import { Box, Typography } from "@mui/material";
import React from "react";
import { FlexContainer } from "../../styled/Generic";

interface ProductProps {
  icon: string;
  details: {
    label: string;
    value: number | string;
  }[];
}

const ProductDetails: React.FC<ProductProps> = ({ icon, details }) => {
  return (
    <Box
      component="div"
      sx={{
        border: "1px solid #808080",
        padding: { xs: "20px", sm: "20px 30px" },
        borderRadius: "6px",
      }}
    >
      <FlexContainer direction="row" flexWrap="nowrap">
        <Box
          component="div"
          sx={{ py: 2, pr: "15px", borderRight: "1px solid #808080" }}
        >
          <Box
            component="img"
            src={icon}
            alt="product icon"
            sx={{
              width: { xs: 90, sm: 100 },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: { xs: "0px 5px 0px 15px", sm: "0px 10px 0 20px" },
            width: { xs: "100%", sm: "250px" },
            maxWidth: "100%",
          }}
        >
          {details.map((detail, index) => (
            <FlexContainer
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", mr: { xs: 3, sm: 2 } }}
              >
                {detail.label}:
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", fontWeight: "500" }}
              >
                {detail.value}
              </Typography>
            </FlexContainer>
          ))}
        </Box>
      </FlexContainer>
    </Box>
  );
};

export default ProductDetails;
