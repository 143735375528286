import React from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { checkIcon } from "../../../assets/svgs/check-icon";
import { FeedBack, StateGenerics } from "../helper/addProductHelpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ProductType } from "../../product";
import { ProductAddSteps } from "../../../types/common.types";
import { capitalizeWords } from "../../../helpers/format";
import { useTranslation } from "../../../hooks/useTranslation";

export const CompletionFeedback = (props: FeedBack & StateGenerics) => {
  const { productNames, selectedCategory, selectedProduct } = props;
  const user = useSelector((state: RootState) => state.Auth.user);
  const lang = user.defaultLanguage;
  const navigate = useNavigate();
  const theme = useTheme();

  const addAnotherText = useTranslation("add_another_btn_text");
  const productText = useTranslation("product_text");
  const addADifferentProduct = useTranslation(
    "add_a_different_product_btn_text"
  );
  const seeCreatedProduct = useTranslation("see_created_product_btn_text");

  const navigateToCreatedProductPage = () => {
    const productType =
      selectedProduct === ProductType.Drink ? "drinks" : "food";
    navigate(`/app/${productType}`);
  };

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      my={5}
    >
      <Box component="div">{checkIcon}</Box>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: theme.spacing(2, 4),
          textAlign: "center",
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Alert
          icon={false}
          severity="success"
          sx={{
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            fontWeight: 600,
          }}
        >
          <Typography variant="h5">
            {productNames[lang]} added to{" "}
            {capitalizeWords(selectedCategory.name)}
          </Typography>
        </Alert>
      </Paper>

      <Divider sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Chip label="Next Actions" size="medium" />
      </Divider>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 2, sm: 3 }}
        sx={{
          width: "100%",
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => props.setActiveStep(ProductAddSteps.FillForm)}
          color="primary"
          sx={{ textTransform: "none" }}
        >
          {addAnotherText} {capitalizeWords(selectedCategory.name)}{" "}
          {productText}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            props.setProductSelect(null);
            props.setActiveStep(ProductAddSteps.SelectProduct);
          }}
          color="primary"
          sx={{ textTransform: "none" }}
        >
          {addADifferentProduct}
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigateToCreatedProductPage()}
          color="primary"
          sx={{ textTransform: "none" }}
        >
          {seeCreatedProduct}
        </Button>
      </Stack>
    </Box>
  );
};
