import { Modal, Backdrop, Fade, Box, Typography } from "@mui/material";
import { useState } from "react";
import { Staff } from "../../types/common.types";
import StaffForm from "./StaffForm";
import { useDispatch } from "react-redux";
import { deleteStaff, updateStaff } from "../../helpers/xhr";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../hooks/useTranslation";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface EditUserProps {
  open: boolean;
  onClose: () => void;
  staff: Staff | null;
}

export default function EditStaff({ open, onClose, staff }: EditUserProps) {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const editStaffTranslation = useTranslation("edit_staff");
  const staffDetailsTranslation = useTranslation("staff_details");

  const handleSave = async (updateDetails: Staff): Promise<boolean> => {
    try {
      const response = await updateStaff(updateDetails, dispatch);
      if (response.success) {
        setTimeout(onClose, 2000);
      }
      return response.success;
    } catch (error) {
      console.error("Error adding staff:", error);
      return false;
    }
  };

  const handleDelete = async (staffId: number): Promise<boolean> => {
    try {
      const response = await deleteStaff(staffId, dispatch);
      if (response.success) {
        setTimeout(onClose, 2000);
      }
      return response.success;
    } catch (error) {
      console.error("Error adding staff:", error);
      return false;
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              zIndex: 1000,
              backgroundColor: "primary.main",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#666666",
              },
              color: "white",
            }}
          >
            <CloseIcon sx={{ fontSize: "14px" }} />
          </IconButton>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            {isEditing
              ? `${editStaffTranslation}`
              : `${staffDetailsTranslation}`}
          </Typography>
          <StaffForm
            staff={staff}
            newStaffMode={false}
            editStaffMode={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onSave={handleSave}
            onDelete={handleDelete}
          />
        </Box>
      </Fade>
    </Modal>
  );
}
