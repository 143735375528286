import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { OrderRow } from "./OrderRow";
import { OrderFromDB } from "../../redux/slices/types/OrderAndSocketState";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React, { useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import { useWindowSize } from "../../helpers/generic";
import { useTranslation } from "../../hooks/useTranslation";

interface Props {
  orders: OrderFromDB[];
  show: (order: OrderFromDB | null) => void;
  headerColor?: string;
}

export default function OrderTable(props: Props) {
  const { orders, show, headerColor } = props;
  const user = useSelector((state: RootState) => state.Auth.user);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isMobile = useWindowSize().width < 900;

  const orderDateTranslation = useTranslation("order_date");
  const tableTranslation = useTranslation("table");
  const priceTranslation = useTranslation("order_price");

  const handleRowClick = (order: OrderFromDB) => {
    setSelectedOrderNumber(order.orderNumber);
    show(order);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!isMobile) {
      const firstOrderInPage = orders.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )[0];
      if (firstOrderInPage) {
        setSelectedOrderNumber(firstOrderInPage.orderNumber);
        show(firstOrderInPage);
      } else {
        show(null);
      }
    }
    //eslint-disable-next-line
  }, [orders, page, rowsPerPage, isMobile]);

  const tableHeaders: {
    label: string;
    align: "left" | "center" | "right";
  }[] = [
    { label: `${orderDateTranslation}`, align: "left" },
    { label: `${tableTranslation}`, align: "center" },
    { label: `${priceTranslation} (${user.currency.prefix})`, align: "right" },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: { xs: "68.5vh", md: "60vh" } }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  align={header.align}
                  sx={{
                    color: headerColor || "primary.main",
                    width: "33%",
                    lineHeight: "20px",
                    // whiteSpace: "nowrap",
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => (
                <OrderRow
                  key={order.id}
                  row={order}
                  handleClick={handleRowClick}
                  isSelected={order.orderNumber === selectedOrderNumber}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiTablePagination-displayedRows": {
            marginBottom: 0,
            display: { xs: "none", sm: "unset" },
          },
          ".MuiTablePagination-selectLabel": {
            marginBottom: 0,
            color: headerColor,
            fontWeight: "600",
          },
          ".css-8nphli, .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
            {
              marginLeft: { xs: "0px", sm: "20px" },
            },
          ".css-1u94fla, .css-1121a4j-MuiInputBase-root-MuiTablePagination-select":
            {
              marginRight: { xs: "10px", sm: "32px" },
            },
        }}
      />
    </Paper>
  );
}
