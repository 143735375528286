import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { Currency, ProductInterface, User } from "../types/common.types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedProduct } from "../redux/slices/AppState";
import { get } from "lockr";
import { useTranslation } from "../hooks/useTranslation";

export type ProductProp = ProductInterface;
export enum ProductType {
  Drink = 1,
  Food,
}
export interface Props {
  product: ProductProp;
  productType: ProductType;
  parent: "Page" | "Modal";
}
const Product = (props: Props) => {
  const { product, parent } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const productInStock = useTranslation("product_is_in_stock");
  const productOutOfStock = useTranslation("out_of_stock");

  const productHasPhoto =
    product && product.productPhoto && product.productPhoto.secureUrl;
  let defaultLanguage = "en";
  let currency: Currency = { name: "", prefix: "" };
  const userData = get("user") as User | undefined;
  if (userData) {
    defaultLanguage = userData.defaultLanguage;
    currency = userData.currency;
  }
  const productPrice = userData?.hasSegments
    ? product.prices["regular"]
    : product.price;

  return (
    <Card
      sx={{
        maxWidth: 249,
        width: "100%",
        borderRadius: "16px",
        position: "relative",
      }}
    >
      <CardActionArea
        onClick={() => {
          if (parent === "Modal") return;
          dispatch(setSelectedProduct(product));
          navigate(`${product.id}`);
        }}
        sx={{ padding: "16px", borderRadius: "8px" }}
      >
        {productHasPhoto && (
          <CardMedia
            component="img"
            image={product?.productPhoto?.secureUrl}
            alt="product"
            sx={{
              maxWidth: 250,
              height: 250,
              objectFit: "fill",
              objectPosition: "center",
              borderRadius: "16px",
            }}
          />
        )}
        <CardContent sx={{ padding: "10px" }}>
          <Typography
            gutterBottom
            variant="body2"
            component="p"
            sx={{ fontWeight: "500" }}
          >
            {`${currency.prefix}${productPrice}`}
          </Typography>{" "}
          <Typography
            gutterBottom
            variant="h6"
            component="p"
            sx={{ letterSpacing: "1px" }}
          >
            {product.names[defaultLanguage]}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            {product.descriptions[defaultLanguage]}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <Typography
          variant="body2"
          bgcolor={
            product.inStock ? "primary.main" : "rgba(128, 128, 128, 0.913)"
          }
          color="white"
          sx={{
            fontSize: 12,
            px: 1,
            py: "5px",
            fontWeight: "500",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
        >
          {product.inStock ? productInStock : productOutOfStock}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default Product;
