import {
  AddProductGeneric,
  StateGenerics,
  updateURLSearchParams,
} from "../helper/addProductHelpers";
import { RootState } from "../../../redux/store";
import React, { useState } from "react";
import { Category } from "../../../types/common.types";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListCategories from "./UI/ListCategories";
import CreateCategory from "./UI/CreateCategory";
import { ProductType } from "../../product";
import { useTranslation } from "../../../hooks/useTranslation";

type CTA = "New Category" | "Abort";

export const SelectProductCategory = (
  props: AddProductGeneric & StateGenerics
) => {
  const { selectedProduct, selectedCategory, setSelectedCategory, handleNext } =
    props;

  const notSelectedProductText = useTranslation("not_selected_product_text");

  let productCategories: "drinkCategories" | "foodCategories" =
    "drinkCategories";
  if (selectedProduct === ProductType.Food)
    productCategories = "foodCategories";
  const categories = useSelector(
    (state: RootState) => state.Product[productCategories]
  );
  const [categoryCTA, setCategoryCTA] = useState<CTA>("New Category");

  if (!selectedProduct) {
    return <>{notSelectedProductText}</>;
  }

  const hasCategories = !!categories.length;
  const addCategoryMode = categoryCTA === "Abort"; // equivalent to new category button click

  const handleCategorySelect = (category: Category) => {
    setSelectedCategory(category);
    updateURLSearchParams("category", category.id);
    handleNext(true);
  };

  return (
    <Box sx={{ width: "100%", maxHeight: "100vh" }}>
      {hasCategories && (
        <Box textAlign="right">
          <Button
            variant="outlined"
            onClick={() => {
              categoryCTA === "Abort"
                ? setCategoryCTA("New Category")
                : setCategoryCTA("Abort");
            }}
          >
            {categoryCTA}
          </Button>
        </Box>
      )}

      <Box
        sx={{
          overflow: "auto",
          height: "inherit",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {hasCategories && !addCategoryMode && (
          <ListCategories
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategorySelect={handleCategorySelect}
          />
        )}
        {(!hasCategories || (hasCategories && addCategoryMode)) && (
          <CreateCategory
            selectedProduct={selectedProduct}
            setCategoryCTA={setCategoryCTA}
          />
        )}
      </Box>
    </Box>
  );
};
