import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Staff } from "../types/common.types";
import { NewButton } from "../components/styled/Generic";
import SearchFilter from "../components/staff/Search";
import StaffList from "../components/staff/StaffList";
import AddStaff from "../components/staff/AddStaff";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../hooks/useTranslation";
import EmptyStaff from "../components/staff/EmptyStaff";

export default function StaffManagement() {
  const { staffs } = useSelector((state: RootState) => state.Staff);

  const [staffList, setStaffList] = useState<Staff[]>(staffs);
  const [newStaffMode, setNewStaffMode] = useState<boolean>(false);

  const staffManagementTranslation = useTranslation("staff_management");
  const addStaffTranslation = useTranslation("add_staff") || "Add Staff";
  const noStaffMessageTranslation =
    useTranslation("no_staff_accounts_available") ||
    "No staff accounts available";
  const noStaffDescTranslation =
    useTranslation("looks_like_you_have_not_added_any_staff_members_yet") ||
    "It looks like you haven't added any staff members yet.";

  const handleOpen = () => setNewStaffMode(true);
  const handleClose = () => setNewStaffMode(false);

  useEffect(() => {}, [staffs]);

  return (
    <>
      <Typography variant="h4" sx={{ pt: { xs: "30px", md: "10px" }, pb: 5 }}>
        {staffManagementTranslation}
      </Typography>

      <Box>
        {!!staffs.length ? (
          <>
            <NewButton onClick={handleOpen}>{addStaffTranslation}</NewButton>
            <SearchFilter
              data={staffs}
              getSearchText={(item) => item.name}
              onFilter={(filteredData) => setStaffList(filteredData)}
            />
            <StaffList staffs={staffList} />
          </>
        ) : (
          <EmptyStaff
            message={noStaffMessageTranslation}
            description={noStaffDescTranslation}
            actionText={addStaffTranslation}
            addStaff={handleOpen}
          />
        )}
      </Box>

      <AddStaff open={newStaffMode} onClose={handleClose} />
    </>
  );
}
