import { useState, useEffect } from "react";
import {
  Box,
  // Button,
  Typography,
  // MenuItem,
  // Select,
  Modal,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { getOrders } from "../../helpers/xhr";
import OrderTable from "./OrderTable";
import { OrderFromDB } from "../../redux/slices/types/OrderAndSocketState";
import OrderWidget from "./OrderWidget";
// import PopularWidget from "./PopularWidget";
// import RevenueWidget from "./RevenueWidget";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../hooks/useTranslation";
import styled from "styled-components";
import { OrderStatus } from "./DesktopOrder";
import { StyledNoDataWrapper } from "../styled/Generic";
import CustomButton from "../common/CustomButton";
import { MembershipTier } from "../../types/common.types";
import UpgradeModal from "../upgrade/UpgradeModal";
import { get } from "lockr";
import PaymentCallback from "../upgrade/PaymentCallback";

// type DashboardCards = "Orders" | "Revenue" | "PopularProduct";
const MobileOrder = () => {
  const user = useSelector((state: RootState) => state.Auth.user);
  const orders = useSelector((state: RootState) => state.Order.orders);
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState<number>(
    OrderStatus.Pending
  );
  const [filteredOrders, setFilteredOrders] = useState<OrderFromDB[]>(orders);
  // const [activeCard, setActiveCard] = useState<DashboardCards>("Orders");
  const activeCard = "Orders";
  const [order, setOrder] = useState<OrderFromDB | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPaymentCallback, setShowPaymentCallback] = useState(false);

  const filterOrders = (status: OrderStatus) => {
    const filtered = orders.filter((order) => order.status === status);
    setFilteredOrders(filtered);
  };

  const thereAreNoOrdersToDisplay = useTranslation(
    "there_are_no_orders_to_display"
  );
  const customerOrdersText = useTranslation(
    "customer_orders_will_show_up_here_when_order_have_been_placed"
  );
  const upgradeTranslation = useTranslation("upgrade");
  const upgradeMembershipTier = useTranslation(
    "upgrade_your_membership_to_accept_orders"
  );
  const pendingTranslation = useTranslation("pending");
  const processingTranslation = useTranslation("processing");
  const deliveredTranslation = useTranslation("delivered");

  const paymentRef = get("pRef");

  const handleStatusChange = (event: SelectChangeEvent<number>) => {
    const status = Number(event.target.value) as OrderStatus;
    setSelectedStatus(status);
  };

  const handleOrderClick = (order: OrderFromDB | null) => {
    setOrder(order);
    setOpenModal(true);
  };

  const handleUpgradeClick = () => {
    if (paymentRef) {
      setShowPaymentCallback(true);
      return;
    }
    setShowUpgradeModal(true);
  };

  // const dashboardCardClickHandler = (title: DashboardCards) => {
  //   setActiveCard(title);
  //   setOpenModal(true);
  // };

  const OrderHeader = styled(Box)`
    width: 100%;
    background-color: #eee;
    position: sticky;
    top: 55px;
    z-index: 10;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  `;
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  let headerColor;
  if (selectedStatus === OrderStatus.Pending) {
    headerColor = "error.main";
  } else if (selectedStatus === OrderStatus.Processing) {
    headerColor = "warning.main";
  } else {
    headerColor = "success.main";
  }
  const noOrder = require("../../assets/no-order.png");

  useEffect(() => {
    if (orders.length) setOrder(orders[0]);
    if (!orders.length) getOrders(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterOrders(selectedStatus);
    // eslint-disable-next-line
  }, [orders, selectedStatus]);

  if (user.membershipDetails.tier !== MembershipTier.PREMIUM) {
    return (
      <>
        <StyledNoDataWrapper>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
            {upgradeMembershipTier}
          </Typography>
          <CustomButton
            sx={{
              maxWidth: "150px",
            }}
            onClick={handleUpgradeClick}
          >
            {upgradeTranslation}
          </CustomButton>
        </StyledNoDataWrapper>
        {showPaymentCallback && <PaymentCallback paymentRef={paymentRef} />}
        <UpgradeModal
          currentTier={user.membershipDetails.tier}
          open={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
        />
      </>
    );
  }

  if (!orders.length) {
    return (
      <StyledNoDataWrapper>
        <Box
          component="img"
          src={noOrder}
          alt="empty order"
          sx={{
            width: 100,
            mb: 2,
          }}
        />
        <Typography variant="h6" gutterBottom>
          {thereAreNoOrdersToDisplay}
        </Typography>
        <Typography variant="body2">{customerOrdersText}</Typography>
      </StyledNoDataWrapper>
    );
  }

  return (
    <Box sx={{ height: "100%" }}>
      {/* Dropdown menu for ideated Orders/Revenue/Popular

      <Box sx={{ marginBottom: "30px" }}>
        <Select
          value={activeCard}
          onChange={(event) =>
            dashboardCardClickHandler(event.target.value as DashboardCards)
          }
          fullWidth
          sx={{
            backgroundColor: "primary.main",
            width: "100%",
            color: "#fff",
            borderRadius: "8px",
            padding: "0px 10px",
            textAlign: "center",
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          <MenuItem value="Orders">Orders</MenuItem>
          <MenuItem value="Revenue">Revenue</MenuItem>
          <MenuItem value="PopularProduct">Most Popular</MenuItem>
        </Select>
      </Box>
      */}

      <OrderHeader>
        <Select
          value={selectedStatus}
          onChange={handleStatusChange}
          fullWidth
          sx={{
            border: `1px solid ${headerColor}`,
            color: headerColor,
            borderRadius: "8px",
            padding: "0px 10px",
            textAlign: "center",
            fontSize: "1.125rem",
            fontWeight: "600",
          }}
        >
          <MenuItem value={OrderStatus.Pending}>{pendingTranslation}</MenuItem>
          <MenuItem value={OrderStatus.Processing}>
            {processingTranslation}
          </MenuItem>
          <MenuItem value={OrderStatus.Delivered}>
            {deliveredTranslation}
          </MenuItem>
        </Select>
      </OrderHeader>

      <OrderTable
        orders={filteredOrders}
        show={handleOrderClick}
        headerColor={headerColor}
      />

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            width: "90%",
            minHeight: "50vh",
            overflowY: "auto",
          }}
        >
          {activeCard === "Orders" && order && (
            <OrderWidget
              orderDetails={order}
              status={selectedStatus}
              onUpdate={handleCloseModal}
            />
          )}
          {/*{activeCard === "Revenue" && <RevenueWidget />}*/}
          {/*{activeCard === "PopularProduct" && <PopularWidget />}*/}

          <div className="absolute">
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                zIndex: 1000,
                backgroundColor: "primary.main",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#666666",
                },
                color: "white",
              }}
            >
              <CloseIcon sx={{ fontSize: "14px" }} />
            </IconButton>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default MobileOrder;
