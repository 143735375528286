import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, CircularProgress, Fade, TextField } from "@mui/material";
import { useState } from "react";
import { Category } from "../../../types/common.types";
import { ProductType } from "../../product";
import { useDispatch } from "react-redux";
import {
  DeleteProductCategory,
  UpdateProductCategory,
} from "../../../helpers/xhrHelper";
import { useTranslation } from "../../../hooks/useTranslation";
import { capitalizeWords } from "../../../helpers/format";

interface CategoryActionProps {
  open: boolean;
  onClose: () => void;
  category: Category;
  isRenaming: boolean;
  productType: ProductType.Drink | ProductType.Food;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function CategoryAction({
  open,
  onClose,
  category,
  isRenaming,
  productType,
}: CategoryActionProps) {
  const [name, setName] = useState<string>(category.name);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const dispatch = useDispatch();

  const saveTranslation = useTranslation("save");
  const cancelTranslation = useTranslation("cancel");
  const yesTranslation = useTranslation("yes");
  const noTranslation = useTranslation("no");
  const operationSuccessful = useTranslation("operation_successful_alert");
  const errorOccurred = useTranslation("error_occurred");
  const pleaseTryAgain = useTranslation("please_try_again");
  const productDeleteWarn = useTranslation("product_delete_warn");
  const categoryText = useTranslation("category_text");
  const doYouWantToContinue = useTranslation("do_you_want_to_continue_text");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSave = async () => {
    if (name === category.name) {
      onClose();
      return;
    }

    setLoading(true);

    try {
      const successResponse = await UpdateProductCategory(
        dispatch,
        category.id,
        name,
        productType
      );
      if (successResponse) {
        setSuccess(true);
        setTimeout(() => {
          onClose();
          setSuccess(false);
        }, 2000);
      } else {
        setError(true);
        setTimeout(() => {
          onClose();
          setError(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error changing category name", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      const successResponse = await DeleteProductCategory(
        dispatch,
        category.id,
        productType
      );
      if (successResponse) {
        setSuccess(true);
        setTimeout(() => {
          onClose();
          setSuccess(false);
        }, 2000);
      } else {
        setError(true);
        setTimeout(() => {
          onClose();
          setError(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error deleting category", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {isRenaming ? (
              <TextField
                fullWidth
                value={name}
                onChange={handleChange}
                autoFocus
              />
            ) : (
              <Typography
                variant="subtitle1"
                component="h3"
                textAlign="left"
                sx={{ mb: 1 }}
              >
                {productDeleteWarn}{" "}
                <Box component="span" fontWeight="bold">
                  {capitalizeWords(category.name)}
                </Box>{" "}
                {categoryText}. {doYouWantToContinue}?
              </Typography>
            )}

            {loading ? (
              <CircularProgress sx={{ mt: 3 }} />
            ) : isRenaming ? (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              >
                <Button sx={{ textTransform: "none" }} onClick={handleSave}>
                  {saveTranslation}
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    onClose();
                    setName(category.name);
                  }}
                >
                  {cancelTranslation}
                </Button>
              </Box>
            ) : (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  {yesTranslation}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onClose()}
                >
                  {noTranslation}
                </Button>
              </Box>
            )}
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {operationSuccessful}!
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorOccurred}. {pleaseTryAgain}.
              </Alert>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
