import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FORM_FIELDS } from "../modal/helpers/constants";
import NewCategoryAdd from "./NewCategoryAdd";
import { FormStateProps } from "./ModalProductUpdate";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "../../hooks/useTranslation";
import { ProductType } from "../product";
import { capitalizeWords } from "../../helpers/format";

//coerce the usage of ProductTypes and ProductType into one with preference for the enum type

interface CategoryInterface extends FormStateProps {
  productType: ProductType;
}
export const Category = (props: CategoryInterface) => {
  const { formFields, setFields, productType } = props;
  const productState = useSelector((state: RootState) => state.Product);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const categories =
    productType === ProductType.Drink
      ? productState.drinkCategories
      : productState.foodCategories;

  const categoryTranslation = useTranslation("category");
  const addNewCategoryTranslation = useTranslation("add_new_category");

  const selectChangeHandler = (value: string) => {
    if (value === FORM_FIELDS.CATEGORY.ADD_NEW) {
      setIsNewCategory(true);
    } else {
      setFields({ ...formFields, category: value });
    }
  };

  const hasCategories = !!categories.length;

  return (
    <Row className="mb-3">
      <Box sx={{ minWidth: 120 }}>
        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
          {!hasCategories && (
            <div style={{ color: "red" }}>Please add a category</div>
          )}
          <InputLabel id="demo-simple-select-standard-label">
            {categoryTranslation}
          </InputLabel>
          <Select
            value={formFields.category}
            onChange={(e) => selectChangeHandler(e.target.value)}
            required
            label={categoryTranslation}
          >
            <MenuItem value={FORM_FIELDS.CATEGORY.ADD_NEW}>
              <em>-- {addNewCategoryTranslation} --</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {capitalizeWords(category.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <NewCategoryAdd
        isAddMode={isNewCategory}
        setNewCategoryActive={setIsNewCategory}
        productAddType={productType}
      />
    </Row>
  );
};

export default Category;
