import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateFeedback } from "../../redux/slices/AppState";
import { Alert } from "@mui/material";

export const FEEDBACK_VARIANT = {
  SUCCESS: "success",
  ERROR: "error",
};
export interface Feedback {
  show: boolean;
  type: string;
  message: string;
  timeout?: number;
}
const FeedbackAlert = memo((props: Feedback) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        dispatch(updateFeedback({ show: false, message: "", type: "" }));
      }, props.timeout || 3000);
    }
  }, [dispatch, props.show, props.timeout]);

  let severity: "success" | "error" = "success";
  if (props.type === FEEDBACK_VARIANT.ERROR) severity = "error";

  if (props.show) {
    return <Alert severity={severity}>{props.message}</Alert>;
  }

  return <></>;
});

export default FeedbackAlert;
