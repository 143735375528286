import React, { useState } from "react";
import { Box, Button, Chip, Divider, styled } from "@mui/material";
import {
  AddProductGeneric,
  ProductForm,
  StateGenerics,
  StepsInterface,
} from "../helper/addProductHelpers";
import { palette } from "../../../assets/color";
import { RootState } from "../../../redux/store";
import {
  Currency,
  Language,
  MediaSource,
  NewProduct,
  ProductAddSteps,
  Segment,
  User,
} from "../../../types/common.types";
import { FormFields } from "../../form/ModalProductUpdate";
import { updateFeedback } from "../../../redux/slices/AppState";
import { FEEDBACK_VARIANT } from "../../extras/Alert";
import { productFormSubmit } from "../../form/formUtils";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lockr";
import Name from "../../form/Name";
import Price from "../../form/Price";
import Category from "../../form/Category";
import Description from "../../form/Description";
import MediaPicker from "../../form/media-picker/desktop/MediaPicker";
import { Form } from "react-bootstrap";
import { useTranslation } from "../../../hooks/useTranslation";
import Photo from "../../form/Photo";
import { ProductType } from "../../product";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  height: "71vh",
  width: "100%",
  overflow: "hidden",
}));
const FormWrapper = styled(Box)(() => ({
  width: "50%",
  backgroundColor: palette.body,
  display: "inherit",
  // justifyContent: "center",
  overflow: "auto",
  padding: "10px 20px",
}));
const StyledForm = styled(Form)(() => ({
  width: "100%",
}));

const PhotoWrapper = styled(Box)(() => ({
  width: "50%",
  height: "100%",
  marginLeft: "10px",
  backgroundColor: palette.body,
  padding: "5px",
  overflowY: "auto",
}));

const NewProductCreate = (
  props: ProductForm & StateGenerics & AddProductGeneric & StepsInterface
) => {
  const selectedFileInBase64 = useSelector(
    (state: RootState) => state.Product.selectedFileInBase64
  );
  const selectedMedia = useSelector(
    (state: RootState) => state.Product.selectedMedia
  );
  const user = useSelector((state: RootState) => state.Auth.user);
  const dispatch = useDispatch();
  const {
    selectedProduct,
    selectedCategory,
    handleNext,
    setFormSuccess,
    setCreatedProductNames,
    setSteps,
  } = props;
  const userData = get("user") as User | undefined;
  let languages: Language[] = [];
  let currency: Currency = { name: "", prefix: "" };
  let segments: Segment[] = [];
  if (userData) {
    languages = userData.languages;
    currency = userData.currency;
    segments = userData.segments;
  }

  const [fields, setFields] = useState<FormFields>({
    names: {},
    descriptions: {},
    category: selectedCategory.id,
    price: "",
    prices: {},
    inStock: true,
  });

  const nameTranslation = useTranslation("new_product_name");
  const priceTranslation = useTranslation("new_product_price");
  const categoryTranslation = useTranslation("new_product_category");
  const descriptionTranslation = useTranslation("new_product_description");
  const productTypeIsNotSelected = useTranslation(
    "product_type_is_not_selected"
  );
  const submitTranslation = useTranslation("new_product_submit");
  const addCategoryText = useTranslation("add_category_text");
  const uploadAPhotoFor = useTranslation("upload_a_photo_for");

  if (!selectedProduct) return <>{productTypeIsNotSelected}</>;

  const submitHandler = () => {
    if (!fields.category) {
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message: `${addCategoryText}`,
        })
      );
      return;
    }

    let productPhoto;
    let mediaSource = MediaSource.Upload;
    if (typeof selectedFileInBase64 === "string") {
      productPhoto = selectedFileInBase64;
    } else if (selectedMedia.photo) {
      mediaSource = MediaSource.MediaPicker;
      productPhoto = selectedMedia.id;
    }

    if (!productPhoto) {
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          timeout: 36000,
          message: `${uploadAPhotoFor} ${fields.names[user.defaultLanguage]}`,
        })
      );
      return;
    }

    const product: NewProduct = {
      descriptions: fields.descriptions,
      inStock: fields.inStock,
      names: fields.names,
      ...(userData?.hasSegments ? { prices: fields.prices } : {}),
      ...(!userData?.hasSegments ? { price: Number(fields.price) } : {}),
      categoryId: fields.category,
      photo: productPhoto,
      id: "",
      mediaSource,
    };

    productFormSubmit(
      product,
      dispatch,
      "New",
      selectedProduct === ProductType.Drink
    ).then((isProcessSuccessful) => {
      setFormSuccess(isProcessSuccessful);
      if (isProcessSuccessful) {
        handleNext(true);
        setCreatedProductNames(fields.names);
        setSteps((prevSteps) => {
          const newSteps = prevSteps.map((step) => {
            if (step.stepId !== ProductAddSteps.SelectCategory)
              step.completed = false;
            return step;
          });
          return newSteps;
        });
      }
    });
  };

  return (
    <Wrapper>
      <FormWrapper>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <Photo isMobile={false} />
          <Divider>
            <Chip label={nameTranslation} size="small" />
          </Divider>
          <Name
            languages={languages}
            formFields={fields}
            setFields={setFields}
          />
          <Divider>
            <Chip label={priceTranslation} size="small" />
          </Divider>
          <Price
            currency={currency}
            segments={segments}
            formFields={fields}
            setFields={setFields}
          />
          <Divider>
            <Chip label={categoryTranslation} size="small" />
          </Divider>
          <Category
            formFields={fields}
            setFields={setFields}
            productType={selectedProduct}
          />
          <Divider>
            <Chip label={descriptionTranslation} size="small" />
          </Divider>
          <Description
            languages={languages}
            formFields={fields}
            setFields={setFields}
          />
          <Box
            sx={{ textAlign: "center", margin: "10px 0", padding: "15px 10px" }}
          >
            <Button
              variant="contained"
              size="large"
              type={"submit"}
              sx={{ width: "60%" }}
            >
              {submitTranslation}
            </Button>
          </Box>
        </StyledForm>
      </FormWrapper>

      <PhotoWrapper>
        <MediaPicker productType={selectedProduct} />
      </PhotoWrapper>
    </Wrapper>
  );
};

export default NewProductCreate;
