import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Modal,
  Paper,
  Chip,
  CardContent,
  CardActions,
  CircularProgress,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import CustomButton from "../common/CustomButton";
import { updateBusinessInfo } from "../../helpers/xhrHelper";
import { useTranslation } from "../../hooks/useTranslation";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

interface BusinessInfoProps {
  address?: string;
  brief?: string;
  contacts?: string[];
  activities?: string[];
}

export default function BusinessInfo(props: BusinessInfoProps) {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState<string>(props.address ?? "");
  const [description, setDescription] = useState<string>(props.brief ?? "");
  const [contacts, setContacts] = useState<string[]>(props.contacts ?? []);
  const [activities, setActivities] = useState<string[]>(
    props.activities ?? []
  );

  const [tempAddress, setTempAddress] = useState(address);
  const [tempDescription, setTempDescription] = useState(description);
  const [tempContacts, setTempContacts] = useState([...contacts]);
  const [tempActivities, setTempActivities] = useState([...activities]);

  const [showContactModal, setShowContactModal] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);

  const [newContact, setNewContact] = useState("");
  const [newActivity, setNewActivity] = useState("");

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<
    "success" | "error" | null
  >(null);

  const businessInfoUpdatedAlert = useTranslation(
    "business_info_updated_alert"
  );
  const updateBusinessInfoErrorAlert = useTranslation(
    "update_business_info_error_alert"
  );
  const addressLabel = useTranslation("address_label");
  const descriptionLabel = useTranslation("description_label");
  const addContactBtnText = useTranslation("add_contact_btn_text");
  const addActivityBtnText = useTranslation("add_activity_btn_text");
  const cancelBtnText = useTranslation("cancel_btn_text");
  const submitBtnText = useTranslation("submit_btn_text");
  const editBtnText = useTranslation("edit_btn_text");
  const addBtnText = useTranslation("add_btn_text");
  const addContactTitle = useTranslation("add_contact_title");
  const addActivityTitle = useTranslation("add_activity_title");
  const contactsTitle = useTranslation("contacts_title");
  const phoneNumberLabel = useTranslation("phone_number_label");
  const activityNameLabel = useTranslation("activity_name_label");

  const handleEditToggle = () => {
    if (isEditing) {
      setTempAddress(address);
      setTempDescription(description);
      setTempContacts([...contacts]);
      setTempActivities([...activities]);
    }
    setIsEditing(!isEditing);
  };

  const handleSubmit = async () => {
    const updatedData: any = {};

    if (tempAddress !== address) updatedData.address = tempAddress;
    if (tempDescription !== description) updatedData.brief = tempDescription;
    if (tempContacts.join(",") !== contacts.join(","))
      updatedData.contacts = tempContacts;
    if (tempActivities.join(",") !== activities.join(","))
      updatedData.activities = tempActivities;

    if (Object.keys(updatedData).length === 0) {
      setIsEditing(false);
      return;
    }

    setLoading(true);
    try {
      await updateBusinessInfo(dispatch, updatedData);
      setAddress(tempAddress);
      setDescription(tempDescription);
      setContacts([...tempContacts]);
      setActivities([...tempActivities]);
      setAlertMessage(`${businessInfoUpdatedAlert}!`);
      setAlertSeverity("success");
      setIsEditing(false);
    } catch (error: any) {
      setAlertMessage(`${updateBusinessInfoErrorAlert}.`);
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddContact = () => {
    if (newContact.trim()) {
      setTempContacts([...tempContacts, newContact.trim()]);
      setNewContact("");
      setShowContactModal(false);
    }
  };

  const handleAddActivity = () => {
    if (newActivity.trim()) {
      setTempActivities([...tempActivities, newActivity.trim()]);
      setNewActivity("");
      setShowActivityModal(false);
    }
  };

  const handleRemoveActivity = useCallback(
    (activity: string) => {
      setTempActivities(tempActivities.filter((a) => a !== activity));
    },
    [tempActivities]
  );

  const handleRemoveContact = useCallback(
    (contact: string) => {
      setTempContacts(tempContacts.filter((c) => c !== contact));
    },
    [tempContacts]
  );

  useEffect(() => {
    if (alertMessage) {
      const timeout = setTimeout(() => {
        setAlertMessage("");
        setAlertSeverity(null);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [alertMessage]);

  return (
    <Box sx={{ width: "max-content" }}>
      {alertSeverity && alertMessage && (
        <Alert
          severity={alertSeverity}
          onClose={() => setAlertMessage("")}
          sx={{ mb: 2 }}
        >
          {alertMessage}
        </Alert>
      )}
      <Paper
        elevation={2}
        sx={{
          borderRadius: "16px",
          minWidth: "235px",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        <CardContent
          sx={{
            padding: "10px 16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label={addressLabel}
              value={isEditing ? tempAddress : address}
              onChange={(e) => setTempAddress(e.target.value)}
              disabled={!isEditing}
              fullWidth
            />
            <TextField
              label={descriptionLabel}
              value={isEditing ? tempDescription : description}
              onChange={(e) => setTempDescription(e.target.value)}
              disabled={!isEditing}
              multiline
              rows={4}
              fullWidth
            />
            <Box>
              <Typography variant="subtitle1">{contactsTitle}</Typography>
              {tempContacts.map((contact, index) => (
                <Chip
                  key={index}
                  label={contact}
                  onDelete={
                    isEditing ? () => handleRemoveContact(contact) : undefined
                  }
                  sx={{ m: 0.5 }}
                />
              ))}
              {isEditing && (
                <Button
                  onClick={() => setShowContactModal(true)}
                  sx={{ textTransform: "none" }}
                  disabled={loading}
                >
                  {addContactBtnText}
                </Button>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle1">Activities</Typography>
              {tempActivities.map((activity, index) => (
                <Chip
                  key={index}
                  label={activity}
                  onDelete={
                    isEditing ? () => handleRemoveActivity(activity) : undefined
                  }
                  sx={{ m: 0.5 }}
                />
              ))}
              {isEditing && (
                <Button
                  onClick={() => setShowActivityModal(true)}
                  sx={{ textTransform: "none" }}
                  disabled={loading}
                >
                  {addActivityBtnText}
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            {isEditing ? (
              loading ? (
                <CircularProgress />
              ) : (
                <>
                  <CustomButton
                    variant="outlined"
                    onClick={handleEditToggle}
                    disabled={loading}
                  >
                    {cancelBtnText}
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {submitBtnText}
                  </CustomButton>
                </>
              )
            ) : (
              <CustomButton variant="contained" onClick={handleEditToggle}>
                {editBtnText}
              </CustomButton>
            )}
          </Box>
        </CardActions>
      </Paper>

      {/* Contact Modal */}
      <Modal open={showContactModal} onClose={() => setShowContactModal(false)}>
        <Paper sx={modalStyle}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{addContactTitle}</Typography>
            <IconButton onClick={() => setShowContactModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label={phoneNumberLabel}
            value={newContact}
            onChange={(e) => setNewContact(e.target.value)}
            fullWidth
            sx={{ my: 2 }}
          />
          <Button fullWidth variant="contained" onClick={handleAddContact}>
            {addBtnText}
          </Button>
        </Paper>
      </Modal>

      {/* Activity Modal */}
      <Modal
        open={showActivityModal}
        onClose={() => setShowActivityModal(false)}
      >
        <Paper sx={modalStyle}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{addActivityTitle}</Typography>
            <IconButton onClick={() => setShowActivityModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label={activityNameLabel}
            value={newActivity}
            onChange={(e) => setNewActivity(e.target.value)}
            fullWidth
            sx={{ my: 2 }}
          />
          <Button fullWidth variant="contained" onClick={handleAddActivity}>
            {addBtnText}
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
}
