import React, { useState } from "react";
import { MembershipDetails, MembershipTier } from "../../types/common.types";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { useUpgrade } from "./UpgradeContext";
import FeedbackAlert from "../extras/Alert";
import PaystackFrame from "./PaystackFrame";
import { get } from "lockr";
import { useTranslation } from "../../hooks/useTranslation";

const MembershipTierDisplay: Record<MembershipTier, string> = {
  [MembershipTier.PREMIUM]: "Premium",
  [MembershipTier.BASIC]: "Basic",
  [MembershipTier.FREE]: "Free",
};

interface UpgradeModalProps {
  currentTier: MembershipTier;
  open: boolean;
  onClose: () => void;
}

interface FeedbackState {
  show: boolean;
  message: string;
  type: "success" | "error";
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

export default function UpgradeModal({
  currentTier,
  open,
  onClose,
}: UpgradeModalProps) {
  const { startUpgradeJourney, isUpgrading } = useUpgrade();
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<FeedbackState>({
    show: false,
    message: "",
    type: "success",
  });

  const upgradeMembershipTitle = useTranslation("upgrade_membership_title");
  const upgradeMembershipText = useTranslation("upgrade_membership_text");
  const cancelBtnText = useTranslation("cancel_btn_text");
  const membershipText = useTranslation("membership_text");
  const costText = useTranslation("cost_text");
  const processingText = useTranslation("processing");
  const upgradeTo = useTranslation("upgrade_to");

  const membershipTiers = get("membershipTiers") as Array<MembershipDetails>;

  const upgradeOptions = membershipTiers
    .filter((tier) => tier.tier !== 3)
    .sort((a, b) => b.tier - a.tier)
    .map((tier) => ({
      tier: tier.tier,
      cost: tier.price,
      details: tier.description,
    }));

  const filteredOptions = upgradeOptions.filter((option) => {
    if (currentTier === MembershipTier.FREE) return true;
    if (currentTier === MembershipTier.BASIC)
      return option.tier === MembershipTier.PREMIUM;
    return false;
  });

  const showFeedback = (message: string, type: "success" | "error") => {
    setFeedback({ show: true, message, type });
    setTimeout(() => {
      setFeedback({ show: false, message: "", type: "success" });
    }, 3000);
  };

  const handleUpgrade = (tier: MembershipTier) => {
    startUpgradeJourney(tier, showFeedback, setIframeUrl);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {feedback.show && (
              <FeedbackAlert
                show={feedback.show}
                message={feedback.message}
                type={feedback.type}
                timeout={3000}
              />
            )}
            {iframeUrl ? (
              <Box sx={{ width: "100%", height: "85vh", mt: 4 }}>
                <PaystackFrame iframeUrl={iframeUrl} onClose={onClose} />
              </Box>
            ) : (
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h2"
                  gutterBottom
                >
                  {upgradeMembershipTitle}
                </Typography>
                <Typography
                  id="transition-modal-description"
                  variant="body2"
                  color="textSecondary"
                  gutterBottom
                >
                  {upgradeMembershipText}.
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                  {filteredOptions.map((option) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={option.tier}
                      sx={{
                        textAlign:
                          filteredOptions.length === 1 ? "center" : undefined,
                      }}
                    >
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          boxShadow: 3,
                          border: "2px solid",
                          borderColor: "grey.300",
                        }}
                      >
                        <CardContent>
                          <Typography variant="h5" sx={{ mb: 2 }}>
                            {MembershipTierDisplay[option.tier]}{" "}
                            {membershipText}
                          </Typography>
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{ mb: 3 }}
                          >
                            {costText}: ₦{option.cost.toLocaleString()}
                          </Typography>
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              textAlign: "left",
                            }}
                          >
                            {option.details.map((detail, idx) => (
                              <Typography
                                key={idx}
                                component="li"
                                variant="body1"
                                sx={{ mb: 1 }}
                              >
                                • {detail}
                              </Typography>
                            ))}
                          </ul>
                        </CardContent>
                        <Box sx={{ p: 2 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpgrade(option.tier)}
                            disabled={isUpgrading}
                            fullWidth
                            sx={{ textTransform: "none" }}
                          >
                            {isUpgrading
                              ? `${processingText}...`
                              : `${upgradeTo} ${
                                  MembershipTierDisplay[option.tier]
                                }`}
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  onClick={onClose}
                  variant="text"
                  color="primary"
                  disabled={isUpgrading}
                  sx={{ mt: 3 }}
                >
                  {cancelBtnText}
                </Button>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
