import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "../../../hooks/useTranslation";

interface Navigator {
  isOptional: boolean;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  isStepsCompleted: boolean;
}
export const StepsNavigator = (props: Navigator) => {
  const { isOptional, handleNext, handleBack, activeStep, isStepsCompleted } =
    props;

  const backTranslation = useTranslation("step_back");
  const skipTranslation = useTranslation("step_skip");
  const nextTranslation = useTranslation("step_next");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        pt: 2,
        pb: 2,
        backgroundColor: "white",
      }}
    >
      <Button
        color="inherit"
        disabled={activeStep === 0}
        onClick={() => handleBack()}
        sx={{ mr: 1 }}
      >
        {backTranslation}
      </Button>
      <Box sx={{ flex: "1 1 auto" }} />
      {isOptional && (
        <Button color="inherit" onClick={() => null} sx={{ mr: 1 }}>
          {skipTranslation}
        </Button>
      )}
      <Button disabled={isStepsCompleted} onClick={() => handleNext()}>
        {nextTranslation}
      </Button>
    </Box>
  );
};
