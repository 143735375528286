import { useState, useEffect, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Card } from "../../styled/Generic";
import { useTranslation } from "../../../hooks/useTranslation";
import { Box, Typography } from "@mui/material";
import GenerateBarcode from "./GenerateBarcode";
import AddSegmentContainer from "./AddSegment";
import { MembershipTier } from "../../../types/common.types";
import { capitalizeWords } from "../../../helpers/format";

export default function Barcode() {
  const user = useSelector((state: RootState) => state.Auth.user);
  const [activeView, setActiveView] = useState<
    "generateBarcode" | "addSegment" | null
  >(null);
  const [selectedSegment, setSelectedSegment] = useState<string | null>(null);

  const addSegment = useTranslation("add_segment");
  const barcodesTranslation = useTranslation("barcodes");

  useEffect(() => {
    if (user.segments.length > 0) {
      setActiveView("generateBarcode");
      setSelectedSegment(user.segments[0].name);
    }
  }, [user.segments]);

  const handleAddSegmentClick = (event: MouseEvent<HTMLElement>) => {
    setActiveView("addSegment");
    setSelectedSegment(null);
  };

  const handleSegmentClick = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    segmentName: string
  ) => {
    setActiveView("generateBarcode");
    setSelectedSegment(segmentName);
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ pb: 2 }}>
        {barcodesTranslation}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          flexWrap: "wrap",
        }}
      >
        <Card
          hoverEffect
          width="150px"
          borderRadius="6px"
          fontSize="14px"
          isActive={activeView === "addSegment"}
          height="56px"
          centerAlignContent
          onClick={handleAddSegmentClick}
        >
          {addSegment}
        </Card>
        {!!user.segments.length &&
          user.segments.map((segment) => (
            <Card
              key={segment.name}
              hoverEffect
              width="150px"
              height="56px"
              fontSize="14px"
              borderRadius="6px"
              isActive={
                activeView === "generateBarcode" &&
                selectedSegment === segment.name
              }
              centerAlignContent
              onClick={(e: MouseEvent<HTMLElement>) =>
                handleSegmentClick(e, segment.name)
              }
              disabled={
                user.membershipDetails.tier === MembershipTier.FREE &&
                segment.name !== "regular"
              }
            >
              {capitalizeWords(segment.name)}
            </Card>
          ))}
      </div>

      <Box component="div" sx={{ mt: 3 }}>
        {activeView === "generateBarcode" && selectedSegment && (
          <GenerateBarcode
            clientUsername={user.username}
            clientSegment={selectedSegment}
          />
        )}

        {activeView === "addSegment" && (
          <AddSegmentContainer clientUsername={user.username} />
        )}
      </Box>
    </Box>
  );
}
