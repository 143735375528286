import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material";
import { Media } from "../../../../types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { palette } from "../../../../assets/color";
import { setMediaSelection } from "../../../../redux/slices/ProductState";

const Card = styled(Box)(() => ({
  height: "200px",
  width: "200px",
  padding: "5px",
  borderRadius: "10px",
  "&:hover": {
    border: `2px solid ${palette.brandMain}`,
  },
}));

interface Props {
  media: Media;
}
const MediaCard = (props: Props) => {
  const isGettingMedia = useSelector(
    (state: RootState) => state.App.isGettingMedia
  );

  const dispatch = useDispatch();

  if (isGettingMedia) {
    return (
      <Card>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={200} height={200} />
        </Stack>
      </Card>
    );
  }

  return (
    <Card
      onClick={() => {
        dispatch(setMediaSelection({ media: props.media }));
      }}
    >
      <img
        src={props.media.photo}
        alt="Uploaded"
        style={{ width: "100%", cursor: "pointer" }}
      />
    </Card>
  );
};

export default MediaCard;
