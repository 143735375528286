import styled from "styled-components";
import { isNightMode } from "../../../helpers/generic";
import { Button } from "../../styled/Generic";
import { palette } from "../../../assets/color";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: ${palette.bodyDark};
`;
export const FeedbackWrapper = styled.div`
  width: 100%;
  display: inherit;
  justify-content: center;
`;
export const FormContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
  justify-content: center;
`;
export const Form = styled.form`
  max-width: 397px;
  width: 100%;
  min-height: 350px;
  text-align: center;
  padding: 10px 30px;
  background-color: ${isNightMode() ? "#444444" : `${palette.body}`};
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    min-height: 100vh;
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const InputField = styled.input`
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
  font-size: 15px;
  color: #313131;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  padding: 5px;
`;
export const SubmitButton = styled(Button)`
  height: 38px;
  background-color: #6e151e;
  width: 100%;
  border: none;
  margin-bottom: 30px;
  border-radius: 5px;

  &:hover {
    background-color: #6e151ec0;
  }
`;
export const Line = styled.div`
  height: 1px;
  background-color: #ebebeb;
  width: 100%;
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0px 10px;

  a {
    text-decoration: none;
    color: #666;
    font-size: 14px;
    transition: color 0.3s ease, transform 0.3s ease;

    &:hover {
      color: #6e151ec0;
      transform: translateY(-1px);
    }
  }
`;
