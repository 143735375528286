import { FormEvent } from "react";
import { xhr, XHR_STATE, xhrHeaders } from "../../helpers/xhr";
import { Dispatch } from "@reduxjs/toolkit";
import { updateFeedback, updateXHR } from "../../redux/slices/AppState";
import { setUserState, USER_STATE } from "../../redux/slices/Auth";
import { FEEDBACK_VARIANT } from "../extras/Alert";
import { User } from "../../types/common.types";
import { rm, set } from "lockr";
import { initiateSocketIo } from "../../helpers/socketEvents";

export type Credentials = {
  username: string;
  password: string;
};
export const loginSubmitHandler = (
  e: FormEvent<HTMLFormElement>,
  data: Credentials,
  dispatch: Dispatch<any>
) => {
  e.preventDefault();
  dispatch(updateXHR({ xhr: XHR_STATE.REQUESTING }));
  xhr
    .post(
      "/auth/user/login/password",
      {
        ...data,
      },
      { headers: xhrHeaders }
    )
    .then(async (response) => {
      if (response.data.success) {
        const token = response.data.token;

        set("token", token);

        const rawUser = response.data.user;
        if (rawUser && Object.keys(rawUser).length) {
          const user: User = {
            currency: rawUser.currency,
            canPlaceOrder: rawUser.canPlaceOrder,
            languages: rawUser.languages,
            segments: rawUser.segments,
            legalName: rawUser.legalName,
            defaultLanguage: rawUser.defaultLanguage,
            hasSegments: rawUser.hasSegments,
            username: rawUser.username,
            activeLanguage: rawUser.defaultLanguage,
            address: rawUser.address,
            brief: rawUser.brief,
            contacts: rawUser.contacts,
            activities: rawUser.activities,
            userLanguages: rawUser.userLanguages,
            membershipDetails: rawUser.membershipDetails,
          };
          if (user.canPlaceOrder) await initiateSocketIo(true, dispatch);
          set("user", user);

          const permissions = response.data.permissions;
          if (permissions) {
            set("staff-permissions", permissions);
          }

          dispatch(setUserState({ user, state: USER_STATE.LOGGED_IN }));
        }

        const membershipTiers = response.data.membershipTiers;
        set("membershipTiers", membershipTiers);

        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.SUCCESS,
            message: response.data.message,
          })
        );
        dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
      } else {
        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.ERROR,
            message: response.data.message,
          })
        );
        dispatch(updateXHR({ xhr: XHR_STATE.SUCCESS }));
      }
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : error.message;
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message,
        })
      );
      dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
    });
};

export const logout = (dispatch: Dispatch<any>) => {
  // const url = baseURL.concat("/auth/user/logout");
  dispatch(updateXHR({ xhr: XHR_STATE.REQUESTING }));
  xhr
    .post("/auth/user/logout")
    .then((response) => {
      dispatch(updateXHR({ xhr: XHR_STATE.SUCCESS }));
      if (response.data.success) {
        // set("user", {});
        rm("user");
        set("staff-permissions", undefined);
        rm("membershipTiers");
        rm("token");
        window.location.href = "/";
      }
    })
    .catch((err) => {
      dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
      console.log(err);
    });
};
