import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MediaPicker from "../../form/media-picker/desktop/MediaPicker";
import { useDispatch } from "react-redux";
import { ProductInterface } from "../../../types/common.types";
import ModalProductUpdate from "../../form/ModalProductUpdate";
import { clearMediaSelection } from "../../../redux/slices/ProductState";

interface ProductEdit {
  modalMode: number;
  product: ProductInterface;
  productType: number;
}
const WriteProduct = (props: ProductEdit) => {
  const { product, productType } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearMediaSelection());
    };
  }, [dispatch]);

  return (
    <Row>
      <Col sm={12} lg={5}>
        <ModalProductUpdate productType={productType} activeProduct={product} />
      </Col>
      <Col sm={12} lg={7}>
        <MediaPicker />
      </Col>
    </Row>
  );
};

export default WriteProduct;
