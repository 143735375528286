import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { MODAL } from "../../components/modal/helpers/constants";
import { ProductInterface } from "../../types/common.types";
import { Feedback } from "../../components/extras/Alert";
import { XHR_STATE } from "../../helpers/xhr";
import { emptyProductObject } from "../../helpers/generic";

interface AppStateInterface {
  isNightMode: boolean;
  feedback: Feedback;
  xhr: XHR_STATE;
  modalActive: boolean;
  modalMode: number;
  activeProduct: ProductInterface;
  isGettingMedia: boolean;
}

const initialState: AppStateInterface = {
  xhr: XHR_STATE.NONE,
  feedback: { show: false, type: "", message: "" },
  isNightMode: false,
  modalActive: false,
  modalMode: MODAL.NULL,
  activeProduct: emptyProductObject,
  isGettingMedia: false,
};

export const AppStateSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setNightMode: (state, action: PayloadAction<boolean>) => {
      state.isNightMode = action.payload;
    },
    setModalState: (state, action: PayloadAction<number>) => {
      state.modalMode = action.payload;
    },
    setSelectedProduct: (state, action: PayloadAction<ProductInterface>) => {
      state.activeProduct = action.payload;
    },
    updateFeedback: (state, action: PayloadAction<Feedback>) => {
      return {
        ...state,
        feedback: action.payload,
      };
    },
    updateXHR: (state, action: PayloadAction<{ xhr: XHR_STATE }>) => {
      return {
        ...state,
        xhr: action.payload.xhr,
      };
    },
    updateMediaXhr: (
      state,
      action: PayloadAction<{ xhr: XHR_STATE; isFetchMedia: boolean }>
    ) => {
      return {
        ...state,
        xhr: action.payload.xhr,
        isGettingMedia: action.payload.isFetchMedia,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNightMode,
  setSelectedProduct,
  setModalState,
  updateFeedback,
  updateXHR,
  updateMediaXhr,
} = AppStateSlice.actions;

export default AppStateSlice.reducer;
