import { createBrowserRouter } from "react-router-dom";
import Login from "./components/auth/Login";
import NotFound from "./Not-Found";
import { ProSidebarProvider } from "react-pro-sidebar";
import App from "./App";
import { Dashboard } from "./pages/dashboard";
import Order from "./pages/order";
import DrinkPage from "./pages/drink";
import { store } from "./redux/store";
import {
  getAllCategories,
  getDrink,
  getDrinks,
  getFood,
  getFoodProducts,
  getStaffs,
} from "./helpers/xhr";
import Modal from "./components/modal/Modal";
import { MODAL } from "./components/modal/helpers/constants";
import Food from "./pages/food";
import { NewProduct } from "./pages/new-product";
import Settings from "./pages/settings";
import { ErrorBoundary } from "./components/extras/RouteError";
import React from "react";
import StaffManagement from "./pages/staff-management";
import { UpgradeProvider } from "./components/upgrade/UpgradeContext";
import { ProductType } from "./components/product";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: "app",
    element: (
      <UpgradeProvider>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </UpgradeProvider>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        loader: () => {
          const { drinks, food } = store.getState().Product;
          if (!drinks.length) {
            getDrinks("Loader", store.dispatch);
          }
          if (!food.length) {
            getFoodProducts("Loader", store.dispatch);
          }
          return { drinks, food };
        },
      },
      {
        path: "order",
        element: <Order />,
        loader: () => {
          return null;
        },
      },
      {
        path: "drinks",
        element: <DrinkPage />,
        loader: () => {
          const productState = store.getState().Product;
          const drinks = productState.drinks;
          const categoriesExist = productState.drinkCategories.length;
          if (!drinks.length)
            return getDrinks("Loader", store.dispatch, !!categoriesExist);
          return drinks;
        },
        children: [
          {
            path: ":drinkId",
            element: (
              <Modal
                show={true}
                productType={ProductType.Drink}
                modalModeInit={MODAL.VIEW}
              />
            ),
            loader: ({ params }) => {
              return getDrink(params.drinkId, store.dispatch);
            },
          },
        ],
      },
      {
        path: "food",
        element: <Food />,
        loader: () => {
          const productState = store.getState().Product;
          const categoriesExist = productState.foodCategories.length;
          const food = productState.food;
          if (!food.length)
            return getFoodProducts("Loader", store.dispatch, !!categoriesExist);
          return food;
        },
        children: [
          {
            path: ":foodId",
            element: (
              <Modal
                show={true}
                productType={ProductType.Food}
                modalModeInit={MODAL.VIEW}
              />
            ),
            loader: ({ params }) => {
              return getFood(params.foodId, store.dispatch);
            },
          },
        ],
      },
      {
        path: "add-product",
        element: <NewProduct />,
        loader: async () => {
          const { drinkCategories, foodCategories } = store.getState().Product;
          if (!(drinkCategories.length && foodCategories.length)) {
            return getAllCategories(store.dispatch);
          }
          return null;
        },
      },
      {
        path: "staff-management",
        element: <StaffManagement />,
        loader: async () => {
          const { staffs } = store.getState().Staff;
          if (!staffs.length) {
            return getStaffs(store.dispatch);
          }
          return null;
        },
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
]);

export default router;
