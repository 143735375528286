import React, { useEffect } from "react";
import * as AppStateActions from "./redux/slices/AppState";
import { useDispatch } from "react-redux";
import { isNightMode } from "./helpers/generic";
import { setUserState, USER_STATE } from "./redux/slices/Auth";
import { get } from "lockr";
import { User } from "./types/common.types";
import { cleanUpListeners, handleSocketEvents } from "./helpers/socketEvents";
import "./App.css";
import AppBarWithDrawer from "./components/AppBarWithDrawer";
import * as socketActions from "./redux/slices/OrderSocketState";

const App = () => {
  const dispatch = useDispatch();
  const nightMode = isNightMode();
  const userData = get("user") as User | undefined;
  useEffect(() => {
    if (!!userData) {
      dispatch(setUserState({ user: userData, state: USER_STATE.LOGGED_IN }));
    }
    if (nightMode) dispatch(AppStateActions.setNightMode(nightMode));
    if (window.socket?.connected) {
      dispatch(socketActions.setSocketConnect({ isActive: true }));
    }
  }, [nightMode, dispatch, userData]);

  useEffect(() => {
    handleSocketEvents(window.socket, dispatch);
    return () => cleanUpListeners(window.socket, dispatch);
    // eslint-disable-next-line
  }, []);

  return <AppBarWithDrawer />;
};

export default App;
//Template Ref: http://preview.themeforest.net/item/ebazar-ecommerce-bootstrap-admin-template/full_screen_preview/33983389?_ga=2.49627558.1884505248.1698594462-1280987435.1679137581&_gac=1.217043300.1698594462.Cj0KCQjwhfipBhCqARIsAH9msblOCwE7LIjVR9JEZfDfbfOkDKsHUq5KZkTYHnyXGrBv-DfIp_VUXwgaAukZEALw_wcB
