import styled from "styled-components";
import { COLORS } from "../../styleConstants";

interface ImageProps {
  image: string;
}
export const Image = styled.div`
  height: 130px;
  background-image: url(${(props: ImageProps) => props.image});
  border-radius: 0;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 1em;
  max-height: 100%;
  width: 100%;
  //background-color: ${COLORS.primary};
  max-width: 100%;
  //height: 240px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: ${COLORS.textGrey};
  //font-family: Arial, serif;
`;

export const Description = styled.div`
  font-size: 14px;
  color: black;
`;

export const Price = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${COLORS.textGrey};
  //font-family: .AppleSystemUIFont sans-serif;
`;

export const Stock = styled.div`
  color: green;
  margin-top: 5px;
  //font-family: .AppleSystemUIFont sans-serif;
`;
