import React, { Fragment, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Currency, Segment } from "../../types/common.types";
import { FormStateProps } from "./ModalProductUpdate";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "../../hooks/useTranslation";
import { capitalizeWords } from "../../helpers/format";

interface Props {
  optionId: number;
  updateOptions: (optionId: number) => void;
  currency: Currency;
}

const SaleOption = (props: Props): JSX.Element => {
  const { optionId, updateOptions, currency } = props;

  return (
    <Row className="mt-2">
      <Col sm={10}>
        <Row>
          <Form.Group as={Col} controlId="saleOptionLabel">
            <Form.Label>Label</Form.Label>
            <Form.Control type="text" placeholder="1 portion" />
          </Form.Group>
          <Form.Group as={Col} controlId="saleOptionPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control type="text" placeholder={`${currency.prefix}0.99`} />
          </Form.Group>
        </Row>
      </Col>
      <Col sm={2}>
        <Form.Group as={Col} controlId="saleOptionClose">
          <Form.Label>Remove</Form.Label>
          <Button variant={"danger"} onClick={() => updateOptions(optionId)}>
            X
          </Button>
        </Form.Group>
      </Col>
    </Row>
  );
};

type SaleOptionObject = {
  element: JSX.Element;
  optionId: number;
};
interface PriceProp extends FormStateProps {
  currency: Currency;
  segments: Segment[];
}
const Price = (props: PriceProp) => {
  const { currency, segments, formFields, setFields } = props;
  const { prices } = formFields;
  const [elementId, setElementId] = useState(0);
  const user = useSelector((state: RootState) => state.Auth.user);
  const [saleOptions, setSaleOptions] = useState<SaleOptionObject[]>([
    {
      optionId: 0,
      element: <></>,
    },
  ]);

  const priceFor = useTranslation("price_for");

  const updateSaleOptions = (id: number) => {
    const filteredElements = saleOptions.filter(
      (element) => element.optionId !== id
    );
    setSaleOptions([...filteredElements]);
  };

  const optionAddHandler = () => {
    const optionsList = saleOptions;
    const optionId = elementId + 1;
    const newElement: SaleOptionObject = {
      element: (
        <SaleOption
          optionId={optionId}
          updateOptions={(id) => updateSaleOptions(id)}
          currency={currency}
        />
      ),
      optionId,
    };
    optionsList.push(newElement);
    setSaleOptions(optionsList);
    setElementId(optionId);
  };

  const onPriceChange = (segName: string, value: number) => {
    const productPrices = { ...prices, [segName]: value };
    setFields({ ...formFields, prices: productPrices });
  };

  return (
    <Row mb={3}>
      <Col md={8}>
        {!user.hasSegments ? (
          <Box>
            <TextField
              id="standard-basic"
              label={`${user.currency.prefix}`}
              variant="standard"
              value={formFields.price}
              onChange={(e) =>
                setFields({ ...formFields, price: e.target.value })
              }
              sx={{ width: "100%" }}
              required
            />
          </Box>
        ) : (
          <Row className="mb-3">
            {!!segments.length &&
              segments.map((segment, index) => {
                return (
                  <Box key={`${segment.name}-${index}`}>
                    <TextField
                      id="standard-basic"
                      label={`${priceFor} ${capitalizeWords(segment.name)} ${
                        user.currency.prefix
                      }`}
                      variant="standard"
                      value={prices[segment.name] || ""}
                      onChange={(e) =>
                        onPriceChange(segment.name, Number(e.target.value))
                      }
                      sx={{ width: "100%" }}
                      required
                    />
                  </Box>
                );
              })}
          </Row>
        )}
      </Col>

      <Col md={4} style={{ margin: "10px 0" }}>
        <Form.Group controlId="priceOptionBtn">
          {/*<Form.Label>Sale Options</Form.Label>*/}
          <Form.Control
            type="button"
            value="Add price options"
            style={{ cursor: "not-allowed" }}
            disabled
            onClick={() => optionAddHandler()}
          />
        </Form.Group>
      </Col>
      <Row>
        {saleOptions &&
          saleOptions.map((saleOption, index) => (
            <Fragment key={index}>{saleOption.element}</Fragment>
          ))}
      </Row>
    </Row>
  );
};

export default Price;
