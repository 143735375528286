import { Avatar } from "@mui/material";
import { Staff, StaffRole } from "../../types/common.types";
import { deepOrange } from "@mui/material/colors";
import { palette } from "../../assets/color";
import { capitalizeFirstLetter } from "../../helpers/generic";
import { capitalizeWords } from "../../helpers/format";

interface StaffCardProps {
  staff: Staff;
}

export default function StaffCard({ staff }: StaffCardProps) {
  let staffRole = StaffRole[staff.role];

  staffRole = capitalizeFirstLetter(staffRole);

  return (
    <div
      style={{
        border: `1px solid ${palette.brandMain}`,
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        width: "250px",
        height: "100px",
        margin: "10px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          border: `1px solid ${palette.brandMain}`,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          marginRight: "20px",
          flexShrink: "0",
        }}
      >
        <Avatar sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}>
          {staff.name.slice()[0]}
        </Avatar>
      </div>

      <div style={{ textAlign: "left", flexGrow: 1, overflow: "hidden" }}>
        <h2
          style={{
            fontSize: "1.2em",
            margin: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {capitalizeWords(staff.name)}
        </h2>
        <p
          style={{
            fontSize: "0.9em",
            margin: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {staffRole}
        </p>
      </div>
    </div>
  );
}
