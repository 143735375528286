import {
  Box,
  Button,
  Paper,
  Popover,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "qrcode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import TableChartIcon from "@mui/icons-material/TableChart";

interface Props {
  id: "simple-popover" | undefined;
  open: boolean;
  anchorEl: HTMLElement | null;
  clientUsername: string | null;
  clientSegment: string | null;
  onClose: () => void;
}

export default function GenerateBarcodeModal({
  id,
  open,
  anchorEl,
  clientUsername,
  clientSegment,
  onClose,
}: Props) {
  const [value, setValue] = useState<string>("");
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [tableNumber, setTableNumber] = useState<string>("");

  const downloadBarcode = useTranslation("download_barcode");
  const printBarcode = useTranslation("print_barcode");
  const generateBarcodeFor = useTranslation("generate_barcode_for");
  const segmentText = useTranslation("segment_text");
  const noQrCodeInfo = useTranslation("no_qr_code_info");
  const tableNoLable = useTranslation("table_number_input_label");
  const tableNoPlaceholder = useTranslation("table_number_input_placeholder");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, "");
    setValue(valueWithoutSpaces);
    setTableNumber(valueWithoutSpaces);
  };

  const handleDownload = () => {
    if (qrCode) {
      const link = document.createElement("a");
      link.href = qrCode;
      link.download = `table_${tableNumber}.png`;
      link.click();
    }
  };

  const handlePrint = () => {
    if (qrCode) {
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(`<img src="${qrCode}" />`);
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.focus();
          printWindow.print();
          printWindow.close();
        };
      }
    }
  };

  useEffect(() => {
    if (tableNumber) {
      let url = clientSegment
        ? `menutender.com/${clientUsername}/${clientSegment.toLocaleLowerCase()}/${tableNumber}`
        : `menutender.com/${clientUsername}/${tableNumber}`;
      QRCode.toDataURL(url, { width: 200, margin: 2 }, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setQrCode(url);
        }
      });
    } else {
      setQrCode(null);
    }
  }, [clientSegment, clientUsername, tableNumber]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          maxWidth: "100%",
          width: "90%",
          borderRadius: "12px",
          padding: "16px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            alignSelf: "flex-end",
            backgroundColor: "primary.main",
            color: "#fff",
            fontSize: "12px",
            "&:hover": { backgroundColor: "#666666" },
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 2, textTransform: "capitalize" }}
        >
          {generateBarcodeFor} {clientSegment} {segmentText}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: 2,
          }}
        >
          <Paper
            elevation={2}
            sx={{
              width: { xs: "150px", sm: "250px" },
              height: { xs: "150px", sm: "200px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
            }}
          >
            {qrCode ? (
              <img src={qrCode} alt="QR Code" style={{ width: "100%" }} />
            ) : (
              <Typography
                variant="body2"
                sx={{ p: 1, textAlign: "center", color: "GrayText" }}
              >
                {`${noQrCodeInfo}.`}
              </Typography>
            )}
          </Paper>

          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "500", textAlign: "center", mb: 3 }}
            >
              {clientSegment
                ? `menutender.com/${clientUsername}/${clientSegment.toLowerCase()}/`
                : `menutender.com/${clientUsername}/`}
              <span style={{ color: "#6e151e" }}>{tableNumber}</span>
            </Typography>

            <TextField
              label={`${tableNoLable}.`}
              type="number"
              variant="outlined"
              placeholder={tableNoPlaceholder}
              InputProps={{
                startAdornment: <TableChartIcon sx={{ mr: 1 }} />,
              }}
              value={value}
              onChange={handleChange}
              sx={{ width: "250px" }}
            />
          </Box>
        </Box>

        {tableNumber && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDownload}
              sx={{ textTransform: "none" }}
            >
              {downloadBarcode}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handlePrint}
              sx={{
                textTransform: "none",
                borderColor: "#6e151e",
                color: "#6e151e",
                "&:hover": {
                  borderColor: "#6e151e",
                  backgroundColor: "#fdf5f5",
                },
              }}
            >
              {printBarcode}
            </Button>
          </Box>
        )}
      </Box>
    </Popover>
  );
}
