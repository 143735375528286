import { configureStore } from "@reduxjs/toolkit";
import AppStateSlice from "./slices/AppState";
import ProductState from "./slices/ProductState";
import AuthSlice from "./slices/Auth";
import Order from "./slices/OrderSocketState";
import StaffStateSlice from "./slices/StaffState";

export const store = configureStore({
  reducer: {
    App: AppStateSlice,
    Product: ProductState,
    Auth: AuthSlice,
    Order,
    Staff: StaffStateSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
