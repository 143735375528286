import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { MembershipTier, StaffFormData } from "../../types/common.types";
import { addStaff } from "../../helpers/xhr";
import { useDispatch, useSelector } from "react-redux";
import StaffForm from "./StaffForm";
import { Alert, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../hooks/useTranslation";
import { useState } from "react";
import { RootState } from "../../redux/store";
import { StyledNoDataWrapper } from "../styled/Generic";
import CustomButton from "../common/CustomButton";
import UpgradeModal from "../upgrade/UpgradeModal";
import { get } from "lockr";
import PaymentCallback from "../upgrade/PaymentCallback";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface AddStaffProps {
  open: boolean;
  onClose: () => void;
}

export default function AddStaff({ open, onClose }: AddStaffProps) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.Auth.user);
  const { staffs } = useSelector((state: RootState) => state.Staff);

  const [upgradeError, setUpgradeError] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPaymentCallback, setShowPaymentCallback] = useState(false);

  const newStaffTranslation = useTranslation("new_staff");
  const limitReachedTranslation =
    useTranslation(
      "you_have_reached_the_staff_creation_limit_for_your_membership_tier."
    ) || "You have reached the staff creation limit for your membership tier.";
  const upgradeTranslation = useTranslation("upgrade");
  const upgradeMembershipTier = useTranslation(
    "upgrade_your_membership_to_add_more_staffs"
  );
  const upgradePlan = useTranslation("upgrade_plan_btn_text");

  const hasBasicMembershipLimitReached =
    user.membershipDetails.tier === MembershipTier.BASIC &&
    staffs.length >= user.membershipDetails.limits.staff;

  const hasFreeMembershipLimitReached =
    user.membershipDetails.tier === MembershipTier.FREE &&
    staffs.length >= user.membershipDetails.limits.staff;

  const shouldShowUpgradeMessage =
    hasBasicMembershipLimitReached || hasFreeMembershipLimitReached;

  const paymentRef = get("pRef");

  const handleUpgradeClick = () => {
    if (paymentRef) {
      setShowPaymentCallback(true);
      return;
    }
    setShowUpgradeModal(true);
  };

  const handleSubmit = async (
    staffDetails: StaffFormData
  ): Promise<boolean> => {
    try {
      const response = await addStaff(staffDetails, dispatch);
      if (response.success) {
        setTimeout(onClose, 2000);
      }
      return response.success;
    } catch (error: any) {
      if (error.message === "Request failed with status code 403") {
        setUpgradeError(true);
      }
      return false;
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                zIndex: 1000,
                backgroundColor: "primary.main",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#666666",
                },
                color: "white",
              }}
            >
              <CloseIcon sx={{ fontSize: "14px" }} />
            </IconButton>
            {!shouldShowUpgradeMessage && (
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
              >
                {newStaffTranslation}
              </Typography>
            )}

            {upgradeError ? (
              <>
                <Alert
                  severity="error"
                  sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                >
                  <Typography>{limitReachedTranslation}</Typography>
                </Alert>
                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => alert("Upgrade processing at the moment")}
                  >
                    {upgradePlan}
                  </Button>
                </Box>
              </>
            ) : shouldShowUpgradeMessage ? (
              <StyledNoDataWrapper>
                <Typography variant="h5" gutterBottom>
                  {upgradeMembershipTier}
                </Typography>
                <CustomButton
                  sx={{
                    maxWidth: "150px",
                  }}
                  onClick={handleUpgradeClick}
                >
                  {upgradeTranslation}
                </CustomButton>
              </StyledNoDataWrapper>
            ) : (
              <StaffForm
                staff={null}
                newStaffMode={true}
                editStaffMode={false}
                onSubmit={handleSubmit}
              />
            )}
          </Box>
        </Fade>
      </Modal>
      {showPaymentCallback && <PaymentCallback paymentRef={paymentRef} />}
      <UpgradeModal
        currentTier={user.membershipDetails.tier}
        open={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </div>
  );
}
