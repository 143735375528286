import styled from "styled-components";
import { palette } from "../../assets/color";

export const Button = styled.button`
  height: 40px;
  padding: 8px;
  text-align: center;
  background-color: #028103;
  color: white;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  padding: 8px 18px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: ${palette.brandMain};
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px #999;
`;

export const ActionsBar = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

interface ButtonProps {
  bg?: string;
  color?: string;
  size?: string;
}
export const StyledButton = styled.button`
  height: 30px;
  background-color: ${(props: ButtonProps) => props.bg};
  color: ${(props) => props.color};
  border-radius: 5px;
`;

export const Record = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
`;

interface CardProps {
  isActive?: boolean;
  isModal?: boolean;
  textAlign?: "left" | "right" | "center";
  textTransform?: string;
  centerAlignContent?: boolean;
  border?: boolean;
  borderRadius?: string;
  parent?: string;
  margin?: string;
  padding?: string;
  bgColor?: string;
  color?: string;
  height?: string;
  width?: string;
  maxWidth?: string;
  fontSize?: string;
  justifyContent?: string;
  alignContent?: string;
  alignItems?: string;
  hoverEffect?: boolean;
  disabled?: boolean;
}
export const Card = styled.div`
  transition: box-shadow 0.1s ease 0.3s;
  height: ${(props: CardProps) => props.height || "200px"};
  width: ${(props: CardProps) => props.width || "200px"};
  margin: ${(props: CardProps) => props.margin || ""};
  padding: ${(props: CardProps) => props.padding || ""};
  font-size: ${(props: CardProps) => props.fontSize || "inherit"};
  display: ${(props: CardProps) => props.centerAlignContent && "flex"};
  flex-direction: ${(props: CardProps) => props.centerAlignContent && "column"};
  align-items: ${(props: CardProps) => props.centerAlignContent && "center"};
  border-radius: ${(props: CardProps) => props.borderRadius || "0"};
  justify-content: ${(props: CardProps) =>
    props.centerAlignContent && "center"};
  text-align: ${(props: CardProps) => props.textAlign || "center"};
  text-transform: ${(props: CardProps) => props.textTransform || "unset"};
  color: ${(props: CardProps) =>
    props.isActive ? ` ${palette.brandMain}` : "inherit"};
  border: ${(props) =>
    props.isActive ? `2px solid ${palette.brandMain}` : "1px solid #ddd"};
  background-color: ${(props: CardProps) => props.bgColor || "#fff"};
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : !props.hoverEffect || props.isModal
      ? ""
      : "pointer"};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  &:hover {
    box-shadow: ${(props: CardProps) =>
      props.hoverEffect && !props.isModal && "0 8px 16px 0 rgba(0, 0, 0, 0.2)"};
  }
  &:active {
    transform: scale(0.98);
  }
`;

interface Flex extends CardProps {
  flexWrap?: string;
  direction: "column" | "row";
  gap?: string;
  margin?: string;
}

export const StyledContainer = styled.div`
  //margin: 0 70px;
  width: 100%;
`;
export const FlexContainer = styled(StyledContainer)`
  display: flex;
  /* background-color: yellowgreen; */
  flex-direction: ${(props: Flex) => props.direction};
  justify-content: ${(props: Flex) => props.justifyContent};
  align-items: ${(props: Flex) => props.alignItems};
  height: ${(props: Flex) => props.height};
  align-content: ${(props: Flex) => props.alignContent};
  flex-wrap: ${(props: Flex) => props.flexWrap || "wrap"};
  gap: ${(props: Flex) => props.gap || "0"};
  width: ${(props: Flex) => props.width || "100%"};
  max-width: ${(props: Flex) => props.maxWidth || ""};
  margin: ${(props: Flex) => props.margin || "0"};
  padding: ${(props: Flex) => props.padding || "0"};
`;

export const NewButton = styled.button`
  background-color: #ffffff;
  border: 1px solid ${palette.brandMain};
  border-radius: 5px;
  font-size: 1em;
  margin: 10px 0px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const SearchBar = styled.input`
  border: 1px solid ${palette.brandMain};
  border-radius: 5px;
  font-size: 1em;
  margin: 10px 0px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const StyledNoDataWrapper = styled.div`
  min-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
