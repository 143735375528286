import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GetMedia } from "../../../../helpers/xhrHelper";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import SelfUpload from "../SelfUpload";
import Photo, { PickPhotoButton } from "../../Photo";
import { styled } from "@mui/material";
import { palette } from "../../../../assets/color";
import { setMediaSelection } from "../../../../redux/slices/ProductState";
import usePrevious from "../../../../hooks/usePrevious";

const MediaPicker = () => {
  const media = useSelector((state: RootState) => state.Product.media);
  const dispatch = useDispatch();
  const [showPicker, setShowPicker] = useState(false);
  const selectedMedia = useSelector(
    (state: RootState) => state.Product.selectedMedia
  );
  const selectedFilePreview = useSelector(
    (state: RootState) => state.Product.selectedFilePreview
  );
  const productType = useSelector(
    (state: RootState) => state.Product.newProductType
  );

  useEffect(() => {
    //fixme: Api requests made on viewport resizing.
    if (productType) GetMedia(dispatch, productType);
    //eslint-disable-next-line
  }, []);

  const photo = selectedMedia.photo || selectedFilePreview;
  const previousMedia = usePrevious({ value: photo });

  useEffect(() => {
    if (previousMedia !== photo) {
      setShowPicker(false);
    }
  }, [previousMedia, photo]);

  if (!showPicker) {
    return (
      <>
        <Photo isMobile={true} action={() => setShowPicker(true)} />
        {!!photo && (
          <PickPhotoButton
            action={() => setShowPicker(true)}
            text={"Change Photo"}
            color="primary"
          />
        )}
      </>
    );
  }

  return (
    <Modal
      show={showPicker}
      fullscreen={true}
      onHide={() => setShowPicker(false)}
      style={{ zIndex: "9999" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Menutender</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          maxWidth: "100vw",
        }}
      >
        <Box
          aria-label="self-upload-container"
          sx={{
            display: "flex",
            width: "100%",
            margin: "10px 0",
            justifyContent: "center",
          }}
        >
          <SelfUpload />
        </Box>

        <Box
          aria-label="media-picker-container"
          sx={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {media.map((m) => (
            <Box
              key={m.id}
              aria-label="media-card-container"
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                margin: "5px 0",
              }}
            >
              <Card
                onClick={() => {
                  dispatch(setMediaSelection({ media: m }));
                  setShowPicker(false);
                }}
              >
                <img
                  src={m.photo}
                  alt="Uploaded"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </Card>
            </Box>
          ))}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

const Card = styled(Box)(() => ({
  height: "200px",
  width: "200px",
  padding: "5px",
  borderRadius: "10px",
  "&:hover": {
    border: `2px solid ${palette.brandMain}`,
  },
}));

export default MediaPicker;
