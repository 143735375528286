import React, { useState, ChangeEvent, useEffect } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { SearchBar } from "../styled/Generic";

interface SearchProps<T> {
  data: T[];
  onFilter: (filteredData: T[]) => void;
  getSearchText: (item: T) => string;
}

function Search<T>({ data, onFilter, getSearchText }: SearchProps<T>) {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query);

  useEffect(() => {
    filterData(debouncedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, data]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const filterData = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (!lowercasedValue) {
      onFilter(data);
      return;
    }

    const filtered = data.filter((item) =>
      getSearchText(item).toLowerCase().includes(lowercasedValue)
    );
    onFilter(filtered);
  };

  return (
    <div>
      <SearchBar
        type="text"
        placeholder="Search..."
        value={query}
        onChange={handleInputChange}
        style={{ borderRadius: "8px", width: "100%", marginBottom: "20px" }}
      />
    </div>
  );
}

export default Search;
