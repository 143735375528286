import { TextField, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { AddSegment } from "../../../helpers/xhrHelper";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/CustomButton";
import { useTranslation } from "../../../hooks/useTranslation";
import { RootState } from "../../../redux/store";
import { MembershipTier } from "../../../types/common.types";
import UpgradeModal from "../../upgrade/UpgradeModal";
import { get } from "lockr";
import PaymentCallback from "../../upgrade/PaymentCallback";
import SegmentIcon from "@mui/icons-material/Segment";

interface AddSegmentProps {
  clientUsername: string;
}

export default function AddSegmentContainer({
  clientUsername,
}: AddSegmentProps) {
  const [value, setValue] = useState<string>("");
  const [segmentName, setSegmentName] = useState<string>("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.Auth.user);

  const createSegment = useTranslation("create_segment_header_title");
  const segmentNameTranslation = useTranslation("create_segment_input_label");
  const submit = useTranslation("submit");
  const upgradeMembershipTier = useTranslation(
    "upgrade_your_membership_to_create_a_segment"
  );
  const upgradeTranslation = useTranslation("upgrade_btn_text");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, "");
    setValue(valueWithoutSpaces);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      setSegmentName(e.target.value);
    }, 2000);

    setTimer(newTimer);
  };

  const handleUpgradeClick = () => setShowUpgradeModal(true);

  const handleSubmit = async () => {
    AddSegment(dispatch, clientUsername, segmentName);
  };

  let mainText = createSegment;
  if (user.membershipDetails.tier === MembershipTier.FREE) {
    mainText = upgradeMembershipTier;
  }

  const Button = () => {
    return (
      <CustomButton
        sx={{
          maxWidth: "150px",
        }}
        disabled={user.hasSegments && !segmentName}
        onClick={
          user.membershipDetails.tier !== MembershipTier.FREE
            ? handleSubmit
            : handleUpgradeClick
        }
      >
        {user.membershipDetails.tier !== MembershipTier.FREE
          ? submit
          : upgradeTranslation}
      </CustomButton>
    );
  };

  const paymentRef = get("pRef");
  if (paymentRef) {
    return <PaymentCallback paymentRef={paymentRef} />;
  }

  return (
    <>
      {user.membershipDetails.tier !== MembershipTier.FREE ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "100%", md: "550px", lg: "650px" },
            maxWidth: { xs: "550px", xl: "100%" },
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: { xs: "24px 20px 10px", md: "30px " },
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {mainText}
            </Typography>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: "30px",
            }}
          >
            <TextField
              label={segmentNameTranslation}
              type="text"
              variant="outlined"
              placeholder="New Segment"
              InputProps={{
                startAdornment: <SegmentIcon sx={{ mr: 1 }} />,
              }}
              value={value}
              onChange={handleChange}
              sx={{ width: "250px" }}
            />

            <code
              style={{ color: "black", fontWeight: "500" }}
            >{`menutender.com/${clientUsername}/${value.toLowerCase()}`}</code>
          </div>
          <Button />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "700px",
              height: "400px",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                {mainText}
              </Typography>
            </Box>
            <Button />
          </div>
        </Box>
      )}
      <UpgradeModal
        currentTier={user.membershipDetails.tier}
        open={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </>
  );
}
