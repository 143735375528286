import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { ProductFormText } from "../../types/common.types";
import { FormStateProps } from "./ModalProductUpdate";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "../../hooks/useTranslation";

interface Language {
  iso: string;
  name: string;
}
interface Props extends FormStateProps {
  languages: Language[];
}
const Description = (props: Props) => {
  const { languages, setFields, formFields } = props;
  const { descriptions } = formFields;

  const productDescriptionIn = useTranslation("product_description_label");

  useEffect(() => {
    const initialDescriptions: ProductFormText = {};
    languages.forEach((language) => (initialDescriptions[language.iso] = ""));
    setFields({ ...formFields, descriptions: initialDescriptions });
    // eslint-disable-next-line
  }, []);

  const onDescriptionChange = (lang: string, value: string) => {
    const productDescriptions = { ...descriptions, [lang]: value };
    setFields({ ...formFields, descriptions: productDescriptions });
  };

  return (
    <>
      <Row className="mb-3">
        {languages.length &&
          languages.map((language, index) => {
            return (
              <Box key={`${language.iso}-${index}`}>
                <TextField
                  id="standard-multiline-flexible"
                  label={`${productDescriptionIn} ${language.name}`}
                  multiline
                  maxRows={4}
                  onChange={(e) =>
                    onDescriptionChange(language.iso, e.target.value)
                  }
                  value={descriptions[language.iso]}
                  variant="standard"
                  sx={{ width: "100%" }}
                  required
                />
              </Box>
            );
          })}
      </Row>
    </>
  );
};

export default Description;
