import React, { useEffect, useRef, useState } from "react";
import { verifyPayment } from "../../helpers/xhr";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { get, rm, set } from "lockr";
import { useTranslation } from "../../hooks/useTranslation";

const updateUserMembership = (tier: string) => {
  const user = get("user");
  user.membershipDetails.tier = tier;
  set("user", user);
};

export default function PaymentCallback({
  paymentRef,
}: {
  paymentRef: string | null;
}) {
  const [status, setStatus] = useState<
    "processing" | "abandoned" | "success" | "pending" | "failed"
  >("processing");
  const [message, setMessage] = useState<string>("");
  const modalOpen = useRef(true);
  const hasExecuted = useRef(false);

  const noPaymentRefText = useTranslation("no_payment_ref_text");
  const abandonedPaymentText = useTranslation("abandoned_payment_text");
  const successPaymentText = useTranslation("success_payment_text");
  const pendingPaymentText = useTranslation("pending_payment_text");
  const failedPaymentText = useTranslation("failed_payment_text");
  const defaultVerifyText = useTranslation("default_verify_text");
  const verifyPaymentErrorText = useTranslation("verify_payment_error_text");
  const closeBtnText = useTranslation("close_btn_text");

  useEffect(() => {
    const verify = async () => {
      if (hasExecuted.current) return; // This prevents duplicate execution of the useEffect
      hasExecuted.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      const reference = urlParams.get("reference") || paymentRef;

      if (!reference) {
        setMessage(noPaymentRefText);
        setStatus("failed");
        return;
      }

      try {
        const response = await verifyPayment(reference);
        const { paymentStatus, newMembershipTier } = response.data;

        switch (paymentStatus) {
          case "abandoned":
            setStatus("abandoned");
            setMessage(`${abandonedPaymentText}!`);
            rm("pRef");
            break;
          case "success":
            setStatus("success");
            setMessage(`${successPaymentText}!`);
            updateUserMembership(newMembershipTier);
            rm("pRef");
            break;
          case "pending":
            setStatus("pending");
            setMessage(pendingPaymentText);
            break;
          case "ongoing":
            setStatus("pending");
            setMessage(pendingPaymentText);
            break;
          case "failed":
            setStatus("failed");
            setMessage(failedPaymentText);
            rm("pRef");
            break;
          default:
            setStatus("failed");
            setMessage(defaultVerifyText);
            rm("pRef");
            break;
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        setStatus("failed");
        setMessage(verifyPaymentErrorText);
      }
    };

    verify();
    //eslint-disable-next-line
  }, []);

  const handleClose = () => {
    modalOpen.current = false;
    window.location.reload();
  };

  return (
    <Modal
      open={modalOpen.current}
      onClose={handleClose}
      aria-labelledby="payment-processing-modal"
      aria-describedby="payment-status"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        {status === "processing" && (
          <>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography variant="h6">Processing...</Typography>
          </>
        )}
        {status === "abandoned" && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}
        {status === "success" && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}
        {status === "pending" && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}
        {status === "failed" && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}
        {(status === "abandoned" ||
          status === "success" ||
          status === "failed" ||
          status === "pending") && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ textTransform: "none" }}
          >
            {closeBtnText}
          </Button>
        )}
      </Box>
    </Modal>
  );
}
