import {
  CreateDrink,
  CreateFood,
  UpdateDrink,
  UpdateFood,
} from "../../helpers/xhrHelper";
import { NewProduct } from "../../types/common.types";
import { Dispatch } from "@reduxjs/toolkit";

export const productFormSubmit = async (
  product: NewProduct,
  dispatch: Dispatch<any>,
  productSubmitType: "New" | "Update",
  isDrinkProduct: boolean
): Promise<boolean> => {
  const { id, ...productData } = product;
  if (isDrinkProduct) {
    if (productSubmitType === "New") {
      return CreateDrink({
        data: productData,
        dispatch,
      });
    } else {
      return UpdateDrink(
        {
          data: productData,
          dispatch,
        },
        id
      );
    }
  } else {
    if (productSubmitType === "New") {
      return CreateFood({
        data: productData,
        dispatch,
      });
    } else {
      return UpdateFood(
        {
          data: productData,
          dispatch,
        },
        product.id
      );
    }
  }
};
