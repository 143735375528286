import { useState, useEffect } from "react";
import { Translation } from "../types/common.types";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const useTranslation = (translationKey: string): string => {
  const [translation, setTranslation] = useState("");
  const { userLanguages, activeLanguage } = useSelector(
    (state: RootState) => state.Auth.user
  );

  useEffect(() => {
    if (userLanguages && activeLanguage) {
      const lang: Translation | undefined = userLanguages.find(
        (language) => language.iso === activeLanguage
      );
      const translations = lang?.translations;

      setTranslation(translations?.[translationKey] || "");
    }
  }, [activeLanguage, translationKey, userLanguages]);

  return translation;
};
