import React from "react";
import { Button, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { palette } from "../../assets/color";
import { ProductInterface } from "../../types/common.types";
import { useTranslation } from "../../hooks/useTranslation";

const Card = styled(Box)(() => ({
  height: "150px",
  width: "150px",
  padding: "5px",
  border: `2px solid ${palette.brandMain}`,
  borderRadius: "10px",
  overflow: "hidden",
}));

interface Props {
  isMobile: boolean;
  activeProduct?: ProductInterface;
  action?: () => void;
}

const Photo = (props: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Content {...props} />
    </Box>
  );
};

const Content = (props: Props) => {
  const { selectedMedia, selectedFilePreview } = useSelector(
    (state: RootState) => state.Product
  );

  const photoSelectInfo = useTranslation("photo_select_info");
  const pickAPhoto = useTranslation("pick_a_photo");

  const isActive = !!props.activeProduct?.productPhoto.secureUrl;
  const askToUploadPhoto = !selectedMedia.photo && !selectedFilePreview;

  if (!isActive && askToUploadPhoto) {
    if (props.isMobile && props.action) {
      return <PickPhotoButton action={props.action} text={pickAPhoto} />;
    } else {
      return (
        <Typography sx={{ padding: "20px", color: "red" }}>
          {`${photoSelectInfo}.`}
        </Typography>
      );
    }
  }

  if (selectedFilePreview) {
    return (
      <Card>
        <img
          src={String(selectedFilePreview)}
          alt="product"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Card>
    );
  }

  return (
    <Card>
      {isActive && props.activeProduct && !selectedMedia.photo && (
        <img
          src={props.activeProduct.productPhoto.secureUrl}
          alt="product"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      )}

      {isActive && selectedMedia.photo && (
        <img
          src={selectedMedia.photo}
          alt="product"
          style={{ width: "100%" }}
        />
      )}

      {!isActive && (
        <img
          src={selectedMedia.photo}
          alt="product"
          style={{ width: "100%" }}
        />
      )}
      {props.isMobile && props.action && (
        <PickPhotoButton action={props.action} text={pickAPhoto} />
      )}
    </Card>
  );
};

interface ButtonProps {
  action: () => void;
  text: string;
  color?: "error" | "primary";
}

export const PickPhotoButton = (props: ButtonProps) => {
  const btnColor = props.color ? props.color : "error";

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "10px 0",
      }}
    >
      <Button variant="contained" color={btnColor} onClick={props.action}>
        {props.text}
      </Button>
    </Box>
  );
};

export default Photo;
