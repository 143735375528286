import {
  Box,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  CircularProgress,
  Alert,
  Button,
  SelectChangeEvent,
  Modal,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Staff, StaffFormData, StaffRole } from "../../types/common.types";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../hooks/useTranslation";

interface StaffFormProps {
  staff: Staff | null;
  newStaffMode: boolean;
  editStaffMode: boolean;
  isEditing?: boolean;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (staff: StaffFormData) => Promise<boolean>;
  onSave?: (staff: Staff) => Promise<boolean>;
  onDelete?: (staffId: number) => Promise<boolean>;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface ConfirmDeleteProps {
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
}

function ConfirmDelete({ open, onClose, handleDelete }: ConfirmDeleteProps) {
  const confirmStaffDeletion = useTranslation("confirm_staff_deletion");
  const areYouSureTranslation = useTranslation(
    "are_you_sure_you_want_to_delete_this_staffs_record"
  );
  const yesTranslation = useTranslation("yes");
  const noTranslation = useTranslation("no");

  const handleYes = () => {
    handleDelete();
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              zIndex: 1000,
              backgroundColor: "primary.main",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#666666",
              },
              color: "white",
            }}
          >
            <CloseIcon sx={{ fontSize: "14px" }} />
          </IconButton>
          <h3 id="child-modal-title">{confirmStaffDeletion}</h3>
          <p id="child-modal-description">{areYouSureTranslation}?</p>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              variant="outlined"
              color="error"
              sx={{ textTransform: "none" }}
              onClick={handleYes}
            >
              {yesTranslation}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none" }}
              onClick={onClose}
            >
              {noTranslation}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const permissionList = [
  "product_write",
  "order_access",
  "settings_modification",
  "can_generate_barcode",
];
const permissionDescriptions: { [key: string]: string } = {
  product_write: "Can create, edit, and delete products.",
  order_access: "Can receive and view order placements.",
  settings_modification: "Can edit settings.",
  can_generate_barcode: "Can generate barcode.",
};

export default function StaffForm({
  staff,
  newStaffMode,
  editStaffMode,
  isEditing,
  setIsEditing,
  onSave,
  onSubmit,
  onDelete,
}: StaffFormProps) {
  const [name, setName] = useState<string>(staff?.name || "");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<number>(staff?.role || StaffRole.ATTENDANT);
  const [permissions, setPermissions] = useState<string[]>(
    staff?.permissions || []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const permissionsHeading = useTranslation("permissions_heading");
  const attendantTranslation = useTranslation("attendant");
  const secretaryTranslation = useTranslation("secretary");
  const managerTranslation = useTranslation("manager");
  const kitchenTranslation = useTranslation("kitchen");
  const accountantTranslation = useTranslation("accountant");
  const roleInputLabel = useTranslation("role_input_label");

  const saveTranslation = useTranslation("save_btn_text");
  const cancelTranslation = useTranslation("cancel_btn_text");
  const editTranslation = useTranslation("edit_btn_text");
  const deleteTranslation = useTranslation("delete_btn_text");
  const submitTranslation = useTranslation("submit_btn_text");
  const operationSuccessful = useTranslation("operation_successful_alert");
  const errorOccurred = useTranslation("error_occurred_alert");
  const pleaseTryAgain = useTranslation("please_try_again_alert");

  const namePlaceholder = useTranslation("name_placholder");
  const emailPlaceholder = useTranslation("email_placeholder");
  const passwordPlaceholder = useTranslation("password_placeholder");

  useEffect(() => {
    if (staff) {
      setName(staff.name);
      setRole(staff.role);
      setPermissions(staff.permissions);
    }
  }, [staff]);

  const handleDeleteClick = () => {
    setOpen(true);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    const selectedRole = Number(event.target.value);
    setRole(selectedRole);
  };

  const handlePermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (permissions.includes(value)) {
      setPermissions(permissions.filter((permission) => permission !== value));
    } else {
      setPermissions([...permissions, value]);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const lowerCaseEmail = email.toLowerCase();
    const newStaffDetails: StaffFormData = {
      name,
      email: lowerCaseEmail,
      password,
      role,
      permissions,
    };

    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      const successResponse = await onSubmit!(newStaffDetails);
      if (successResponse) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error saving staff:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      staff?.name === name &&
      staff?.role === role &&
      JSON.stringify(staff?.permissions) === JSON.stringify(permissions)
    ) {
      setIsEditing!(false);
      return;
    }
    const updateStaffDetails: Staff = {
      staffId: staff!.staffId,
      name,
      role,
      permissions,
    };

    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      const successResponse = await onSave!(updateStaffDetails);
      if (successResponse) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error saving staff:", error);
      setError(true);
    } finally {
      setIsEditing!(false);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      const successResponse = await onDelete!(staff!.staffId);
      if (successResponse) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error saving staff:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <form onSubmit={newStaffMode ? handleSubmit : handleSave}>
          <input
            type="text"
            placeholder={namePlaceholder}
            value={name}
            required={true}
            onChange={handleNameChange}
            style={{
              width: "100%",
              padding: "8px",
              marginBottom: "16px",
              border: "1px solid #666",
            }}
            disabled={!newStaffMode && !isEditing}
          />

          {newStaffMode && (
            <>
              <input
                type="email"
                placeholder={emailPlaceholder}
                value={email}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginBottom: "16px",
                  border: "1px solid #666",
                }}
              />
              <input
                type="password"
                placeholder={passwordPlaceholder}
                value={password}
                required={true}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginBottom: "16px",
                  border: "1px solid #666",
                }}
              />
            </>
          )}

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">
              {roleInputLabel}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={role.toString()}
              required
              onChange={handleRoleChange}
              disabled={!newStaffMode && !isEditing}
              sx={{ border: "1px solid #666" }}
            >
              <MenuItem value={StaffRole.ATTENDANT}>
                1. {attendantTranslation}
              </MenuItem>
              <MenuItem value={StaffRole.SECRETARY}>
                2. {secretaryTranslation}
              </MenuItem>
              <MenuItem value={StaffRole.MANAGER}>
                3. {managerTranslation}
              </MenuItem>
              <MenuItem value={StaffRole.KITCHEN}>
                4. {kitchenTranslation}
              </MenuItem>
              <MenuItem value={StaffRole.ACCOUNTANT}>
                5. {accountantTranslation}
              </MenuItem>
            </Select>
          </FormControl>
          <Typography
            variant="subtitle1"
            component="h3"
            textAlign="left"
            sx={{ mb: 1 }}
          >
            {permissionsHeading}
          </Typography>
          {permissionList.map((permission) => (
            <Box
              key={permission}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
                justifyContent: "flex-start",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.includes(permission)}
                    onChange={handlePermissionsChange}
                    value={permission}
                    disabled={!newStaffMode && !isEditing}
                  />
                }
                label={
                  <ListItemText
                    primary={permissionDescriptions[permission]}
                    sx={{ textAlign: "left", ml: 1 }}
                  />
                }
              />
            </Box>
          ))}
          {loading ? (
            <CircularProgress sx={{ mt: 3 }} />
          ) : isEditing ? (
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
              >
                {saveTranslation}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ textTransform: "none" }}
                onClick={() => setIsEditing!(false)}
              >
                {cancelTranslation}
              </Button>
            </Box>
          ) : editStaffMode ? (
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing!(true);
                }}
              >
                {editTranslation}
              </Button>

              <Button
                variant="contained"
                color="error"
                sx={{ textTransform: "none" }}
                onClick={handleDeleteClick}
              >
                {deleteTranslation}
              </Button>
            </Box>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, textTransform: "none" }}
            >
              {submitTranslation}
            </Button>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {operationSuccessful}!
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorOccurred}. {pleaseTryAgain}.
            </Alert>
          )}
        </form>
      </Box>
      <ConfirmDelete
        open={open}
        onClose={() => setOpen(false)}
        handleDelete={handleDelete}
      />
    </React.Fragment>
  );
}
