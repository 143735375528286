import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import LanguageSetting from "../components/settings/Language";
import CurrencySetting from "../components/settings/Currency";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SettingWrapper } from "../components/settings/SettingWrapper";
import ChangePassword from "../components/settings/ChangePassword";
import { get } from "lockr";
import { useTranslation } from "../hooks/useTranslation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BusinessInfo from "../components/settings/BusinessInfo";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Settings = () => {
  const { user } = useSelector((state: RootState) => state.Auth);

  const staffPermissions = get("staff-permissions") as string[] | undefined;

  const currencyTranslation = useTranslation("currency");
  const languagesTranslation = useTranslation("languages");
  const changePassword = useTranslation("change_password");
  const businessInfoTitle = useTranslation("business_info_title");

  return (
    <Wrapper>
      {(!staffPermissions ||
        staffPermissions.includes("settings_modification")) && (
        <>
          <SettingWrapper settingHeaderText={businessInfoTitle}>
            <BusinessInfo
              address={user.address}
              brief={user.brief}
              contacts={user.contacts}
              activities={user.activities}
            />
          </SettingWrapper>
          <SettingWrapper settingHeaderText={currencyTranslation}>
            <CurrencySetting currency={user.currency} />
          </SettingWrapper>
          <SettingWrapper settingHeaderText={languagesTranslation}>
            <LanguageSetting
              existingLanguages={user.languages}
              defaultLanguage={user.defaultLanguage}
            />
          </SettingWrapper>
        </>
      )}

      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#000" }} />}
          aria-controls="change-password-content"
          id="change-password-header"
        >
          <Typography variant="h4">{changePassword}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChangePassword />
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};

export default Settings;
