import React from "react";
import { Category } from "../../../../types/common.types";
import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import { palette } from "../../../../assets/color";
import { capitalizeWords } from "../../../../helpers/format";

type Props = {
  categories: Category[];
  selectedCategory: Category;
  handleCategorySelect: (category: Category) => void;
};

type StyledType = {
  active: boolean;
  theme?: any;
};

const CategoryPaper = styled(Paper)(({ theme, active }: StyledType) => ({
  padding: "20px",
  margin: "5px 0",
  width: "80%",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: active ? theme.palette.primary.main : palette.body,
  color: active ? palette.body : theme.palette.primary.main,
  "&:hover": {
    backgroundColor: palette.brandMain,
    color: palette.body,
  },
  "&:first-of-type": {
    marginTop: "70px",
  },
  ...theme.mixins.toolbar,
}));

const ListCategories = (props: Props) => {
  const { categories, selectedCategory, handleCategorySelect } = props;

  return (
    <>
      {categories.map((category) => (
        <CategoryPaper
          key={category.id}
          active={selectedCategory.id === category.id}
          onClick={() => handleCategorySelect(category)}
        >
          {capitalizeWords(category.name)}
        </CategoryPaper>
      ))}
    </>
  );
};

export default ListCategories;
