import React, { useEffect, useState } from "react";
import { MenuItem, IconButton, Menu, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { updateActiveLanguage } from "../../redux/slices/Auth";
import { set } from "lockr";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const getFlagUrl = (iso: string) => {
  const languageToCountryMap: { [key: string]: string } = {
    en: "us",
    fr: "fr",
    es: "es",
    de: "de",
    yo: "ng",
    ig: "ng",
    ha: "ng",
    pi: "ng",
  };
  const countryIso =
    languageToCountryMap[iso.toLowerCase()] || iso.toLowerCase();
  return `https://flagcdn.com/48x36/${countryIso}.png`;
};

const LanguageSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const user = useSelector((state: RootState) => state.Auth.user);
  const { userLanguages, activeLanguage } = user;

  const dispatch = useDispatch();

  useEffect(() => {
    set("user", user);
  }, [user]);

  if (!userLanguages) return <></>;

  const switcherActiveLang = userLanguages.find(
    (lang) => lang.iso === activeLanguage
  );

  const handleSwitch = (value: string) => {
    dispatch(updateActiveLanguage(value));
    handleCloseMenu();
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseEnter={handleOpenMenu}
        sx={{ padding: 0, cursor: "pointer" }}
      >
        <img
          src={getFlagUrl(switcherActiveLang?.iso || "us")}
          alt={switcherActiveLang?.name}
          style={{ width: "24px", height: "18px", marginRight: "5px" }}
        />
        <ArrowDropDownIcon />
      </IconButton>

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          onMouseLeave: handleCloseMenu,
        }}
        PaperProps={{
          sx: {
            mt: 1,
            "& .MuiMenu-paper": {
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            },
          },
        }}
      >
        {userLanguages.map((lang) => (
          <MenuItem
            key={lang.iso}
            onClick={() => handleSwitch(lang.iso)}
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": { backgroundColor: "primary.light", color: "#fff" },
            }}
          >
            <img
              src={getFlagUrl(lang.iso)}
              alt={lang.name}
              style={{ width: "24px", height: "18px", marginRight: "8px" }}
            />
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
