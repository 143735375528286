import React from "react";
import { DesktopOverview } from "../components/dashboard-sections/DesktopOverview";
import { useWindowSize } from "../helpers/generic";
import { MobileOverview } from "../components/dashboard-sections/MobileOverview";
import { Box } from "@mui/material";

export const Dashboard = () => {
  const isMobile = useWindowSize().width < 900;
  return (
    <Box sx={{ maxHeight: "100%" }}>
      {isMobile ? <MobileOverview /> : <DesktopOverview />}
    </Box>
  );
};
