import { Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import ModalBody from "./components/ModalBody";
import { MODAL } from "./helpers/constants";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ProductInterface } from "../../types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import FeedbackAlert from "../extras/Alert";
import { setModalState, setSelectedProduct } from "../../redux/slices/AppState";
import { COLORS } from "../../styleConstants";
import { emptyProductObject } from "../../helpers/generic";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../hooks/useTranslation";
import { ProductType } from "../product";

interface ModalProps {
  show: boolean;
  productType: number;
  modalModeInit: number;
}
export interface LoaderXHR {
  success: boolean;
  product: ProductInterface;
}
const ProductModal = (props: ModalProps) => {
  const { show, productType, modalModeInit } = props;
  const { feedback, modalMode } = useSelector((state: RootState) => state.App);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let loaderData = useLoaderData() as LoaderXHR | undefined;

  const somethingWentWrong = useTranslation("something_went_wrong");
  const newProductHeading = useTranslation("new_product_heading");
  const viewProductHeading = useTranslation("view_product_heading");
  const editProductHeading = useTranslation("edit_product_heading");

  useEffect(() => {
    dispatch(setModalState(modalModeInit));
    return () => {
      dispatch(setModalState(MODAL.NULL));
    };
    // eslint-disable-next-line
  }, []);

  const onModalHide = () => {
    dispatch(setSelectedProduct(emptyProductObject));
    if (productType === ProductType.Drink) navigate("/app/drinks");
    if (productType === ProductType.Food) navigate("/app/food");
  };

  let heading = <>{newProductHeading}</>;
  if (modalMode === MODAL.VIEW) {
    heading = <>{viewProductHeading}</>;
  } else if (modalMode === MODAL.EDIT) {
    heading = <>{editProductHeading}</>;
  }

  const renderModalBody =
    modalModeInit === MODAL.WRITE ||
    (loaderData && loaderData.success && modalModeInit === MODAL.VIEW);

  const navBarHeight = "65px";

  return (
    <Modal
      show={show}
      onHide={onModalHide}
      animation={true}
      size="lg"
      style={{ marginTop: navBarHeight, zIndex: "9999" }}
    >
      <IconButton
        aria-label="close"
        onClick={onModalHide}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          zIndex: 1000,
          backgroundColor: "primary.main",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "#666666",
          },
          color: "white",
        }}
      >
        <CloseIcon sx={{ fontSize: "14px" }} />
      </IconButton>
      <Modal.Header>
        <Modal.Title style={{ color: COLORS.primary }}>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "75vh", overflowY: "auto" }}>
        <FeedbackAlert
          show={feedback.show}
          message={feedback.message}
          type={feedback.type}
          timeout={feedback.timeout}
        />
        {renderModalBody ? (
          <ModalBody
            modalMode={modalMode}
            activeProduct={loaderData?.product || emptyProductObject}
            productType={productType}
          />
        ) : (
          <div>{somethingWentWrong}</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
