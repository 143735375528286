import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Staff } from "../../types/common.types";

export interface StaffState {
  staffs: Array<Staff>;
}

const initialState: StaffState = {
  staffs: [],
};

export const StaffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaffs: (state, action: PayloadAction<Array<Staff>>) => {
      state.staffs = action.payload;
    },
    addStaffToState: (state, action: PayloadAction<Staff>) => {
      state.staffs.push(action.payload);
    },
    updateStaffs: (state, action: PayloadAction<Staff>) => {
      const updatedStaff = action.payload;
      const index = state.staffs.findIndex(
        (staff) => staff.staffId === updatedStaff.staffId
      );
      if (index !== -1) {
        state.staffs[index] = updatedStaff;
      }
    },
    removeStaff: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.staffs = state.staffs.filter((staff) => staff.staffId !== id);
    },
  },
});

export const { setStaffs, addStaffToState, updateStaffs, removeStaff } =
  StaffSlice.actions;

export default StaffSlice.reducer;
