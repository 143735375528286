import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ProductInterface } from "../../types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import { ProductType } from "../product";
import {
  setFood,
  setActiveCategoryFilter,
  setDrinks,
} from "../../redux/slices/ProductState";
import { useDebounce } from "../../hooks/useDebounce";
import CategoryList from "../modal/category-management/CategoryList";
import { useTranslation } from "../../hooks/useTranslation";
import { capitalizeWords } from "../../helpers/format";

const FilterWrapper = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: "column";
  gap: "20px";
  position: sticky;
  top: 10px;
  z-index: 10;
  min-height: 100px;
  margin-bottom: 5px;
  padding: 30px 5%;
  transition: all 0.3s ease-in-out;
`;

const StockFilterContainer = styled.div`
  font-weight: 500;
`;
const SearchContainer = styled.div`
  /* width: 70%; */
`;
interface Filter {
  productType: ProductType.Drink | ProductType.Food;
  inStockFilterChecked: boolean;
  setProductList: React.Dispatch<React.SetStateAction<ProductInterface[]>>;
  setInStockFilterChecked: React.Dispatch<React.SetStateAction<boolean>>;
}
const DesktopFilter = (props: Filter) => {
  const {
    productType,
    setInStockFilterChecked,
    inStockFilterChecked,
    setProductList,
  } = props;
  const dispatch = useDispatch();
  const [openCategoryManagement, setOpenCategoryManagement] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue.toLowerCase());
  const categories = useSelector((state: RootState) =>
    productType === ProductType.Food
      ? state.Product.foodCategories
      : state.Product.drinkCategories
  );
  const products = useSelector((state: RootState) =>
    productType === ProductType.Food ? state.Product.food : state.Product.drinks
  );
  const language = useSelector(
    (state: RootState) => state.Auth.user.defaultLanguage
  );
  const activeCategoryFilter = useSelector(
    (state: RootState) => state.Product.activeCategoryFilterId
  );
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const filterByCategoryTranslation = useTranslation("filter_by_category");
  const manageCategories = useTranslation("manage_categories");
  const inStockTranslation = useTranslation("in_stock_label");
  const findAProduct = useTranslation("find_a_product");
  const allTranslation = useTranslation("all");
  const expandFilterText = useTranslation("expand_filter_text");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsCollapsed(scrollPosition > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const query = debouncedSearchValue.trim();
    if (products.length && language) {
      const filteredProducts = products.filter((product) =>
        product.names[language].toLowerCase().includes(query)
      );
      if (!filteredProducts.length) {
        alert("Your search returned no result");
        setSearchValue("");
      } else {
        setProductList(filteredProducts);
      }
    }
  }, [debouncedSearchValue, language, products, setProductList]);

  useEffect(() => {
    dispatch(setActiveCategoryFilter({ categoryId: "All" }));
    //eslint-disable-next-line
  }, []);

  const filterChangeHandler = (value: string) => {
    dispatch(setActiveCategoryFilter({ categoryId: value }));
    setSearchValue("");
    if (value === "All") {
      let all: ProductInterface[] = [];
      categories.forEach((category) => {
        if (category.food) all = [...all, ...category.food];
        if (category.drinks) all = [...all, ...category.drinks];
      });
      if (productType === ProductType.Drink) {
        dispatch(setDrinks(all));
      } else if (productType === ProductType.Food) {
        dispatch(setFood(all));
      }
    } else {
      const filteredCategory = categories.filter(
        (category) => category.id === value
      )[0];
      if (productType === ProductType.Drink && filteredCategory.drinks) {
        dispatch(setDrinks(filteredCategory.drinks));
      } else if (productType === ProductType.Food && filteredCategory.food) {
        dispatch(setFood(filteredCategory.food));
      }
    }
  };

  const checkboxLabel = { inputProps: { "aria-label": "Checkbox demo" } };

  if (!categories.length) {
    return <></>;
  }

  return (
    <FilterWrapper>
      <FormControl sx={{ mt: isCollapsed ? 5 : 0, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            justifyContent: "space-between",
            width: "100%",
            mb: 2,
          }}
        >
          <Box>
            <FormLabel
              id="row-restaurant-category-group-label"
              sx={{
                color: "primary.main",
                fontWeight: "500",
                mb: 1,
                display: isCollapsed ? "none" : "unset",
              }}
            >
              {filterByCategoryTranslation}
            </FormLabel>
            <RadioGroup
              aria-labelledby="row-restaurant-category"
              name="row-radio-buttons-group"
              sx={{
                width: "100%",
                flexDirection: "row",
                display: isCollapsed ? "none" : "flex",
                flexWrap: "wrap",
                gap: 1,
                whiteSpace: "nowrap",
                justifyContent: "flex-start",
                "& > *": {
                  minWidth: "130px",
                },
              }}
            >
              <FormControlLabel
                value="All"
                control={<Radio />}
                checked={activeCategoryFilter === "All"}
                onChange={() => filterChangeHandler("All")}
                label={allTranslation}
              />
              {categories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  value={category.id}
                  control={<Radio />}
                  onChange={() => filterChangeHandler(category.id)}
                  label={capitalizeWords(category.name)}
                />
              ))}
            </RadioGroup>
          </Box>

          <StockFilterContainer className="stock">
            <FormControl
              sx={{
                display: isCollapsed ? "none" : "unset",
                whiteSpace: "nowrap",
              }}
            >
              <FormControlLabel
                id="row-restaurant-category-group-label"
                control={
                  <Checkbox
                    {...checkboxLabel}
                    checked={inStockFilterChecked}
                    onChange={() =>
                      setInStockFilterChecked(!inStockFilterChecked)
                    }
                  />
                }
                label={inStockTranslation}
              />
            </FormControl>
          </StockFilterContainer>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: isCollapsed ? "end" : "center",
            width: "100%",
            justifyContent: isCollapsed ? "space-between" : "flex-start",
            gap: "50px",
          }}
        >
          <Button
            variant="text"
            sx={{
              display: isCollapsed ? "none" : "unset",
              textTransform: "none",
              maxWidth: "max-content",
              p: "0px",
              lineHeight: "1",
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
            onClick={() => setOpenCategoryManagement(true)}
          >
            {manageCategories}
          </Button>
          <SearchContainer className="search">
            <FormControl>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <TextField
                  id="input-with-sx"
                  placeholder={findAProduct}
                  variant="standard"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded
                          sx={{ color: "action.active", fontSize: "20px" }}
                        />
                      </InputAdornment>
                    ),
                    sx: {
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: "16px",
                      },
                      "& .MuiInputBase-input": {
                        minWidth: {
                          xs: "500px",
                          lg: "600px",
                          xl: "700px",
                        },
                        maxWidth: "100%",
                        transition: "min-width 0.3s ease-in-out",
                      },
                    },
                  }}
                />
              </Box>
            </FormControl>
          </SearchContainer>

          <Box
            component="div"
            sx={{
              display: isCollapsed ? "unset" : "none",
              lineHeight: "1",
              cursor: "pointer",
              color: "primary.main",
              fontWeight: "500",
              p: 0,
              whiteSpace: "nowrap",
            }}
            onClick={() => setIsCollapsed(false)}
          >
            {expandFilterText}
          </Box>
        </Box>
      </FormControl>

      <CategoryList
        open={openCategoryManagement}
        onClose={() => setOpenCategoryManagement(false)}
        categories={categories}
        productType={productType}
      />
    </FilterWrapper>
  );
};

export default DesktopFilter;
