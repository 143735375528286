import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Categories,
  Category,
  Media,
  ProductInterface,
} from "../../types/common.types";
import { ProductType } from "../../components/product";
import { SetFileSelection } from "./types/actionTypes";

interface SliceInterface {
  drinks: Array<ProductInterface>;
  food: Array<ProductInterface>;
  drinkCategories: Array<Category>;
  foodCategories: Array<Category>;
  categories: Array<Category>;
  filteredCategories: Array<{ id: string; name: string }>;
  selectedFile: File | null;
  selectedFileInBase64: string | ArrayBuffer | null;
  selectedFilePreview: string;
  newCategoryName: string;
  newProductType: ProductType.Food | ProductType.Drink | null;
  activeCategoryFilterId: string;
  media: Media[];
  selectedMedia: Media;
}
const initialState: SliceInterface = {
  drinks: [],
  food: [],
  categories: [],
  foodCategories: [],
  drinkCategories: [],
  filteredCategories: [],
  selectedFile: null,
  selectedFileInBase64: "",
  selectedFilePreview: "",
  newCategoryName: "",
  newProductType: null,
  activeCategoryFilterId: "All",
  media: [],
  selectedMedia: { photo: "", id: "", tags: [] },
};

export const ProductSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    setDrinks: (state, action: PayloadAction<Array<ProductInterface>>) => {
      state.drinks = action.payload;
    },
    setFood: (state, action: PayloadAction<Array<ProductInterface>>) => {
      state.food = action.payload;
    },
    getCategories: (state, action: PayloadAction<Categories>) => {
      const categories = action.payload.categories;
      state.categories = categories;
      if (action.payload.productType === "Food") {
        state.foodCategories = categories;
      } else if (action.payload.productType === "Drink") {
        state.drinkCategories = categories;
      }
    },
    setFileSelection: (state, action: PayloadAction<SetFileSelection>) => {
      state.selectedFile = action.payload.file;
      state.selectedFileInBase64 = action.payload.fileInBase64;
      state.selectedFilePreview = action.payload.preview;
      state.selectedMedia = { id: "", tags: [], photo: "" };
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.newCategoryName = action.payload;
    },
    setProductAddType: (state, action: PayloadAction<ProductType | null>) => {
      state.newProductType = action.payload;
    },
    spreadProductUpdate: (
      state,
      action: PayloadAction<{
        product: ProductInterface;
        productType: ProductType;
      }>
    ) => {
      const { product, productType } = action.payload;
      if (productType === ProductType.Drink) {
        const drinks = state.drinks.map((drink) => {
          if (drink.id === product.id) {
            drink = product;
          }
          return drink;
        });
        return { ...state, drinks };
      } else if (productType === ProductType.Food) {
        const food = state.food.map((foodItem) => {
          if (foodItem.id === product.id) {
            foodItem = product;
          }
          return foodItem;
        });
        return { ...state, food };
      }
      return {
        ...state,
      };
    },
    setActiveCategoryFilter: (
      state,
      action: PayloadAction<{ categoryId: string }>
    ) => {
      state.activeCategoryFilterId = action.payload.categoryId;
      return state;
    },
    setMediaPhotos: (state, action: PayloadAction<{ media: any[] }>) => {
      state.media = action.payload.media;
      return state;
    },
    setMediaSelection: (state, action: PayloadAction<{ media: Media }>) => {
      state.selectedMedia = action.payload.media;
      state.selectedFileInBase64 = null;
      state.selectedFile = null;
      state.selectedFilePreview = "";
      return state;
    },
    clearMediaSelection: (state) => {
      state.selectedMedia = { photo: "", id: "", tags: [] };
      state.selectedFilePreview = "";
      state.selectedFileInBase64 = "";
      state.selectedFile = null;
      return state;
    },
  },
});

export const {
  getCategories,
  setFileSelection,
  setDrinks,
  setCategory,
  setFood,
  setProductAddType,
  setActiveCategoryFilter,
  setMediaPhotos,
  setMediaSelection,
  clearMediaSelection,
} = ProductSlice.actions;

export default ProductSlice.reducer;
