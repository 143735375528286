import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Category } from "../../../types/common.types";
import CategoryCard from "./CategoryCard";
import { ProductType } from "../../product";
import { useState } from "react";
import NewCategoryAdd from "../../form/NewCategoryAdd";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../../hooks/useTranslation";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",
  width: "100%",
  margin: "0 auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: { xs: "30px 3%", md: "50px 3% 30px" },
  maxHeight: "75vh",
  overflowY: "auto",
};

interface CategoryListProps {
  open: boolean;
  onClose: () => void;
  categories: Category[];
  productType: ProductType.Drink | ProductType.Food;
}

interface NewCategoryProps {
  open: boolean;
  onClose: () => void;
  productType: ProductType.Drink | ProductType.Food;
}

function NewCategory({ open, onClose, productType }: NewCategoryProps) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, maxWidth: 600 }}>
            <IconButton
              aria-label="close"
              onClick={() => onClose()}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                zIndex: 1000,
                backgroundColor: "primary.main",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#666666",
                },
                color: "white",
              }}
            >
              <CloseIcon sx={{ fontSize: "14px" }} />
            </IconButton>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                margin: "0 auto",
                padding: "20px",
                boxSizing: "border-box",
              }}
            >
              <NewCategoryAdd
                isAddMode={open}
                setNewCategoryActive={() => onClose()}
                productAddType={productType}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default function CategoryList({
  open,
  onClose,
  categories,
  productType,
}: CategoryListProps) {
  const [newCategoryMode, setNewCategoryMode] = useState<boolean>(false);

  const newTranslation = useTranslation("new");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={() => onClose()}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                zIndex: 1000,
                backgroundColor: "primary.main",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#666666",
                },
                color: "white",
              }}
            >
              <CloseIcon sx={{ fontSize: "14px" }} />
            </IconButton>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                gap: "0 30px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  height: "100px",
                  width: "150px",
                  borderRadius: "10px",
                }}
                onClick={() => setNewCategoryMode(true)}
              >
                {newTranslation}
              </Button>
              {categories.map((category) => (
                <CategoryCard
                  key={category.id}
                  category={category}
                  productType={productType}
                />
              ))}
            </div>
          </Box>
        </Fade>
      </Modal>
      {
        <NewCategory
          open={newCategoryMode}
          onClose={() => setNewCategoryMode(false)}
          productType={productType}
        />
      }
    </div>
  );
}
