import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { ProductAddSteps } from "../../types/common.types";
import { useSearchParams } from "react-router-dom";
import {
  FeedBack,
  ProductForm,
  StateGenerics,
  StepsInterface,
} from "./helper/addProductHelpers";
import { CreateProduct, SelectProductType } from "./mobile/MobileSteps";
import { useTranslation } from "../../hooks/useTranslation";
import { useDispatch } from "react-redux";
import {
  clearMediaSelection,
  setProductAddType,
} from "../../redux/slices/ProductState";
import { ProductType } from "../product";
import { SelectProductCategory } from "./desktop/SelectProductCategory";
import { CompletionFeedback } from "./desktop/CompletionFeedback";

export default function AddProductMobile(
  props: StateGenerics & ProductForm & FeedBack & StepsInterface
) {
  const {
    setActiveStep,
    steps,
    setSteps,
    setProductSelect,
    setSelectedCategory,
    selectedCategory,
    activeStep,
  } = props;
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const nextTranslation = useTranslation("next");
  const backTranslation = useTranslation("back");
  const dispatch = useDispatch();

  useEffect(() => {
    const productTypeParam = searchParams.get("product-type");

    const categoryId = searchParams.get("category") as string;

    function mapProductType(param: string | null): ProductType | null {
      switch (param) {
        case "Food":
          return ProductType.Food;
        case "Drink":
          return ProductType.Drink;
        default:
          return null;
      }
    }

    const productType = mapProductType(productTypeParam);

    if (productType !== null) {
      setSteps((prevSteps) => {
        const newSteps = prevSteps.map((step) => {
          if (step.stepId === ProductAddSteps.SelectProduct) {
            step.completed = true;
          }
          return step;
        });
        return newSteps;
      });
      setProductSelect(productType);
      dispatch(setProductAddType(productType));
      setActiveStep(ProductAddSteps.SelectCategory);
    }
    if (categoryId) {
      setSelectedCategory({ ...selectedCategory, id: categoryId });
      setActiveStep(ProductAddSteps.FillForm);
    }
    return () => {
      dispatch(clearMediaSelection());
    };
    //eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const step = steps[activeStep];

  switch (step.stepId) {
    case ProductAddSteps.SelectProduct:
      step.component = <SelectProductType handleNext={handleNext} {...props} />;
      break;
    case ProductAddSteps.SelectCategory:
      step.component = (
        <SelectProductCategory {...props} handleNext={handleNext} />
      );
      break;
    case ProductAddSteps.FillForm:
      step.component = <CreateProduct {...props} handleNext={handleNext} />;
      break;
    case ProductAddSteps.Feedback:
      step.component = <CompletionFeedback {...props} />;
      break;
    default:
      step.component = <></>;
  }

  const maxSteps = steps.length;

  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography sx={{ textAlign: "center", fontWeight: "500" }}>
          {step.label}
        </Typography>
      </Paper>

      <Box
        sx={{
          minHeight: "80vh",
          maxWidth: 400,
          width: "100%",
          p: 2,
          marginBottom: "20px",
        }}
      >
        {step.component}
        <Box
          sx={{ height: "56px", width: "100%" }}
          className="stepper-nav-offset"
        />
      </Box>

      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="bottom"
        activeStep={activeStep}
        sx={{ textAlign: "center", paddingLeft: "55px", paddingRight: "15px" }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1 || !step.completed}
          >
            {nextTranslation}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {backTranslation}
          </Button>
        }
      />
    </Box>
  );
}
