import React from "react";
import { MODAL } from "../helpers/constants";
import ProductView from "./ProductView";
import WriteProduct from "./WriteProduct";
import { ModalSubComponent } from "../../../types/common.types";

const ModalBody = (props: ModalSubComponent) => {
  const { modalMode, activeProduct, productType } = props;

  switch (modalMode) {
    case MODAL.EDIT:
    case MODAL.VIEW:
      return (
        <ProductView
          activeProduct={activeProduct}
          modalMode={modalMode}
          productType={productType}
        />
      );

    case MODAL.WRITE:
      return (
        <WriteProduct
          modalMode={modalMode}
          product={activeProduct}
          productType={productType}
        />
      );

    default:
      return <></>;
  }
};

export default ModalBody;
