import moment from "moment";
import {
  Currency,
  Language,
  MediaSource,
  ProductInterface,
} from "../types/common.types";
import { useEffect, useState } from "react";

export const currencies: Array<Currency> = [
  {
    name: "Euro",
    prefix: "€",
  },
  {
    name: "Dollar",
    prefix: "$",
  },
  {
    name: "Naira",
    prefix: "₦",
  },
];

export const languages: Array<Language> = [
  { name: "English", iso: "en" },
  { name: "Lithuanian", iso: "lt" },
  { name: "Yoruba", iso: "yo" },
  { name: "Pidgin", iso: "pi" },
  { name: "Igbo", iso: "ib" },
  { name: "Hausa", iso: "ha" },
];

export const isNightMode = (): boolean => {
  const time = moment().format("h A").split(" ");
  // eslint-disable-next-line
  const hour = Number(time[0]);
  // eslint-disable-next-line
  const meridian = time[1];

  return false; //(hour > 8 && meridian === "PM") || (meridian === "AM" && hour < 6);
};

export const emptyProductObject: ProductInterface = {
  id: "",
  names: {},
  photo: "",
  descriptions: {},
  categoryId: "",
  price: 0,
  mediaSource: MediaSource.MediaPicker,
  prices: {},
  inStock: false,
  productPhoto: {
    id: "",
    mediaSource: MediaSource.MediaPicker,
    secureUrl: "",
    publicId: "",
  },
  // sales: [{}],
};

export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    let timeoutId: any = 0;

    function handleResize() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWindowSize(getSize()), 150);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  return windowSize;
}

export const money = (
  amount: number,
  currencySymbol: string,
  withCurrency: boolean
) => {
  let currency = "";
  if (withCurrency) currency = currencySymbol;

  return `${currency}${amount.toLocaleString()}`;
};

export function capitalizeFirstLetter(str: string) {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
