export enum MODAL {
  NULL,
  VIEW,
  EDIT,
  WRITE,
}
export const FORM_FIELDS = {
  CATEGORY: {
    NAME: "categoryId",
    ADD_NEW: "new",
  },
  NAME: "name",
  STOCK_COUNT: "stockCount",
  PRICE: "price",
  DESCRIPTION: "description",
};
export const ROUTES = {
  BASE: "dashboard",
  DrinksPage: "drinks",
};
/**BUGs
 * 1. View a drink and switch modal mode to edit. Close modal (while in edit mode), click add drink button and then close
 *    the add drink modal. View a drink again, and there is a fallthrough between drink edit and view modes. Suspicion
 *    is with switch case fall through in ModalBody.tsx#18.
 * */
