import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ProductTab from "../ProductTab";
import SalesTab from "../SalesTab";
import { ModalSubComponent } from "../../../types/common.types";

interface EditProps extends ModalSubComponent {}
const ProductView = (props: EditProps) => {
  const { activeProduct, modalMode, productType } = props;
  return (
    <Tabs
      defaultActiveKey="details"
      id="drinksModalTab"
      className="mb-2 custom-tab"
    >
      <Tab
        eventKey="details"
        title={<span style={{ color: "inherit" }}>Details</span>}
      >
        <ProductTab
          activeProduct={activeProduct}
          modalMode={modalMode}
          productType={productType}
        />
      </Tab>
      <Tab
        eventKey="sales"
        title={<span style={{ color: "inherit" }}>Sales</span>}
      >
        <SalesTab drink={activeProduct} />
      </Tab>
    </Tabs>
  );
};

export default ProductView;
