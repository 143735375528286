import React from "react";
import DesktopOrder from "../components/order/DesktopOrder";
import { useWindowSize } from "../helpers/generic";
import MobileOrder from "../components/order/MobileOrder";
import { Box } from "@mui/material";

const Order = () => {
  const isMobile = useWindowSize().width < 900;
  return (
    <Box sx={{ minHeight: "max-content" }}>
      {isMobile ? <MobileOrder /> : <DesktopOrder />}
    </Box>
  );
};

export default Order;
