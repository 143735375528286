import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import {
  FeedBack,
  isStepOptional,
  ProductForm,
  StateGenerics,
  StepsInterface,
} from "./helper/addProductHelpers";
import { FEEDBACK_ALERTS, ProductAddSteps } from "../../types/common.types";
import { palette } from "../../assets/color";
import { SelectProductType } from "./desktop/SelectProductType";
import { StepsNavigator } from "./desktop/StepNavigator";
import { CompletionFeedback } from "./desktop/CompletionFeedback";
import { SelectProductCategory } from "./desktop/SelectProductCategory";
import NewProductCreate from "./desktop/CreateProduct";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";
import { useDispatch } from "react-redux";
import { updateFeedback } from "../../redux/slices/AppState";
import { FEEDBACK_VARIANT } from "../extras/Alert";
import {
  clearMediaSelection,
  setProductAddType,
} from "../../redux/slices/ProductState";
import { ProductType } from "../product";

export default function AddProductDesktop(
  props: StateGenerics & ProductForm & FeedBack & StepsInterface
) {
  const [searchParams] = useSearchParams();

  const { setActiveStep, activeStep, steps } = props;

  const optionalTranslation = useTranslation("optional");

  const dispatch = useDispatch();

  useEffect(() => {
    //Note: This useEffect is used to persist stepper progress on page reload.

    const productTypeParam = searchParams.get("product-type");
    const categoryId = searchParams.get("category") as string;

    function mapProductType(param: string | null): ProductType | null {
      switch (param) {
        case "Food":
          return ProductType.Food;
        case "Drink":
          return ProductType.Drink;
        default:
          return null;
      }
    }

    const productType = mapProductType(productTypeParam);

    if (productType !== null) {
      props.setSteps((prevSteps) => {
        return prevSteps.map((step) => {
          if (step.stepId === ProductAddSteps.SelectProduct) {
            step.completed = true;
          }
          if (categoryId && step.stepId === ProductAddSteps.SelectCategory) {
            step.completed = true;
          }
          return step;
        });
      });

      props.setProductSelect(productType);
      dispatch(setProductAddType(productType));
      setActiveStep(ProductAddSteps.SelectCategory);

      if (categoryId) {
        props.setSelectedCategory({
          ...props.selectedCategory,
          id: categoryId,
        });
        setActiveStep(ProductAddSteps.FillForm);
      }
    }
    return () => {
      dispatch(clearMediaSelection());
    };
    //eslint-disable-next-line
  }, []);

  /** Navigates stepper component to next step
   * @param justCompleted provides context to handler that a step has just been completed. This is helpful when
   * distinguishing between page reload on a completed step and activating step completion by fulfilling a step's requirement.
   * */
  const handleNext = (justCompleted?: boolean) => {
    if (!justCompleted && !steps[activeStep].completed) {
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message: FEEDBACK_ALERTS.CREATE_PRODUCT.STEP_REQUIRED,
        })
      );

      return;
    }
    steps[activeStep].completed = true;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const step = steps[activeStep];

  switch (step.stepId) {
    case ProductAddSteps.SelectProduct:
      step.component = <SelectProductType {...props} handleNext={handleNext} />;
      break;
    case ProductAddSteps.SelectCategory:
      step.component = (
        <SelectProductCategory {...props} handleNext={handleNext} />
      );
      break;
    case ProductAddSteps.FillForm:
      step.component = <NewProductCreate {...props} handleNext={handleNext} />;
      break;
    case ProductAddSteps.Feedback:
      step.component = <Box>Feed back component</Box>;
      break;
    default:
      step.component = <></>;
  }

  const isLastStep = activeStep === steps.length - 1;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "inherit", backgroundColor: palette.body }}>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {
              optional: isStepOptional(step.stepId) ? (
                <Typography variant="caption">{optionalTranslation}</Typography>
              ) : (
                <></>
              ),
            };
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      {isLastStep && <CompletionFeedback {...props} />}

      {!isLastStep && (
        <React.Fragment>
          <Box sx={{ mt: 2, mb: 1, height: "72vh" }}>{step.component}</Box>

          <StepsNavigator
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            isOptional={isStepOptional(activeStep)}
            isStepsCompleted={isLastStep}
          />
        </React.Fragment>
      )}
    </Box>
  );
}
