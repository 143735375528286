// src/context/UpgradeContext.tsx
import React, { createContext, ReactNode, useContext, useState } from "react";
import { MembershipTier } from "../../types/common.types";
import { upgradeMembership } from "../../helpers/xhr";
import { set } from "lockr";
import { useTranslation } from "../../hooks/useTranslation";

interface UpgradeContextProps {
  startUpgradeJourney: (
    upgradeType: MembershipTier,
    showFeedback: (message: string, type: "success" | "error") => void,
    setIframeUrl: (url: string) => void
  ) => void;
  isUpgrading: boolean;
}

const UpgradeContext = createContext<UpgradeContextProps | undefined>(
  undefined
);

export function UpgradeProvider({ children }: { children: ReactNode }) {
  const [isUpgrading, setIsUpgrading] = useState(false);

  const failedPaymentInit = useTranslation("payment_initialization_failed");
  const errorStartingUpgrade = useTranslation("error_starting_upgrade_process");

  const startUpgradeJourney = async (
    upgradeType: MembershipTier,
    showFeedback: (message: string, type: "success" | "error") => void,
    setIframeUrl: (url: string) => void
  ) => {
    try {
      setIsUpgrading(true);

      const response = await upgradeMembership(upgradeType);

      const { success, authorizationUrl, reference } = response.data;

      set("pRef", reference);

      if (success && authorizationUrl) {
        setIframeUrl(authorizationUrl);
      } else {
        showFeedback(`${failedPaymentInit}.`, "error");
      }
    } catch (error) {
      console.error("Error starting upgrade journey:", error);
      showFeedback(`${errorStartingUpgrade}.`, "error");
    } finally {
      setIsUpgrading(false);
    }
  };

  return (
    <UpgradeContext.Provider value={{ startUpgradeJourney, isUpgrading }}>
      {children}
    </UpgradeContext.Provider>
  );
}

export const useUpgrade = () => {
  const context = useContext(UpgradeContext);
  if (!context) {
    throw new Error("useUpgrade must be used within an UpgradeProvider");
  }
  return context;
};
