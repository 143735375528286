import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setCategory } from "../../redux/slices/ProductState";
import { AddCategory } from "../../helpers/xhrHelper";
import { useTranslation } from "../../hooks/useTranslation";
import { ProductType } from "../product";

interface Props {
  isAddMode: boolean;
  productAddType: ProductType;
  setNewCategoryActive: (isNewCategory: boolean) => void;
}
const NewCategoryAdd = ({
  isAddMode,
  setNewCategoryActive,
  productAddType,
}: Props) => {
  const categoryName = useSelector(
    (state: RootState) => state.Product.newCategoryName
  );
  const dispatch = useDispatch();

  const categoryNameTranslation = useTranslation("category_name");
  const abortTranslation = useTranslation("abort");
  const submitTranslation = useTranslation("submit");

  if (!isAddMode) {
    return <></>;
  }

  const addCategoryHandler = async () => {
    if (!categoryName) return;
    setNewCategoryActive(false);
    await AddCategory(dispatch, categoryName, productAddType, true);
  };

  return (
    <Row className="m-2">
      <Col sm={12} md={8} className="pb-2">
        <Form.Control
          type="text"
          placeholder={categoryNameTranslation}
          autoFocus
          value={categoryName}
          onChange={(e) => dispatch(setCategory(e.target.value))}
        />
      </Col>
      <Col sm={12} md={4}>
        <Row>
          <Col xs={3} sm={6}>
            <Button variant="light" onClick={() => setNewCategoryActive(false)}>
              {abortTranslation}
            </Button>
          </Col>
          <Col xs={3} sm={6}>
            <Button variant="light" onClick={() => addCategoryHandler()}>
              {submitTranslation}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NewCategoryAdd;
