import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MembershipTier } from "../../../types/common.types";
import CustomButton from "../../common/CustomButton";
import { FlexContainer } from "../../styled/Generic";
import UpgradeModal from "../../upgrade/UpgradeModal";
import { useTranslation } from "../../../hooks/useTranslation";

export const LinksWrapper = styled.div`
  a {
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease, transform 0.3s ease;
    color: #6e151e;
    font-weight: 500;

    &:hover {
      transform: translateY(-1px);
      color: #5e0808a9;
    }
  }
`;

interface PlanContainerProps {
  user: any;
  staffs: any[];
  product: any;
  handleUpgradeClick: () => void;
  showUpgradeModal: boolean;
  setShowUpgradeModal: (state: boolean) => void;
}

const MembershipPlan: React.FC<PlanContainerProps> = ({
  user,
  staffs,
  product,
  handleUpgradeClick,
  showUpgradeModal,
  setShowUpgradeModal,
}) => {
  const accountSettings = useTranslation("account_settings");
  const planDetails = useTranslation("plan_details_title");
  const planLabel = useTranslation("plan_label");
  const segmentsLabel = useTranslation("segments_label");
  const subAccountsLabel = useTranslation("sub_accounts_label");
  const productsLabel = useTranslation("products_label");
  const ordersLabel = useTranslation("orders_label");
  const notEligibleText = useTranslation("not_eligible_text");
  const unlimitedText = useTranslation("unlimited_text");
  const upgradePlan = useTranslation("upgrade_plan_btn_text");

  const shouldShowUpgradeMessage =
    user.membershipDetails.tier !== MembershipTier.PREMIUM;

  const planDetail = [
    {
      label: planLabel,
      value: user.membershipDetails.name,
    },
    {
      label: segmentsLabel,
      value:
        user.membershipDetails.tier !== MembershipTier.PREMIUM
          ? `${user.segments.length} of ${user.membershipDetails.limits.segment}`
          : `${user.segments.length}`,
    },
    {
      label: subAccountsLabel,
      value:
        user.membershipDetails.tier !== MembershipTier.PREMIUM
          ? `${staffs.length} of ${user.membershipDetails.limits.staff}`
          : `${staffs.length}`,
    },
    {
      label: productsLabel,
      value:
        user.membershipDetails.tier !== MembershipTier.PREMIUM
          ? `${product.food.length + product.drinks.length} of ${
              user.membershipDetails.limits.product
            }`
          : `${product.food.length + product.drinks.length}`,
    },
    {
      label: ordersLabel,
      value:
        user.membershipDetails.tier === MembershipTier.BASIC ||
        user.membershipDetails.tier === MembershipTier.FREE
          ? notEligibleText
          : unlimitedText,
    },
  ];

  return (
    <Box
      sx={{
        borderLeft: "1px solid #808080",
        padding: "10px 5px 20px",
        width: { md: "40%", lg: "45%", xl: "50%" },
      }}
      className="plan-container"
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
          borderBottom: "1px solid #808080",
          px: 2,
        }}
      >
        <LinksWrapper>
          <Typography variant="h4">{user.legalName}</Typography>
          <Link to="/app/settings">{accountSettings}</Link>
        </LinksWrapper>

        <div>
          <Avatar
            sx={{
              border: "1px solid #6e151e",
              color: "#000",
              background: "transparent",
            }}
          >
            {user.legalName && user.legalName.charAt(0).toUpperCase()}
          </Avatar>
        </div>
      </Box>

      <Box sx={{ padding: { xs: "20px 10px 0", sm: "20px 20px 0px" } }}>
        <Typography variant="h5" sx={{ mb: "20px" }}>
          {planDetails}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "15px", md: "25px" },
            maxWidth: { xs: "100%", sm: "750px" },
            Width: "100%",
          }}
        >
          {planDetail.map((detail, index) => (
            <FlexContainer
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" sx={{ fontWeight: "500", mr: 2 }}>
                {detail.label}:
              </Typography>

              <div className="plan-value">
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                  {detail.value}
                </Typography>
              </div>
            </FlexContainer>
          ))}
        </Box>

        <div>
          {shouldShowUpgradeMessage && (
            <Box sx={{ mt: 4 }}>
              <CustomButton
                onClick={handleUpgradeClick}
                size="large"
                sx={{
                  height: "46px",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                }}
              >
                {upgradePlan}
              </CustomButton>
            </Box>
          )}
        </div>
      </Box>
      <UpgradeModal
        currentTier={user.membershipDetails.tier}
        open={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </Box>
  );
};

export default MembershipPlan;
