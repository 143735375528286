import React, { memo, useEffect } from "react";

import Box from "@mui/material/Box";
import SelfUpload from "../SelfUpload";
import { Chip, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GetMedia } from "../../../../helpers/xhrHelper";
import MediaCard from "./MediaCard";
import { ProductType } from "../../../product";
import { useTranslation } from "../../../../hooks/useTranslation";

interface ProductPhoto {
  productType?: ProductType;
}

/*
 * The backend should return media based on
 * - active frontend category -- filter user tags
 * - active product type -- filter user tags
 * - user jurisdictions
 **/

const MediaPicker = memo((props: ProductPhoto) => {
  const media = useSelector((state: RootState) => state.Product.media);
  const productType = useSelector(
    (state: RootState) => state.Product.newProductType
  );

  const dispatch = useDispatch();

  const pickFromMedia = useTranslation("pick_from_media_label");

  useEffect(() => {
    //fixme: Api requests made on viewport resizing.
    if (productType) GetMedia(dispatch, productType);
    //eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <SelfUpload />
      <Divider>
        <Chip label={pickFromMedia} size="small" />
      </Divider>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          columnGap: 1,
          rowGap: 1,
          padding: "10px",
        }}
      >
        {media.map((photo) => (
          <MediaCard key={photo.id} media={photo} />
        ))}
      </Box>
    </Box>
  );
});

export default MediaPicker;
