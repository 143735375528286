import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { isNightMode } from "./helpers/generic";
import { initiateSocketIo } from "./helpers/socketEvents";
import router from "./router";
import { get } from "lockr";
import { User } from "./types/common.types";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";

const body = document.getElementById("body");
if (isNightMode() && body) {
  body.style.backgroundColor = "#2b2b2b";
  body.style.color = "white";
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const initializeApp = async () => {
  const user = get("user") as User;
  if (user && !window.socket) {
    await initiateSocketIo(user.canPlaceOrder, store.dispatch);
  }
};

const renderApp = () => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

initializeApp().then(() => {
  return renderApp();
});
