import { Box, Typography } from "@mui/material";

interface Props {
  children: any;
  settingHeaderText: string;
}
export const SettingWrapper = (props: Props) => {
  return (
    <Box>
      <Typography variant="h4" pb="30px">
        {props.settingHeaderText}
      </Typography>
      <Box sx={{ mb: 4 }}>{props.children}</Box>
    </Box>
  );
};
