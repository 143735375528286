import React from "react";
import styled from "styled-components";
import { ProductType } from "../product";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "../../hooks/useTranslation";

const StyledAddProductWrp = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const YouHaveNoProduct = (props: { productType: number }) => {
  const noProduct = require("../../assets/no-order.png");
  const navigate = useNavigate();
  const categoryId = useSelector(
    (state: RootState) => state.Product.activeCategoryFilterId
  );

  const addProductTranslation = useTranslation("add_product");
  const youHaveNoDrinkProduct = useTranslation("you_have_no_drink_product");
  const youHaveNoFoodProduct = useTranslation("you_have_no_food_product");

  const noProductText =
    props.productType === ProductType.Drink
      ? `${youHaveNoDrinkProduct}`
      : `${youHaveNoFoodProduct}`;
  const productTypeQuery =
    props.productType === ProductType.Drink ? "Drink" : "Food";

  const handleAddProductClick = () => {
    if (categoryId === "All") {
      navigate(`/app/add-product?product-type=${productTypeQuery}`);
    } else {
      navigate(
        `/app/add-product?product-type=${productTypeQuery}&category=${categoryId}`
      );
    }
  };

  return (
    <StyledAddProductWrp>
      <Box
        component="img"
        src={noProduct}
        alt="empty product"
        sx={{
          width: 100,
          mb: 2,
        }}
      />
      <Typography variant="h5" sx={{ mb: 2 }}>
        {noProductText}
      </Typography>

      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          p: "10px 20px",
          borderRadius: "8px",
          minWidth: "159px",
          color: "#fff",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.light",
            color: "#fff",
          },
        }}
        onClick={handleAddProductClick}
      >
        {addProductTranslation}
      </Button>
    </StyledAddProductWrp>
  );
};

export default YouHaveNoProduct;
