import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import {
  Category,
  Currency,
  Language,
  MediaSource,
  NewProduct,
  ProductAddSteps,
  Segment,
  User,
} from "../../../types/common.types";
import { palette } from "../../../assets/color";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Form } from "react-bootstrap";
import Name from "../../form/Name";
import Price from "../../form/Price";
import Description from "../../form/Description";
import { get } from "lockr";
import { FormFields } from "../../form/ModalProductUpdate";
import { Category as ProductAddCategory } from "../../form/Category";
import { updateFeedback } from "../../../redux/slices/AppState";
import { FEEDBACK_VARIANT } from "../../extras/Alert";
import { productFormSubmit } from "../../form/formUtils";
import CheckIcon from "@mui/icons-material/Check";
import {
  FeedBack,
  ProductForm,
  StepsInterface,
  updateURLSearchParams,
} from "../helper/addProductHelpers";
import { AddProductGeneric, StateGenerics } from "../helper/addProductHelpers";
import { useTranslation } from "../../../hooks/useTranslation";
import MediaPicker from "../../form/media-picker/mobile/MediaPicker";
import { setProductAddType } from "../../../redux/slices/ProductState";
import { ProductType } from "../../product";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "../../../helpers/format";

export const SelectProductType = (
  props: AddProductGeneric & StateGenerics & StepsInterface
) => {
  const dispatch = useDispatch();
  const { setProductSelect, selectedProduct, handleNext, steps, setSteps } =
    props;

  const drinkText = useTranslation("product_type_drink");
  const foodText = useTranslation("product_type_food");

  const itemStyle: React.CSSProperties = {
    padding: "20px",
    margin: "5px 0",
    textAlign: "center",
  };
  const getActiveStyles = (item: ProductType): React.CSSProperties => {
    const itemIsActive = selectedProduct === item;
    return {
      backgroundColor: itemIsActive ? palette.primaryBg : "",
      color: itemIsActive ? palette.body : "",
    };
  };

  const handleProductTypeClick = (type: ProductType) => {
    setProductSelect(type);
    setSteps((prevSteps) => {
      const newSteps = steps.map((step) => {
        if (step.stepId === ProductAddSteps.SelectProduct)
          step.completed = true;
        return step;
      });

      return newSteps;
    });
    updateURLSearchParams("product-type", type);
    dispatch(setProductAddType(type));
    handleNext();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "60vh",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Paper
        style={itemStyle}
        sx={getActiveStyles(ProductType.Drink)}
        onClick={() => handleProductTypeClick(ProductType.Drink)}
      >
        {drinkText}
      </Paper>
      <Paper
        style={itemStyle}
        sx={getActiveStyles(ProductType.Food)}
        onClick={() => handleProductTypeClick(ProductType.Food)}
      >
        {foodText}
      </Paper>
    </Box>
  );
};

export const ProductCategorySelect = (
  props: AddProductGeneric & StateGenerics
) => {
  const { selectedProduct, selectedCategory, setSelectedCategory, handleNext } =
    props;
  let productCategories: "drinkCategories" | "foodCategories" =
    "drinkCategories";
  if (selectedProduct === ProductType.Food)
    productCategories = "foodCategories";
  const categories = useSelector(
    (state: RootState) => state.Product[productCategories]
  );
  const itemStyle: React.CSSProperties = {
    padding: "15px",
    margin: "9px 0",
    textAlign: "center",
  };
  const getActiveStyles = (categoryId: string): React.CSSProperties => {
    const itemIsActive = selectedCategory.id === categoryId;
    return {
      backgroundColor: itemIsActive ? palette.primaryBg : "",
      color: itemIsActive ? palette.body : "",
    };
  };

  const handleCategorySelect = (category: Category) => {
    setSelectedCategory(category);
    updateURLSearchParams("category", category.id);
    handleNext();
  };

  return (
    <Box>
      {categories.map((category) => {
        return (
          <Paper
            key={category.id}
            style={itemStyle}
            sx={getActiveStyles(category.id)}
            onClick={() => handleCategorySelect(category)}
          >
            {capitalizeWords(category.name)}
          </Paper>
        );
      })}
    </Box>
  );
};

export const CreateProduct = (
  props: ProductForm & StateGenerics & AddProductGeneric & StepsInterface
) => {
  const productState = useSelector((state: RootState) => state.Product);
  const user = useSelector((state: RootState) => state.Auth.user);
  const dispatch = useDispatch();
  const {
    selectedProduct,
    selectedCategory,
    handleNext,
    setFormSuccess,
    setCreatedProductNames,
    setSteps,
  } = props;
  const userData = get("user") as User | undefined;
  let languages: Language[] = [];
  let currency: Currency = { name: "", prefix: "" };
  let segments: Segment[] = [];
  if (userData) {
    languages = userData.languages;
    currency = userData.currency;
    segments = userData.segments;
  }

  const [fields, setFields] = useState<FormFields>({
    names: {},
    descriptions: {},
    category: selectedCategory.id,
    price: "",
    prices: {},
    inStock: true,
  });

  const nameTranslation = useTranslation("new_product_name");
  const priceTranslation = useTranslation("new_product_price");
  const categoryTranslation = useTranslation("new_product_category");
  const descriptionTranslation = useTranslation("new_product_description");
  const submitTranslation = useTranslation("new_product_submit");

  if (!selectedProduct) return <>Product type is not selected</>;

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { selectedFileInBase64, selectedMedia } = productState;

    if (!fields.category) {
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message: `Please add a category`,
        })
      );
      return;
    }

    let productPhoto;
    let mediaSource = MediaSource.Upload;

    if (typeof selectedFileInBase64 === "string") {
      productPhoto = selectedFileInBase64;
    } else if (selectedMedia.photo) {
      mediaSource = MediaSource.MediaPicker;
      productPhoto = selectedMedia.id;
    }

    if (!productPhoto) {
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          timeout: 36000,
          message: `Upload a photo for: "${
            fields.names[user.defaultLanguage]
          }"`,
        })
      );
      return;
    }

    const product: NewProduct = {
      descriptions: fields.descriptions,
      inStock: fields.inStock,
      names: fields.names,
      price: Number(fields.price),
      prices: fields.prices,
      categoryId: fields.category,
      photo: productPhoto,
      id: "",
      mediaSource,
    };

    productFormSubmit(
      product,
      dispatch,
      "New",
      selectedProduct === ProductType.Drink
    ).then((isProcessSuccessful) => {
      handleNext();
      setFormSuccess(isProcessSuccessful);
      setCreatedProductNames(fields.names);
      setSteps((prevSteps) => {
        const newSteps = prevSteps.map((step) => {
          if (step.stepId !== ProductAddSteps.SelectCategory)
            step.completed = false;
          return step;
        });
        return newSteps;
      });
    });
  };

  return (
    <Form onSubmit={(e) => submitHandler(e)}>
      <Box>
        <MediaPicker />
      </Box>
      <Divider>
        <Chip label={nameTranslation} size="small" />
      </Divider>
      <Name languages={languages} formFields={fields} setFields={setFields} />
      <Divider>
        <Chip label={priceTranslation} size="small" />
      </Divider>
      <Price
        currency={currency}
        segments={segments}
        formFields={fields}
        setFields={setFields}
      />
      <Divider>
        <Chip label={categoryTranslation} size="small" />
      </Divider>
      <ProductAddCategory
        formFields={fields}
        setFields={setFields}
        productType={selectedProduct}
      />
      <Divider>
        <Chip label={descriptionTranslation} size="small" />
      </Divider>
      <Description
        languages={languages}
        formFields={fields}
        setFields={setFields}
      />
      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          type="submit"
          size={"large"}
          sx={{ width: "70%" }}
        >
          {submitTranslation}
        </Button>
      </Box>
    </Form>
  );
};

export const ProductCreateFeedback = (props: FeedBack & StateGenerics) => {
  const {
    isProductCreateSuccessful,
    productNames,
    selectedCategory,
    selectedProduct,
  } = props;
  const user = useSelector((state: RootState) => state.Auth.user);
  const lang = user.defaultLanguage;

  const navigate = useNavigate();

  const navigateToCreatedProductPage = () => {
    const productType =
      selectedProduct === ProductType.Drink ? "drinks" : "food";
    navigate(`/app/${productType}`);
  };

  if (!isProductCreateSuccessful) {
    return <>Yikes</>;
  }
  const ButtonStyle: React.CSSProperties = {
    marginBottom: "10px",
  };
  return (
    <Box>
      <Alert
        icon={<CheckIcon fontSize="inherit" />}
        sx={{ backgroundColor: "black", color: "white" }}
        variant="outlined"
      >
        <Typography color="green">
          {productNames[lang]} added to {selectedCategory.name}
        </Typography>
      </Alert>
      <Divider sx={{ marginTop: "20px" }}>
        <Chip label="Next Actions" size="small" />
      </Divider>
      <Box textAlign="center" marginTop={3} padding={2}>
        <Button
          variant="text"
          style={ButtonStyle}
          onClick={() => props.setActiveStep(ProductAddSteps.FillForm)}
        >
          Add another {selectedCategory.name} product
        </Button>
        <Button
          variant="text"
          style={ButtonStyle}
          onClick={() => {
            props.setProductSelect(null);
            props.setActiveStep(ProductAddSteps.SelectProduct);
          }}
        >
          Add a different product
        </Button>
        <Button
          variant="text"
          style={ButtonStyle}
          onClick={() => navigateToCreatedProductPage()}
        >
          See created product
        </Button>
      </Box>
    </Box>
  );
};
