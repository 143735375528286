interface CapitalizeOptions {
  format?: "title" | "sentence";
  ignore?: string[];
  nameList?: string[];
  preserveUppercase?: boolean;
}

/**
 * Capitalizes words in a given string based on the specified formatting options.
 *
 * @param {string} text - The input text to format.
 * @param {CapitalizeOptions} [options] - Configuration options for capitalization.
 * @param {"title" | "sentence"} [options.format="title"] -
 *    The format for capitalization.
 *    - `"title"`: Capitalizes the first letter of each word, ignoring certain words (e.g., "and", "the").
 *    - `"sentence"`: Capitalizes the first word of the sentence and converts the rest to lowercase,
 *      except for names in the `nameList`.
 * @param {string[]} [options.ignore=["and", "or", "the", "of", "in", "on", "at", "by", "with"]] -
 *    A list of words to ignore (kept lowercase) when formatting in `"title"` format.
 * @param {string[]} [options.nameList=[]] - A list of words to treat as proper names.
 *    Names are always capitalized regardless of format.
 * @param {boolean} [options.preserveUppercase=true] -
 *    If `true`, preserves words already in uppercase (e.g., acronyms).
 *
 * @returns {string} The formatted string with capitalization applied.
 *
 * @example
 * // Title case example
 * const titleResult = capitalizeWords("a tale of two cities", {
 *   format: "title",
 * });
 * console.log(titleResult);
 * // Output: "A Tale of Two Cities"
 *
 * @example
 * // Sentence case with names
 * const sentenceResult = capitalizeWords("john doe and mary went to the city of new york.", {
 *   format: "sentence",
 *   nameList: ["john", "doe", "mary", "new york"],
 * });
 * console.log(sentenceResult);
 * // Output: "John doe and mary went to the city of New York."
 */
export function capitalizeWords(
  text: string,
  options: CapitalizeOptions = {}
): string {
  if (!text) return text;

  const {
    format = "title",
    ignore = ["and", "or", "the", "of", "in", "on", "at", "by", "with"],
    nameList = [],
    preserveUppercase = true,
  } = options;

  const isUpperCase = (word: string): boolean => word === word.toUpperCase();

  const capitalizeWord = (word: string): string => {
    const parts = word.split("-");
    const capitalizedParts = parts.map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    );
    return capitalizedParts.join("-");
  };

  const capitalizeWithPunctuation = (word: string): string => {
    const letters = word.replace(/[^a-zA-Z-]/g, "");
    const punctuation = word.slice(letters.length);
    return capitalizeWord(letters) + punctuation;
  };

  const isName = (word: string): boolean =>
    nameList.includes(word.toLowerCase());

  const words = text.split(" ");

  const formattedWords = words.map((word, index) => {
    if (
      ignore.includes(word.toLowerCase()) &&
      index !== 0 &&
      format === "title"
    ) {
      return word.toLowerCase();
    }

    if (preserveUppercase && isUpperCase(word)) {
      return word;
    }

    if (isName(word)) {
      return capitalizeWord(word);
    }

    return capitalizeWithPunctuation(word);
  });

  if (format === "sentence") {
    const firstWord = capitalizeWord(formattedWords[0]);

    const remainingWords = formattedWords.slice(1).map((word, index) => {
      const originalWord = words[index + 1];
      if (isName(originalWord)) {
        return word;
      }
      return word.toLowerCase();
    });

    return `${firstWord} ${remainingWords.join(" ")}`;
  }

  return formattedWords.join(" ");
}
