import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { OrderFromDB } from "../../redux/slices/types/OrderAndSocketState";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { money, useWindowSize } from "../../helpers/generic";
import { updateOrder, XHR_STATE } from "../../helpers/xhr";
import { useTranslation } from "../../hooks/useTranslation";
import { updateXHR } from "../../redux/slices/AppState";
import { useEffect } from "react";

interface OrderWidgetProps {
  orderDetails: OrderFromDB | null;
  status: number;
  onUpdate?: () => void;
}

const orderStatus = ["Pending", "Processing", "Delivered"];

const OrderWidget = ({ orderDetails, status, onUpdate }: OrderWidgetProps) => {
  const user = useSelector((state: RootState) => state.Auth.user);
  const { xhr } = useSelector((state: RootState) => state.App);

  const dispatch = useDispatch();
  const isMobile = useWindowSize().width < 900;

  const tableTranslation = useTranslation("table");
  const processingTranslation = useTranslation("processing");
  const deliveredTranslation = useTranslation("delivered");

  const handleStatusUpdate = (newStatus: number) => {
    updateOrder(dispatch, orderDetails!.id, newStatus);
  };

  useEffect(() => {
    if (xhr === XHR_STATE.SUCCESS && onUpdate) {
      onUpdate();
      dispatch(updateXHR({ xhr: XHR_STATE.NONE }));
    }
  }, [xhr, onUpdate, dispatch]);

  return (
    <Paper
      elevation={isMobile ? 0 : 3}
      sx={{
        minHeight: {
          xs: "50vh",
          lg: "500px",
          maxHeight: "80vh",
          overflowY: "auto",
        },
      }}
    >
      <Box p={3} display="flex" flexDirection="column" alignItems="center">
        {!orderDetails ? (
          <Typography variant="h6">No {orderStatus[status]} orders</Typography>
        ) : (
          <>
            <Box
              sx={{
                width: 100,
                height: 100,
                backgroundColor: "#eee",
                color: "primary.main",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 3,
                textAlign: "center",
              }}
            >
              <Typography variant="h6">
                {tableTranslation} <br /> {orderDetails?.tableNumber}
              </Typography>
            </Box>

            <TableContainer
              sx={{ padding: { xs: "0px 5% 30px", lg: "0px 40px 30px" } }}
            >
              <Table stickyHeader aria-label="sticky table">
                {orderDetails?.order.map((item) => (
                  <TableRow key={item.productId}>
                    <TableCell>
                      {item.names["en"]}
                      {item.count > 1 ? `(${item.count})` : ""}
                    </TableCell>
                    <TableCell align="right">
                      {item.price * item.count}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right">
                    {money(
                      orderDetails!.orderTotal,
                      user.currency.prefix,
                      true
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" gap="30px" mt={3}>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  textTransform: "none",
                  p: "10px 20px",
                  minWidth: "30%",
                  borderRadius: "16px",
                  backgroundColor: "warning.main",
                  "&:hover": {
                    backgroundColor: "warning.light",
                  },
                  cursor:
                    orderDetails!.status === 1 ? "not-allowed" : "pointer",
                }}
                onClick={() => handleStatusUpdate(1)}
                disabled={
                  xhr === XHR_STATE.REQUESTING || orderDetails!.status === 1
                }
              >
                {processingTranslation}
              </Button>

              <Button
                variant="contained"
                color="success"
                sx={{
                  textTransform: "none",
                  p: "10px 20px",
                  minWidth: "30%",
                  borderRadius: "16px",
                  backgroundColor: "success.main",
                  "&:hover": {
                    backgroundColor: "success.light",
                  },
                  cursor:
                    orderDetails!.status === 2 ? "not-allowed" : "pointer",
                }}
                onClick={() => handleStatusUpdate(2)}
                disabled={
                  xhr === XHR_STATE.REQUESTING || orderDetails!.status === 2
                }
              >
                {deliveredTranslation}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default OrderWidget;
