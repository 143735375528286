import React from "react";
import { Card, CardContent, CardHeader, CardActions } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CustomCardProps {
  header?: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
}

export const CustomCard = styled(Card)(({ theme }) => ({
  transition: "box-shadow 0.3s ease-in-out",
  borderRadius: "16px",
  minWidth: "235px",
  maxWidth: "235px",
  width: "100%",
  minHeight: "185px",
  "& .MuiCardContent-root": {
    color: "#666",
  },
  "&:hover": {
    boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
    "& .MuiCardHeader-root, & .MuiCardContent-root": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      borderColor: "#fff",
      color: "#fff",
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
}));

export const SettingCard: React.FC<CustomCardProps> = ({
  header,
  content,
  footer,
}) => {
  return (
    <CustomCard>
      <CardHeader title={header} />
      <CardContent
        sx={{ padding: "10px 16px", display: "flex", justifyContent: "center" }}
      >
        {content}
      </CardContent>
      <CardActions>{footer}</CardActions>
    </CustomCard>
  );
};
