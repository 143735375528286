import React from "react";
import {
  Category,
  ProductAddSteps,
  ProductFormText,
} from "../../../types/common.types";
import { ProductType } from "../../product";

//========================= Types ================================

export interface AddProductGeneric {
  /** Navigates stepper component to next step
   * @param justCompleted provides context to handler that a step has just been completed. This is helpful when
   * distinguishing between page reload on a completed step and activating step completion by fulfilling a step's requirement.
   * */
  handleNext: (justCompleted?: boolean) => void;
}

export type StateGenerics = {
  setProductSelect: React.Dispatch<React.SetStateAction<ProductType | null>>;
  selectedProduct: ProductType | null;
  setSelectedCategory: React.Dispatch<React.SetStateAction<Category>>;
  selectedCategory: Category;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
};
export interface StepsInterface {
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  steps: Step[];
}
export interface ProductForm {
  setCreatedProductNames: React.Dispatch<React.SetStateAction<ProductFormText>>;
  setFormSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface FeedBack {
  isProductCreateSuccessful: boolean;
  productNames: ProductFormText;
}
export interface Step {
  stepId: number;
  label: string;
  component?: JSX.Element;
  completed: boolean;
}
type GetStepsProps = StateGenerics & ProductForm & FeedBack;
//====================== METHODS ========================//
export function updateURLSearchParams(
  param: string,
  value: ProductType | string
): void {
  // @ts-ignore
  const url = new URL(window.location);
  if (param === "product-type") {
    //erase category param if product type has changed
    url.search = "";
  }
  const params = new URLSearchParams(url.search);
  if (value === null || value === "") {
    params.delete(param);
  } else {
    if (typeof value === "number") {
      value = ProductType[value] as string;
    }
    params.set(param, value);
  }

  window.history.replaceState(null, "", `${url.pathname}?${params.toString()}`);
}

/**
 * Defines the steps a user must complete in order to create a new product.
 *
 * @var completed: When true, enables the Next button click.
 *
 * @returns Step[]
 * */
export function getSteps(props: GetStepsProps): Step[] {
  return [
    {
      stepId: ProductAddSteps.SelectProduct,
      label: "Select Product Type",
      completed: false,
    },
    {
      stepId: ProductAddSteps.SelectCategory,
      label: `Select ${props.selectedProduct || ""} Category`,
      completed: false,
    },
    {
      stepId: ProductAddSteps.FillForm,
      label: `Create ${props.selectedCategory.name} Product`,
      completed: false,
    },
    {
      stepId: ProductAddSteps.Feedback,
      label: `Product Created`,
      completed: true,
    },
  ];
}

/**
 * Used to determine whether to show the skip button or not.
 * */
export const isStepOptional = (stepId: number) => {
  return false; //[ProductAddSteps.SelectCategory].includes(step);
};
