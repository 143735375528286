import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { logout } from "../auth/helper";
import LogoutIcon from "@mui/icons-material/Logout";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { DrawerHeader, drawerWidth } from "../AppBarWithDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import { useDispatch } from "react-redux";
import { Fragment } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import PeopleIcon from "@mui/icons-material/People";
import { get } from "lockr";
import { Box, Typography } from "@mui/material";

interface MenuItem {
  title: string;
  icon: JSX.Element;
  route: string;
  requiredPermissions?: string[];
}

const menuList: MenuItem[] = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    route: "dashboard",
  },
  {
    title: "New Product",
    icon: <AddIcon />,
    route: "add-product",
    requiredPermissions: ["product_write"],
  },
  {
    title: "Drinks",
    icon: <LocalBarIcon />,
    route: "drinks",
  },
  {
    title: "Food",
    icon: <RestaurantIcon />,
    route: "food",
  },
  {
    title: "Order",
    icon: <FormatListNumberedRtlIcon />,
    route: "order",
    requiredPermissions: ["order_access"],
  },
  {
    title: "Staff Management",
    icon: <PeopleIcon />,
    route: "staff-management",
    requiredPermissions: [""],
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    route: "settings",
  },
];

const getStaffMenuList = (permissions: string[]): MenuItem[] => {
  return menuList.filter(
    (menu) =>
      !menu.requiredPermissions ||
      menu.requiredPermissions.some((perm) => permissions.includes(perm))
  );
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  open: boolean;
  handleDrawerClose: () => void;
}

export const SideDrawer = (props: Props) => {
  const logo = require("../../assets/logo-crop.png");
  const { open, handleDrawerClose } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const staffPermissions = get("staff-permissions") as string[] | undefined;

  const staffMenuList = staffPermissions
    ? getStaffMenuList(staffPermissions)
    : menuList;

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            src={logo}
            alt="Menutender Logo"
            sx={{
              width: 50,
            }}
          />
          <Typography variant="h4" sx={{ fontSize: "14px", ml: 1 }}>
            MENUTENDER
          </Typography>
        </Box>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ padding: "0px" }}>
        {staffMenuList.map((menu) => (
          <Fragment key={menu.route}>
            <ListItem
              key={menu.route}
              disablePadding
              sx={{
                display: "block",
                color: location.pathname.includes(menu.route)
                  ? "white"
                  : "inherit",
                backgroundColor: location.pathname.includes(menu.route)
                  ? theme.palette.primary.main
                  : "inherit",
                "&:hover": {
                  backgroundColor: location.pathname.includes(menu.route)
                    ? theme.palette.primary.light
                    : theme.palette.action.hover,
                },
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  p: 2.5,
                }}
                onClick={() => navigate(menu.route)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: location.pathname.includes(menu.route)
                      ? "white"
                      : "inherit",
                  }}
                >
                  {menu.icon}
                </ListItemIcon>
                <ListItemText
                  primary={menu.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <Divider component="li" />
          </Fragment>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => logout(dispatch)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};
