import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Price from "./Price";
import Name from "./Name";
import Description from "./Description";
import Category from "./Category";
import { get } from "lockr";
import {
  Currency,
  Language,
  MediaSource,
  NewProduct,
  ProductFormPrice,
  ProductFormText,
  ProductInterface,
  Segment,
  User,
} from "../../types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updateFeedback } from "../../redux/slices/AppState";
import { FEEDBACK_VARIANT } from "../extras/Alert";
import { productFormSubmit } from "./formUtils";
import { ProductType } from "../product";
import { useTranslation } from "../../hooks/useTranslation";
import Photo from "./Photo";

export interface FormFields {
  names: ProductFormText;
  descriptions: ProductFormText;
  category: string;
  price: string;
  prices: ProductFormPrice;
  inStock: boolean;
}
export interface FormStateProps {
  formFields: FormFields;
  setFields: React.Dispatch<React.SetStateAction<FormFields>>;
}

interface FormInterface {
  activeProduct: ProductInterface;
  productType: ProductType;
}

const ModalProductUpdate = (props: FormInterface) => {
  const userData = get("user") as User | undefined;
  const productState = useSelector((state: RootState) => state.Product);
  const dispatch = useDispatch();

  const { activeProduct, productType } = props;
  const isDrinkProduct = productType === ProductType.Drink;

  const submitTranslation = useTranslation("submit");

  let languages: Language[] = [];
  let currency: Currency = { name: "", prefix: "" };
  let segments: Segment[] = [];
  if (userData) {
    segments = userData.segments;
    languages = userData.languages;
    currency = userData.currency;
  }

  const [fields, setFields] = useState<FormFields>({
    names: {},
    descriptions: {},
    category: "",
    price: "",
    prices: {},
    inStock: true,
  });

  useEffect(() => {
    setFields({
      inStock: activeProduct.inStock,
      names: activeProduct.names,
      price: String(activeProduct.price),
      prices: activeProduct.prices,
      descriptions: activeProduct.descriptions,
      category: activeProduct.categoryId,
    });
    //eslint-disable-next-line
  }, []);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { selectedFileInBase64, selectedMedia } = productState;

    if (!fields.category) {
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message: `Please add a category`,
        })
      );
      return;
    }

    let newPhoto = null;
    let newMediaSource = MediaSource.Upload;
    if (selectedFileInBase64 && typeof selectedFileInBase64 === "string") {
      newPhoto = selectedFileInBase64;
    } else if (selectedMedia.photo) {
      newPhoto = selectedMedia.id;
      newMediaSource = MediaSource.MediaPicker;
    }
    if (selectedFileInBase64) newPhoto = String(selectedFileInBase64);
    else if (selectedMedia.id) newPhoto = selectedMedia.id;

    const product: NewProduct = {
      descriptions: fields.descriptions,
      inStock: fields.inStock,
      names: fields.names,
      ...(userData?.hasSegments ? { prices: fields.prices } : {}),
      categoryId: fields.category,
      photo: newPhoto,
      productPhoto: {
        ...activeProduct.productPhoto,
        mediaSource: activeProduct.mediaSource,
      },
      id: activeProduct.id,
      mediaSource: newMediaSource,
    };

    await productFormSubmit(product, dispatch, "Update", isDrinkProduct);
  };

  return (
    <Form onSubmit={submitHandler}>
      <Photo isMobile={false} activeProduct={activeProduct} />
      <hr />
      <Name languages={languages} formFields={fields} setFields={setFields} />
      <hr />
      <Price
        currency={currency}
        segments={segments}
        formFields={fields}
        setFields={setFields}
      />
      <hr />
      <Category
        formFields={fields}
        setFields={setFields}
        productType={productType}
      />
      <hr />
      <Description
        languages={languages}
        formFields={fields}
        setFields={setFields}
      />

      <Button variant="primary" type="submit">
        {submitTranslation}
      </Button>
    </Form>
  );
};

export default ModalProductUpdate;
