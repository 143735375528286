import { Box, Button, Typography } from "@mui/material";

interface EmptyStateProps {
  message: string;
  description: string;
  actionText: string;
  addStaff: () => void;
}

const EmptyStaff = ({
  message,
  description,
  actionText,
  addStaff,
}: EmptyStateProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {message}
      </Typography>
      <Typography variant="body1" mb="20px">
        {description}
      </Typography>

      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          p: "10px 20px",
          borderRadius: "8px",
          minWidth: { xs: "125px", md: "159px" },
          maxWidth: "max-content",
          color: "#fff",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.light",
            color: "#fff",
          },
        }}
        onClick={addStaff}
      >
        {actionText}
      </Button>
    </Box>
  );
};

export default EmptyStaff;
