import axios, { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setDrinks,
  setFood,
  getCategories,
} from "../redux/slices/ProductState";
import {
  BusinessInfo,
  Category,
  Currency,
  Language,
  MembershipTier,
  PasswordUpdateData,
  ProductFormData,
  ProductInterface,
  ProductResponse,
  Staff,
  StaffFormData,
} from "../types/common.types";
import { OrderFromDB } from "../redux/slices/types/OrderAndSocketState";
import { setOrders, updateOrderStatus } from "../redux/slices/OrderSocketState";
import {
  addStaffToState,
  removeStaff,
  setStaffs,
  updateStaffs,
} from "../redux/slices/StaffState";
import { ProductType } from "../components/product";
import { get } from "lockr";
import { getStaffPermission } from "./xhrHelper";
import { setSelectedProduct, updateXHR } from "../redux/slices/AppState";

export enum XHR_STATE {
  NONE,
  REQUESTING,
  FAILED,
  SUCCESS,
}

const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
const salt = process.env.REACT_APP_BASIC_AUTH_SALT;

const credentials = window.btoa(`${username}:${password}`);

export const xhrHeaders = {
  ContentType: "application/json",
  Authorization: `Basic ${credentials}${salt}`,
};
export const getProdBaseURL = () => {
  let url = null;
  const baseSite = window.location.origin;
  if (baseSite === process.env.REACT_APP_CLIENT_ORIGIN_TESTING) {
    url = process.env.REACT_APP_API_URL_PROD_TESTING;
  }
  if (baseSite === process.env.REACT_APP_CLIENT_ORIGIN_PROD) {
    url = process.env.REACT_APP_API_URL_PROD;
  }

  return url || "";
};
const environment = process.env.NODE_ENV;
export let baseURL = process.env.REACT_APP_API_URL_DEV;
if (environment !== "development" && getProdBaseURL()) {
  baseURL = getProdBaseURL();
}

export const xhr = axios.create({
  withCredentials: true,
  baseURL,
  headers: xhrHeaders,
});

xhr.interceptors.request.use(
  (config) => {
    const token = get("token");
    config.headers = {
      ...config.headers,
      "X-Bearer-Token": token ? `Bearer ${token}` : "",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const pathResolver = (path: string, params: string[]) => {
  let urlPath = path;
  let delimiter = "?";
  if (path && params.length) {
    params.forEach((param) => {
      if (urlPath.includes("?")) delimiter = "&";
      urlPath = `${urlPath}${delimiter}${param}`;
    });
  }

  return urlPath;
};

export const getAllCategories = (dispatch: Dispatch<any>) => {
  return xhr.get("/categories/allCategories").then((response) => {
    const drinkCategories: Category[] = response.data.drinkCategories;
    const foodCategories: Category[] = response.data.foodCategories;
    dispatch(
      getCategories({ categories: drinkCategories, productType: "Drink" })
    );
    dispatch(
      getCategories({ categories: foodCategories, productType: "Food" })
    );
    return null;
  });
};
// ==================== ALL CATEGORIES =======================

export const getDrinkCategories = async (
  dispatch: Dispatch<any>,
  fetchDrinks = false
) => {
  const path = pathResolver("drink_category", [`withDrinks=${fetchDrinks}`]);
  return xhr
    .get(path)
    .then((response) => {
      const categories = response.data;
      dispatch(getCategories({ categories, productType: "Drink" }));
      return categories;
    })
    .catch((err) => console.log("Axios Error: getDrinkCategories"));
};
export const addProductCategory = async (
  name: string,
  itemType: ProductType
) => {
  const productEndpoint =
    itemType === ProductType.Drink ? "drink_category" : "food_category";
  return await xhr.post(productEndpoint, { name }, { headers: xhrHeaders });
};
export const InsertDrink = async (
  formData: ProductFormData
): Promise<AxiosResponse> => {
  return await xhr.post(`/drink/`, formData, {
    headers: xhrHeaders,
  });
};

export const updateProductCategory = async (
  id: string,
  name: string,
  itemType: ProductType.Drink | ProductType.Food
) => {
  const productEndpoint =
    itemType === ProductType.Drink
      ? `drink_category/${id}`
      : `food_category/${id}`;
  return await xhr.put(productEndpoint, { name }, { headers: xhrHeaders });
};

export const deleteProductCategory = async (
  id: string,
  itemType: ProductType.Drink | ProductType.Food
) => {
  const productEndpoint =
    itemType === ProductType.Drink
      ? `drink_category/${id}`
      : `food_category/${id}`;
  return await xhr.delete(productEndpoint, { headers: xhrHeaders });
};

export const UpdateDrink = async (
  formData: ProductFormData,
  drinkId: string = ""
): Promise<AxiosResponse> => {
  const path = pathResolver(`/drink/${drinkId}`, []);
  return await xhr.put(path, formData);
};
export const getDrinks = (
  trigger: "Loader" | "Helper",
  dispatch: Dispatch<any>,
  categoriesExist?: boolean
): void | Promise<ProductInterface[]> => {
  if (trigger === "Loader") {
    return Promise.resolve().then(() => {
      const path = pathResolver("drink/drinks", []);
      return xhr
        .get(path)
        .then((response) => {
          dispatch(setDrinks(response.data));
          return response.data;
        })
        .then(async (drinks) => {
          if (!categoriesExist) {
            return getDrinkCategories(dispatch, true).then(() => drinks);
          } else {
            return drinks;
          }
        });
    });
  } else {
    xhr.get("drink/drinks", { headers: xhrHeaders }).then((response) => {
      dispatch && dispatch(setDrinks(response.data));
    });
  }
};
export const getDrink = async (
  drinkId: string | undefined,
  dispatch: Dispatch<any>
) => {
  if (!drinkId) return false;
  return await xhr
    .get(`drink/${drinkId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
};
export const deleteDrink = async (drink: ProductInterface) => {
  return await xhr.delete(`drink/${drink.id}`, {
    headers: xhrHeaders,
    data: { photo: drink.productPhoto },
  });
};

// ==================== FOOD =======================

export const getFoodProducts = (
  trigger: "Loader" | "Helper",
  dispatch: Dispatch<any>,
  categoriesExist?: boolean
): void | Promise<any> => {
  if (trigger === "Loader") {
    return xhr
      .get("food/food")
      .then((response: AxiosResponse<ProductResponse>) => {
        const foodProducts = response.data.products;
        if (foodProducts) dispatch(setFood(foodProducts));
        return foodProducts;
      })
      .then(async (food) => {
        if (!categoriesExist) {
          return getFoodCategories(dispatch, true).then(() => food);
        }

        return food;
      });
  } else {
    xhr.get("food/food", { headers: xhrHeaders }).then((response) => {
      dispatch && dispatch(setFood(response.data.products));
    });
  }
};

export const getFood = async (
  foodId: string | undefined,
  dispatch: Dispatch<any>
) => {
  if (!foodId) return false;
  return await xhr
    .get(`food/${foodId}`)
    .then((response) => {
      const { product } = response.data;
      dispatch(setSelectedProduct(product));
      return response.data;
    })
    .catch((error) => console.log(error));
};

export const getFoodCategories = async (
  dispatch: Dispatch<any>,
  fetchFood = false
) => {
  try {
    const path = pathResolver("food_category", [`withFood=${fetchFood}`]);
    const response = await xhr.get(path);
    const categories = response.data.foodCategory;
    dispatch(getCategories({ categories, productType: "Food" }));
  } catch (err) {
    return console.log("Axios Error: getDrinkCategories");
  }
};

export const InsertFood = async (
  formData: ProductFormData
): Promise<AxiosResponse> => {
  return await xhr.post(`/food/`, formData, {
    headers: xhrHeaders,
  });
};
export const UpdateFood = async (
  formData: ProductFormData,
  drinkId: string = ""
): Promise<AxiosResponse> => {
  return await xhr.put(`/food/${drinkId}`, formData, {
    headers: xhrHeaders,
  });
};
export const deleteFood = async (food: ProductInterface) => {
  return await xhr.delete(`food/${food.id}`, {
    headers: xhrHeaders,
    data: { photo: food.productPhoto },
  });
};

// ==================== SETTINGS =======================

export const updateBusinessInfo = async (data: BusinessInfo) => {
  return await xhr.patch(
    "user/business-info",
    { ...data },
    { headers: xhrHeaders }
  );
};

export const addLanguage = async (languages: Language[]) => {
  return await xhr.post(
    `user/language`,
    { languages },
    { headers: xhrHeaders }
  );
};

export const updateDefaultLanguage = async (iso: string) => {
  return await xhr.post(
    `user/language/default`,
    { iso },
    { headers: xhrHeaders }
  );
};

export const updateCurrency = async (currency: Currency) => {
  return await xhr.post(`user/currency`, { currency }, { headers: xhrHeaders });
};

export const changePassword = async (data: PasswordUpdateData) => {
  return await xhr.put(
    `auth/user/password`,
    { ...data },
    { headers: xhrHeaders }
  );
};

// ==================== ORDER =======================
type AllOrdersResponse = {
  success: boolean;
  orders: OrderFromDB[];
};

export const getOrders = (dispatch: Dispatch<any>): void => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("order_access")) {
    throw new Error("Access denied!");
  }
  xhr
    .get("order/", { headers: xhrHeaders })
    .then((response: AxiosResponse<AllOrdersResponse>) => {
      if (response.data.success) {
        dispatch(setOrders(response.data.orders));
      }
    });
};

export const updateOrder = (
  dispatch: Dispatch<any>,
  id: string,
  status: number
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("order_access")) {
    throw new Error("Access denied!");
  }

  dispatch(updateXHR({ xhr: XHR_STATE.REQUESTING }));

  xhr
    .patch(`order/${id}`, { status }, { headers: xhrHeaders })
    .then((response) => {
      if (response.data.success) {
        dispatch(updateOrderStatus(response.data.order));
        dispatch(updateXHR({ xhr: XHR_STATE.SUCCESS }));
      }
    });
};

// ==================== DASHBOARD =======================

export const addSegment = async (
  clientUsername: string,
  segmentName: string
) => {
  return await xhr.post(
    `/user/add-segment`,
    { clientUsername, segmentName },
    { headers: xhrHeaders }
  );
};

// ==================== STAFF MANAGEMENT =======================
export const getStaffs = (dispatch: Dispatch<any>) => {
  return xhr.get("/user/staff").then((response) => {
    if (response.data.success) {
      dispatch(setStaffs(response.data.staffs));
    }
    return null;
  });
};

export const addStaff = async (
  data: StaffFormData,
  dispatch: Dispatch<any>
) => {
  const response = await xhr.post(
    "user/staff",
    { ...data },
    { headers: xhrHeaders }
  );
  if (response.data.success) {
    const rawStaff = response.data.staff;
    const newStaff: Staff = {
      name: rawStaff.name,
      role: rawStaff.role,
      staffId: rawStaff.staffId,
      permissions: data.permissions,
    };
    dispatch(addStaffToState(newStaff));
  }
  return response.data;
};

export const updateStaff = async (data: Staff, dispatch: Dispatch<any>) => {
  const response = await xhr.put(
    `user/staff/${data.staffId}`,
    { ...data },
    { headers: xhrHeaders }
  );
  if (response.data.success) {
    const rawStaff = response.data.staff;
    const updatedStaff: Staff = {
      name: rawStaff.name,
      role: rawStaff.role,
      staffId: rawStaff.staffId,
      permissions: data.permissions,
    };
    dispatch(updateStaffs(updatedStaff));
  }
  return response.data;
};

export const deleteStaff = async (id: number, dispatch: Dispatch<any>) => {
  const response = await xhr.delete(`user/staff/${id}`, {
    headers: xhrHeaders,
  });
  if (response.data.success) {
    dispatch(removeStaff(id));
  }
  return response.data;
};

//======================================== Media =====================================
export const getMedia = async (query: string = "") =>
  await xhr.get(`media/product-photos${query}`, {
    headers: xhrHeaders,
  });

// TIER UPGRADE //
export const upgradeMembership = async (upgradeType: MembershipTier) => {
  return await xhr.post(
    "payment/initialize",
    { upgradeType },
    { headers: xhrHeaders }
  );
};

export const verifyPayment = async (reference: string) => {
  return await xhr.post(
    "payment/verify",
    { reference },
    { headers: xhrHeaders }
  );
};
