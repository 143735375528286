import React, { useState } from "react";
import { get } from "lockr";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import { FlexContainer } from "../styled/Generic";
import { Box, Typography } from "@mui/material";
import MobileBarcode from "./Barcode/MobileBarcode";
import { ProductInterface } from "../../types/common.types";
import MobilePlanDetails from "./Barcode/MobilePlanDetails";
import ProductDetails from "./Barcode/ProductDetails";
import { useTranslation } from "../../hooks/useTranslation";

export const LinksWrapper = styled.div`
  a {
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease, transform 0.3s ease;
    color: #6e151e;
    font-weight: 500;

    &:hover {
      transform: translateY(-1px);
      color: #5e0808a9;
    }
  }
`;

export const MobileOverview = () => {
  const foodIcon = require("../../assets/plate.png");
  const drinkIcon = require("../../assets/cheers.png");
  const staffPermissions = get("staff-permissions");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const user = useSelector((state: RootState) => state.Auth.user);
  const { staffs } = useSelector((state: RootState) => state.Staff);
  const orders = useSelector((state: RootState) => state.Order.orders);
  const product = useSelector((state: RootState) => state.Product);

  const greet = useTranslation("dashboard_greeting");
  const categoriesLabel = useTranslation("categories_label");
  const productsLabel = useTranslation("products_label");
  const inStockLabel = useTranslation("in_stock_label");
  const ordersLabel = useTranslation("orders_label");

  const handleUpgradeClick = () => setShowUpgradeModal(true);

  const calculatePercentage = (
    inStockProducts: number,
    totalProducts: number
  ): string => {
    if (totalProducts === 0) return "0%";
    const percentage = Math.round((inStockProducts / totalProducts) * 100);
    return `${percentage}%`;
  };

  return (
    <FlexContainer
      direction="column"
      width="100%"
      height="100%"
      aria-label="aria-dashboard-page"
      //   padding="20px 0px"
    >
      {(!staffPermissions ||
        staffPermissions.includes("can_generate_barcode")) && (
        <div>
          <Typography variant="h4" pb="30px">
            {greet}, {user.legalName}
          </Typography>

          <MobileBarcode />

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "30px",
              mb: "30px",
              width: "100%",
            }}
          >
            <ProductDetails
              icon={foodIcon}
              details={[
                {
                  label: categoriesLabel,
                  value: product.foodCategories.length,
                },
                { label: productsLabel, value: product.food.length },
                {
                  label: inStockLabel,
                  value: calculatePercentage(
                    product.food.filter(
                      (item: ProductInterface) => item.inStock
                    ).length,
                    product.food.length
                  ),
                },
                { label: ordersLabel, value: orders.length },
              ]}
            />
            <ProductDetails
              icon={drinkIcon}
              details={[
                {
                  label: categoriesLabel,
                  value: product.drinkCategories.length,
                },
                { label: productsLabel, value: product.drinks.length },
                {
                  label: inStockLabel,
                  value: calculatePercentage(
                    product.drinks.filter(
                      (item: ProductInterface) => item.inStock
                    ).length,
                    product.drinks.length
                  ),
                },
                { label: ordersLabel, value: orders.length },
              ]}
            />
          </Box>

          <FlexContainer direction="column" flexWrap="nowrap">
            <MobilePlanDetails
              user={user}
              staffs={staffs}
              product={product}
              handleUpgradeClick={handleUpgradeClick}
              showUpgradeModal={showUpgradeModal}
              setShowUpgradeModal={setShowUpgradeModal}
            />
          </FlexContainer>
        </div>
      )}
    </FlexContainer>
  );
};
