import React, { ChangeEvent, useEffect } from "react";
import Box from "@mui/material/Box";
import { StyledLabel } from "../../styled/Generic";
import { updateFeedback } from "../../../redux/slices/AppState";
import { FEEDBACK_VARIANT } from "../../extras/Alert";
import { FEEDBACK_ALERTS } from "../../../types/common.types";
import { Dispatch } from "@reduxjs/toolkit";
import { setFileSelection } from "../../../redux/slices/ProductState";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "../../../hooks/useTranslation";

const SelfUpload = () => {
  const { selectedFilePreview } = useSelector(
    (state: RootState) => state.Product
  );

  const dispatch = useDispatch();

  const uploadYourPhoto = useTranslation("upload_photo_title");

  useEffect(() => {
    if (selectedFilePreview) {
      setTimeout(() => {
        URL.revokeObjectURL(selectedFilePreview);
      }, 2000);
    }
  }, [selectedFilePreview]);

  const readPhotoToBase64 = (
    file: File,
    dispatch: Dispatch,
    preview: string
  ) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      dispatch(
        setFileSelection({ file, fileInBase64: fileReader.result, preview })
      );
    };
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    const pattern = /\.(jpe?g|png|svg)$/i;
    const selectedFile = file && file[0];
    if (selectedFile && pattern.test(selectedFile.name)) {
      const fileSizeInMb = (selectedFile.size / 1000000).toFixed(2);
      if (Number(fileSizeInMb) > 0.5) {
        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.ERROR,
            message: FEEDBACK_ALERTS.LARGE_FILE_SIZE,
            timeout: 4000,
          })
        );
        return;
      }
      readPhotoToBase64(
        selectedFile,
        dispatch,
        URL.createObjectURL(selectedFile)
      );
    } else {
      dispatch(
        setFileSelection({ file: null, fileInBase64: null, preview: "" })
      );
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message: FEEDBACK_ALERTS.INVALID_FILE,
          timeout: 10000,
        })
      );
      return;
    }
  };

  return (
    <Box
      id="self-upload"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "20px 0",
      }}
    >
      <StyledLabel htmlFor="drinkImage">{uploadYourPhoto}</StyledLabel>

      <input
        type="file"
        id="drinkImage"
        hidden={true}
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default SelfUpload;
