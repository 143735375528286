import { Button } from "@mui/material";
import React, { useState } from "react";
import PaymentCallback from "./PaymentCallback";
import { get } from "lockr";
import { useTranslation } from "../../hooks/useTranslation";

interface PaystackFrameProps {
  iframeUrl: string;
  onClose: () => void;
}

export default function PaystackFrame({
  iframeUrl,
  onClose,
}: PaystackFrameProps) {
  const [paymentCallback, showPaymentCallback] = useState(false);
  const [pRef, setPRef] = useState<string | null>(null);

  const closeWindowBtnText = useTranslation("close_window_btn_text");

  const handleClose = () => {
    const reference = get("pRef");
    setPRef(reference);
    showPaymentCallback(true);
    onClose();
  };
  return (
    <>
      <iframe
        src={iframeUrl}
        width="100%"
        height="120%"
        style={{
          border: "none",
        }}
        title="Paystack Payment"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      />
      <Button
        onClick={handleClose}
        variant="contained"
        color="primary"
        sx={{
          position: "absolute",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 10,
        }}
      >
        {closeWindowBtnText}
      </Button>
      {paymentCallback && <PaymentCallback paymentRef={pRef} />}
    </>
  );
}
