import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { capitalizeFirstLetter } from "../../../../helpers/generic";
import Button from "@mui/material/Button";
import { AddCategory } from "../../../../helpers/xhrHelper";
import { useDispatch } from "react-redux";
import { ProductType } from "../../../product";
import { useTranslation } from "../../../../hooks/useTranslation";

type Props = {
  selectedProduct: ProductType;
  setCategoryCTA: React.Dispatch<
    React.SetStateAction<"New Category" | "Abort">
  >;
};

const CreateCategory = (props: Props) => {
  const [newCategory, setNewCategory] = useState<string>("");
  const dispatch = useDispatch();

  const createANewText = useTranslation("create_a_new_text");
  const categoryText = useTranslation("category_text");
  const createText = useTranslation("create_text");

  const handleAddCategory = () => {
    if (newCategory) {
      AddCategory(dispatch, newCategory, props.selectedProduct).then((r) => {
        props.setCategoryCTA("New Category"); // deactivates addCategoryMode in SelectProductCategory.tsx
        // todo: toast action complete feedback
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "50vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        py: 5,
      }}
    >
      <Typography variant="h6">
        {createANewText} {ProductType[props.selectedProduct]} {categoryText}
      </Typography>
      <TextField
        label="New Category"
        value={newCategory}
        onChange={(e) => setNewCategory(capitalizeFirstLetter(e.target.value))}
        sx={{
          marginBottom: 2,
          width: "100%",
          maxWidth: 500,
          borderRadius: "5px",
        }}
      />

      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          p: "10px 20px",
          borderRadius: "8px",
          minWidth: { xs: "125px", md: "159px" },
          maxWidth: "max-content",
          color: "#fff",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.light",
            color: "#fff",
          },
        }}
        onClick={handleAddCategory}
      >
        {createText}
      </Button>
    </Box>
  );
};

export default CreateCategory;
