import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { Language, ProductFormText } from "../../types/common.types";
import { FormStateProps } from "./ModalProductUpdate";
import {
  Box,
  Chip,
  Divider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../hooks/useTranslation";

interface Props extends FormStateProps {
  languages: Language[];
}

const Name = (props: Props) => {
  const { languages, setFields, formFields } = props;
  const { names } = formFields;

  const productDescriptionIn = useTranslation("product_description_label");
  const productIsInStock = useTranslation("product_is_in_stock");
  const notInStock = useTranslation("not_in_stock");
  const productWillNotBeVisibleToCustomers = useTranslation(
    "product_will_not_be_visible_to_customers"
  );
  const visibilityTranslation = useTranslation("visibility");

  useEffect(() => {
    const initialLanguages: ProductFormText = {};
    languages.forEach((language) => (initialLanguages[language.iso] = ""));
    setFields({ ...formFields, names: initialLanguages });
    // eslint-disable-next-line
  }, []);

  const onNameChange = (lang: string, value: string) => {
    const productNames = { ...names, [lang]: value };
    setFields({ ...formFields, names: productNames });
  };

  return (
    <>
      <Row className="mb-3">
        {languages.length &&
          languages.map((language, index) => {
            return (
              <Box key={`${language.iso}-${index}`}>
                <TextField
                  id="standard-basic"
                  label={`${productDescriptionIn} ${language.name}`}
                  variant="standard"
                  value={names[language.iso] || ""}
                  onChange={(e) => onNameChange(language.iso, e.target.value)}
                  sx={{ width: "100%" }}
                  required
                />
              </Box>
            );
          })}
      </Row>
      <Divider>
        <Chip label={visibilityTranslation} size="small" />
      </Divider>
      <Row className="mb-3">
        <Box>
          <Typography>
            {formFields.inStock ? (
              <Typography component="span">
                <small>{productIsInStock}</small>
              </Typography>
            ) : (
              <Typography component="span">
                <small>{notInStock}</small>{" "}
                <small>
                  <b>({productWillNotBeVisibleToCustomers})</b>
                </small>
              </Typography>
            )}
          </Typography>
          <Box>
            <Switch
              checked={formFields.inStock}
              onChange={() =>
                setFields({ ...formFields, inStock: !formFields.inStock })
              }
            />
          </Box>
        </Box>
      </Row>
    </>
  );
};

export default Name;
