import * as Drinks from "../styled/Product";
import React, { Fragment } from "react";
import { ModalSubComponent, User } from "../../types/common.types";
import { useDispatch } from "react-redux";
import { MODAL } from "./helpers/constants";
import WriteProduct from "./components/WriteProduct";
import { DeleteDrink, DeleteFood } from "../../helpers/xhrHelper";
import Product, { ProductType } from "../product";
import { setModalState } from "../../redux/slices/AppState";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { get } from "lockr";
import CustomButton from "../common/CustomButton";
import { Box } from "@mui/material";
import { useTranslation } from "../../hooks/useTranslation";

interface TabInterface extends ModalSubComponent {}
const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProductTab = (props: TabInterface): JSX.Element => {
  const staffPermissions = get("staff-permissions") as string[] | undefined;

  const dispatch = useDispatch();

  const cancelTranslation = useTranslation("cancel_translation");
  const editTranslation = useTranslation("edit_btn_text");
  const deleteTranslation = useTranslation("delete");

  const { activeProduct, modalMode, productType } = props;
  const editMode = modalMode === MODAL.EDIT;
  const EditOrCancelButton = editMode
    ? `${cancelTranslation}`
    : `${editTranslation}`;
  const navigate = useNavigate();
  let defaultLanguage = "en";
  const userData = get("user") as User | undefined;
  if (userData) defaultLanguage = userData.defaultLanguage;

  const productDeleteHandler = () => {
    if (productType === ProductType.Drink)
      DeleteDrink(activeProduct, dispatch, navigate);
    if (productType === ProductType.Food)
      DeleteFood(activeProduct, dispatch, navigate);
  };

  return (
    <Fragment>
      {(!staffPermissions || staffPermissions.includes("product_write")) && (
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: 1,
            my: 2,
          }}
        >
          <CustomButton
            sx={{ maxWidth: "100px" }}
            onClick={() =>
              dispatch(
                setModalState(
                  modalMode === MODAL.EDIT ? MODAL.VIEW : MODAL.EDIT
                )
              )
            }
          >
            {EditOrCancelButton}
          </CustomButton>
          <CustomButton
            sx={{ maxWidth: "100px" }}
            background="#9e0505"
            onClick={() => productDeleteHandler()}
          >
            {deleteTranslation}
          </CustomButton>
        </Box>
      )}
      {modalMode === MODAL.VIEW ? (
        <ProductWrapper>
          <Product
            product={activeProduct}
            productType={ProductType.Drink}
            parent="Modal"
          />
        </ProductWrapper>
      ) : (
        <WriteProduct
          modalMode={modalMode}
          product={activeProduct}
          productType={props.productType || 0}
        />
      )}
      {!editMode && (
        <Box
          component="div"
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
            px: 4,
          }}
        >
          <Drinks.Description>
            {activeProduct.descriptions[defaultLanguage]}
          </Drinks.Description>
        </Box>
      )}
    </Fragment>
  );
};

export default ProductTab;
