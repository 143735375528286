import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ProductInterface } from "../types/common.types";
import { useSelector } from "react-redux";
import Product, { ProductType } from "../components/product";
import { RootState } from "../redux/store";
import YouHaveNoProduct from "../components/styled/YouHaveNoProduct";
import { FlexContainer } from "../components/styled/Generic";
import DesktopFilter from "../components/filter/DesktopFilter";
import { useWindowSize } from "../helpers/generic";
import MobileFilter from "../components/filter/MobileFilter";

const Food = () => {
  const { food } = useSelector((state: RootState) => state.Product);
  const [foodProducts, setFoodProducts] = useState<ProductInterface[]>([]);
  const isMobile = useWindowSize().width < 900;
  const [inStockFilterChecked, setInStockFilterChecked] = useState(true);

  useEffect(() => {
    setFoodProducts(food);
  }, [food]);

  const FoodList = () => (
    <>
      {foodProducts.map((food, index) => (
        <Product
          key={food.id}
          product={food}
          productType={ProductType.Drink}
          parent="Page"
        />
      ))}
    </>
  );

  return (
    <>
      {!isMobile ? (
        <DesktopFilter
          productType={ProductType.Food}
          setProductList={setFoodProducts}
          inStockFilterChecked={inStockFilterChecked}
          setInStockFilterChecked={setInStockFilterChecked}
        />
      ) : (
        <MobileFilter
          productType={ProductType.Food}
          setProductList={setFoodProducts}
          inStockFilterChecked={inStockFilterChecked}
          setInStockFilterChecked={setInStockFilterChecked}
        />
      )}
      {/* <Box component="div" sx={{ margin: "0 auto", maxWidth: "1440px" }}> */}
      <FlexContainer
        direction="row"
        aria-label="aria-foods-page"
        gap="20px"
        margin={isMobile ? "30px 0 20px" : "30px 3% 10px"}
        justifyContent={isMobile ? "center" : "start"}
      >
        {foodProducts.length ? (
          <FoodList />
        ) : (
          <YouHaveNoProduct productType={ProductType.Food} />
        )}
        <Outlet />
      </FlexContainer>
      {/* </Box> */}
    </>
  );
};

export default Food;
