import React, { Fragment, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ProductInterface } from "../types/common.types";
import Product, { ProductType } from "../components/product";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import YouHaveNoProduct from "../components/styled/YouHaveNoProduct";
import { FlexContainer } from "../components/styled/Generic";
import DesktopFilter from "../components/filter/DesktopFilter";
import { useWindowSize } from "../helpers/generic";
import MobileFilter from "../components/filter/MobileFilter";

const DrinkPage = () => {
  const { drinks } = useSelector((state: RootState) => state.Product);
  const [drinksList, setDrinksList] = useState<ProductInterface[]>([]);
  const isMobile = useWindowSize().width < 900;
  const [inStockFilterChecked, setInStockFilterChecked] = useState(true);

  useEffect(() => {
    setDrinksList(drinks);
  }, [drinks]);

  const DrinksList = () => (
    <Fragment>
      {drinksList.map((drink, index) => {
        if (inStockFilterChecked && !drink.inStock)
          return <Fragment key={drink.id}></Fragment>;

        return (
          <Product
            key={drink.id}
            product={drink}
            productType={ProductType.Drink}
            parent="Page"
          />
        );
      })}
    </Fragment>
  );

  return (
    <>
      {isMobile ? (
        <MobileFilter
          productType={ProductType.Drink}
          setProductList={setDrinksList}
          inStockFilterChecked={inStockFilterChecked}
          setInStockFilterChecked={setInStockFilterChecked}
        />
      ) : (
        <DesktopFilter
          productType={ProductType.Drink}
          inStockFilterChecked={inStockFilterChecked}
          setInStockFilterChecked={setInStockFilterChecked}
          setProductList={setDrinksList}
        />
      )}
      {/* <Box component="div" sx={{ margin: "0 auto", maxWidth: "1440px" }}> */}
      <FlexContainer
        direction="row"
        aria-label="aria-drinks-page"
        gap="20px"
        margin={isMobile ? "30px 0 20px" : "30px 3% 10px"}
        justifyContent={isMobile ? "center" : "start"}
      >
        {!drinks.length && <YouHaveNoProduct productType={ProductType.Drink} />}
        {!!drinks.length && <DrinksList />}
        <Outlet />
      </FlexContainer>
      {/* </Box> */}
    </>
  );
};

export default DrinkPage;
