import React, { useState } from "react";
import { Category } from "../../../types/common.types";
import { Button } from "@mui/material";
import CategoryAction from "./CategoryAction";
import { ProductType } from "../../product";
import { useTranslation } from "../../../hooks/useTranslation";
import { capitalizeWords } from "../../../helpers/format";

interface CategoryCardProps {
  category: Category;
  productType: ProductType.Drink | ProductType.Food;
}

export default function CategoryCard({
  category,
  productType,
}: CategoryCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);

  const renameTranslation = useTranslation("rename");
  const deleteTranslation = useTranslation("delete");

  const handleRenameClick = () => {
    setOpen(true);
    setIsRenaming(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsRenaming(false);
    }, 500);
  };

  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "150px",
        // margin: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "150px",
          height: "70px",
          border: "1px solid #007BFF",
          borderRadius: "10px",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <h3
          style={{
            fontSize: "1em",
            margin: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {capitalizeWords(category.name)}
        </h3>

        <p
          style={{
            fontSize: "0.9em",
            margin: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {!!category.drinks && `${category.drinks.length} products`}
        </p>
        <p
          style={{
            fontSize: "0.9em",
            margin: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {!!category.food && `${category.food.length} products`}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button sx={{ textTransform: "none" }} onClick={handleRenameClick}>
          {renameTranslation}
        </Button>
        <Button sx={{ textTransform: "none" }} onClick={() => setOpen(true)}>
          {deleteTranslation}
        </Button>
      </div>

      <CategoryAction
        open={open}
        onClose={handleClose}
        category={category}
        isRenaming={isRenaming}
        productType={productType}
      />
    </div>
  );
}
