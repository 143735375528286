import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ProductInterface } from "../../types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Paper from "@mui/material/Paper";
import {
  AppBar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import { ProductType } from "../product";
import {
  setFood,
  setActiveCategoryFilter,
  setDrinks,
} from "../../redux/slices/ProductState";
import { useDebounce } from "../../hooks/useDebounce";
import CategoryList from "../modal/category-management/CategoryList";
import CustomButton from "../common/CustomButton";
import { FlexContainer } from "../styled/Generic";
import { useTranslation } from "../../hooks/useTranslation";
import { capitalizeWords } from "../../helpers/format";

const FilterWrapper = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10px;
  z-index: 10;
  min-height: 60px;
  margin-bottom: 5px;
  padding: 20px 5% 0px;
  gap: 20px;
`;

const SearchContainer = styled.div`
  width: "100%";
`;

interface Filter {
  productType: ProductType.Drink | ProductType.Food;
  inStockFilterChecked: boolean;
  setProductList: React.Dispatch<React.SetStateAction<ProductInterface[]>>;
  setInStockFilterChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileFilter = (props: Filter) => {
  const {
    productType,
    setInStockFilterChecked,
    inStockFilterChecked,
    setProductList,
  } = props;
  const dispatch = useDispatch();
  const [openCategoryManagement, setOpenCategoryManagement] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue.toLowerCase());
  const categories = useSelector((state: RootState) =>
    productType === ProductType.Food
      ? state.Product.foodCategories
      : state.Product.drinkCategories
  );
  const products = useSelector((state: RootState) =>
    productType === ProductType.Food ? state.Product.food : state.Product.drinks
  );
  const language = useSelector(
    (state: RootState) => state.Auth.user.defaultLanguage
  );
  const activeCategoryFilter = useSelector(
    (state: RootState) => state.Product.activeCategoryFilterId
  );
  const [showFilter, setShowFilter] = useState(false);

  const manageCategories = useTranslation("manage_categories");
  const filterByCategoryTranslation = useTranslation("filter_by_category");
  const hideFilterTranslation = useTranslation("hide_filter");
  const showFilterTranslation = useTranslation("show_filter");
  const inStockLabel = useTranslation("in_stock_label");

  useEffect(() => {
    const query = debouncedSearchValue.trim();
    if (products.length && language) {
      const filteredProducts = products.filter((product) =>
        product.names[language].toLowerCase().includes(query)
      );
      if (!filteredProducts.length) {
        alert("Your search returned no result");
        setSearchValue("");
      } else {
        setProductList(filteredProducts);
      }
    }
  }, [debouncedSearchValue, language, products, setProductList]);

  useEffect(() => {
    dispatch(setActiveCategoryFilter({ categoryId: "All" }));
    //eslint-disable-next-line
  }, []);

  const filterChangeHandler = (value: string) => {
    dispatch(setActiveCategoryFilter({ categoryId: value }));
    setSearchValue("");
    if (value === "All") {
      let all: ProductInterface[] = [];
      categories.forEach((category) => {
        if (category.food) all = [...all, ...category.food];
        if (category.drinks) all = [...all, ...category.drinks];
      });
      if (productType === ProductType.Drink) {
        dispatch(setDrinks(all));
      } else if (productType === ProductType.Food) {
        dispatch(setFood(all));
      }
    } else {
      const filteredCategory = categories.filter(
        (category) => category.id === value
      )[0];
      if (productType === ProductType.Drink && filteredCategory.drinks) {
        dispatch(setDrinks(filteredCategory.drinks));
      } else if (productType === ProductType.Food && filteredCategory.food) {
        dispatch(setFood(filteredCategory.food));
      }
    }
  };

  if (!categories.length) {
    return <></>;
  }
  const checkboxLabel = { inputProps: { "aria-label": "Checkbox demo" } };
  const label = { slotProps: { input: { "aria-label": "Demo switch" } } };
  return (
    <>
      {showFilter ? (
        <FilterWrapper>
          <FormLabel
            id="row-restaurant-category-group-label"
            sx={{ color: "primary.main", fontWeight: "500" }}
          >
            {filterByCategoryTranslation}
          </FormLabel>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              width: "100%",
            }}
          >
            <FormControl fullWidth variant="outlined">
              <Select
                value={activeCategoryFilter || "All"}
                onChange={(e) => filterChangeHandler(e.target.value as string)}
                displayEmpty
              >
                <MenuItem value="All">All</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {capitalizeWords(category.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ whiteSpace: "nowrap" }}>
              <FormControlLabel
                id="row-restaurant-category-group-label"
                control={
                  <Checkbox
                    {...checkboxLabel}
                    checked={inStockFilterChecked}
                    onChange={() =>
                      setInStockFilterChecked(!inStockFilterChecked)
                    }
                  />
                }
                label={inStockLabel}
                labelPlacement="top"
              />
            </FormControl>
          </Box>

          <Box component="div">
            <SearchContainer>
              <FormControl fullWidth>
                <TextField
                  id="input-with-sx"
                  placeholder="Find a product"
                  variant="standard"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded sx={{ color: "action.active" }} />
                      </InputAdornment>
                    ),
                    sx: {
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                      },
                    },
                  }}
                />
              </FormControl>
            </SearchContainer>
            <FlexContainer
              direction="row"
              alignItems="center"
              flexWrap="nowrap"
              gap="20px"
              margin="20px 0px 0px"
            >
              <CustomButton
                size="large"
                onClick={() => setOpenCategoryManagement(true)}
                sx={{ p: 3 }}
              >
                {manageCategories}
              </CustomButton>
              <Box>
                <Typography
                  component="span"
                  color="primary.main"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  <small>{hideFilterTranslation}</small>
                </Typography>

                <Box>
                  <Switch
                    sx={{ height: "39px" }}
                    {...label}
                    checked={showFilter}
                    onChange={() => setShowFilter(false)}
                  />
                </Box>
              </Box>
            </FlexContainer>
          </Box>

          <CategoryList
            open={openCategoryManagement}
            onClose={() => setOpenCategoryManagement(false)}
            categories={categories}
            productType={productType}
          />
        </FilterWrapper>
      ) : (
        <AppBar
          component="div"
          sx={{
            height: "59px",
            backgroundColor: "primary.main",
            width: "100%",
            borderRadius: "16px",
            margin: "20px 0",
            position: "sticky",
            top: {
              xs: "55px",
              sm: "70px",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setShowFilter(true)}
        >
          <Toolbar>
            <Typography variant="h6" component="div">
              {showFilterTranslation}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default MobileFilter;
