import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import QRCode from "qrcode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import TableChartIcon from "@mui/icons-material/TableChart";

interface Props {
  clientUsername: string | null;
  clientSegment: string | null;
}

export default function GenerateBarcode({
  clientUsername,
  clientSegment,
}: Props) {
  const [value, setValue] = useState<string>("");
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [tableNumber, setTableNumber] = useState<string>("");

  const downloadBarcode = useTranslation("download_barcode");
  const printBarcode = useTranslation("print_barcode");
  const generateBarcodeFor = useTranslation("generate_barcode_for");
  const segmentText = useTranslation("segment_text");
  const noQrCodeInfo = useTranslation("no_qr_code_info");
  const tableNoLable = useTranslation("table_number_input_label");
  const tableNoPlaceholder = useTranslation("table_number_input_placeholder");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, "");
    setValue(valueWithoutSpaces);
    setTableNumber(valueWithoutSpaces);
  };

  const handleDownload = () => {
    if (qrCode) {
      const link = document.createElement("a");
      link.href = qrCode;
      link.download = `table_${tableNumber}.png`;
      link.click();
    }
  };

  const handlePrint = () => {
    if (qrCode) {
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(`<img src="${qrCode}" />`);
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.focus();
          printWindow.print();
          printWindow.close();
        };
      }
    }
  };

  useEffect(() => {
    if (tableNumber) {
      let url;
      if (clientSegment) {
        url = `menutender.com/${clientUsername}/${clientSegment.toLowerCase()}/${tableNumber}`;
      } else {
        url = `menutender.com/${clientUsername}/${tableNumber}`;
      }
      QRCode.toDataURL(url, { width: 200, margin: 2 }, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setQrCode(url);
        }
      });
    } else {
      setQrCode(null);
    }
  }, [clientSegment, clientUsername, tableNumber]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: { xs: "100%", xl: "650px" },
        maxWidth: { xs: "550px", xl: "100%" },
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: { xs: "24px 20px 10px", md: "30px 30px 10px" },
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" sx={{ mb: 4, textTransform: "capitalize" }}>
        {generateBarcodeFor} {clientSegment} {segmentText}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", lg: "space-between" },
          flexWrap: { xs: "wrap", lg: "nowrap" },
          gap: { xs: "20px", xl: "50px" },
          mb: 4,
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "200px",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
          }}
        >
          {qrCode ? (
            <img src={qrCode} alt="QR Code" />
          ) : (
            <Typography
              variant="body2"
              sx={{ p: 1, textAlign: "center", color: "GrayText" }}
            >
              {`${noQrCodeInfo}.`}
            </Typography>
          )}
        </Paper>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "500", textAlign: "center", mb: 4 }}
          >
            {clientSegment
              ? `menutender.com/${clientUsername}/${clientSegment.toLowerCase()}/`
              : `menutender.com/${clientUsername}/`}
            <span style={{ color: "#6e151e" }}>{value}</span>
          </Typography>

          <TextField
            label={`${tableNoLable}.`}
            type="number"
            variant="outlined"
            placeholder={tableNoPlaceholder}
            InputProps={{
              startAdornment: <TableChartIcon sx={{ mr: 1 }} />,
            }}
            value={value}
            onChange={handleChange}
            sx={{ width: "250px" }}
          />
        </Box>
      </Box>

      {tableNumber && (
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            sx={{ textTransform: "none", boxShadow: "none" }}
          >
            {downloadBarcode}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePrint}
            sx={{
              textTransform: "none",
              border: "2px solid #6e151e",
              ":hover": {
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                border: "2px solid #6e151e",
              },
            }}
          >
            {printBarcode}
          </Button>
        </Box>
      )}
    </Box>
  );
}
