import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { getOrders } from "../../helpers/xhr";
import OrderTable from "./OrderTable";
import OrderWidget from "./OrderWidget";

import { OrderFromDB } from "../../redux/slices/types/OrderAndSocketState";
import { useTranslation } from "../../hooks/useTranslation";
import { MembershipTier } from "../../types/common.types";
import { StyledNoDataWrapper } from "../styled/Generic";
import CustomButton from "../common/CustomButton";
import UpgradeModal from "../upgrade/UpgradeModal";
import { get } from "lockr";
import PaymentCallback from "../upgrade/PaymentCallback";

export enum OrderStatus {
  Pending,
  Processing,
  Delivered,
}

const DesktopOrder = () => {
  const user = useSelector((state: RootState) => state.Auth.user);
  const orders = useSelector((state: RootState) => state.Order.orders);
  const dispatch = useDispatch();
  const activeCard = "Orders";
  const [selectedStatus, setSelectedStatus] = useState<OrderStatus>(
    OrderStatus.Pending
  );
  const [order, setOrder] = useState<OrderFromDB | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<OrderFromDB[]>(orders);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPaymentCallback, setShowPaymentCallback] = useState(false);

  const filterOrders = (status: OrderStatus) => {
    const filtered = orders.filter((order) => order.status === status);
    setFilteredOrders(filtered);
  };

  const thereAreNoOrdersToDisplay = useTranslation(
    "there_are_no_orders_to_display"
  );
  const customerOrdersText =
    useTranslation(
      "customer_orders_will_show_up_here_when_order_have_been_placed"
    ) || "Customer orders will show up here when order have been placed";
  const defaultDeliveryTime = useTranslation("default_delivery_time");
  const defaultProcessingMessage = useTranslation("default_processing_message");
  const pendingTranslation = useTranslation("pending");
  const processingTranslation = useTranslation("processing");
  const deliveredTranslation = useTranslation("delivered");
  const upgradeTranslation = useTranslation("upgrade");
  const upgradeMembershipTier = useTranslation(
    "upgrade_your_membership_to_accept_orders"
  );

  const paymentRef = get("pRef");

  const noOrder = require("../../assets/no-order.png");

  const handleUpgradeClick = () => {
    if (paymentRef) {
      setShowPaymentCallback(true);
      return;
    }
    setShowUpgradeModal(true);
  };

  useEffect(() => {
    if (orders.length) setOrder(orders[0]);
    if (!orders.length) getOrders(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterOrders(selectedStatus);
    // eslint-disable-next-line
  }, [orders, selectedStatus]);

  if (user.membershipDetails.tier !== MembershipTier.PREMIUM) {
    return (
      <>
        <StyledNoDataWrapper>
          <Typography variant="h5" gutterBottom>
            {upgradeMembershipTier}
          </Typography>
          <CustomButton
            sx={{
              maxWidth: "150px",
            }}
            onClick={handleUpgradeClick}
          >
            {upgradeTranslation}
          </CustomButton>
        </StyledNoDataWrapper>
        {showPaymentCallback && <PaymentCallback paymentRef={paymentRef} />}
        <UpgradeModal
          currentTier={user.membershipDetails.tier}
          open={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
        />
      </>
    );
  }

  if (!orders.length) {
    return (
      <StyledNoDataWrapper>
        <Box
          component="img"
          src={noOrder}
          alt="empty order"
          sx={{
            width: 100,
            mb: 2,
          }}
        />
        <Typography variant="h5" gutterBottom>
          {thereAreNoOrdersToDisplay}
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: "550px" }}>
          {customerOrdersText}
        </Typography>
      </StyledNoDataWrapper>
    );
  }

  const handleStatusClick = (status: OrderStatus) => {
    setSelectedStatus(status);
  };

  const handleOrderClick = (order: OrderFromDB | null) => {
    if (order) {
      setOrder(order);
    } else {
      setOrder(null);
    }
  };

  let headerColor;
  if (selectedStatus === OrderStatus.Pending) {
    headerColor = "error.main";
  } else if (selectedStatus === OrderStatus.Processing) {
    headerColor = "warning.main";
  } else {
    headerColor = "success.main";
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Box component="div" sx={{ display: "flex", gap: "50px", mb: "30px" }}>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            maxWidth: "max-content",
            p: "10px 15px",
            lineHeight: "1",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          {defaultDeliveryTime}
        </Button>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            maxWidth: "max-content",
            p: "10px 15px",
            lineHeight: "1",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          {defaultProcessingMessage}
        </Button>
      </Box>

      <Box component="main" sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", gap: "30px" }}>
          <Box sx={{ width: "50%" }}>
            <Box
              sx={{
                marginBottom: "30px",
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Button
                variant={
                  selectedStatus === OrderStatus.Pending
                    ? "contained"
                    : "outlined"
                }
                color="error"
                sx={{
                  textTransform: "none",
                  p: "10px 20px",
                  minWidth: "30%",
                  borderRadius: "8px",
                  backgroundColor:
                    selectedStatus === OrderStatus.Pending
                      ? "error.light"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "error.light",
                    color: "#fff",
                  },
                }}
                onClick={() => handleStatusClick(OrderStatus.Pending)}
              >
                {pendingTranslation}
              </Button>
              <Button
                variant={
                  selectedStatus === OrderStatus.Processing
                    ? "contained"
                    : "outlined"
                }
                color="warning"
                sx={{
                  textTransform: "none",
                  p: "10px 20px",
                  minWidth: "30%",
                  borderRadius: "8px",
                  backgroundColor:
                    selectedStatus === OrderStatus.Processing
                      ? "warning.light"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "warning.light",
                    color: "#fff",
                  },
                }}
                onClick={() => handleStatusClick(OrderStatus.Processing)}
              >
                {processingTranslation}
              </Button>
              <Button
                variant={
                  selectedStatus === OrderStatus.Delivered
                    ? "contained"
                    : "outlined"
                }
                color="success"
                sx={{
                  textTransform: "none",
                  p: "10px 20px",
                  minWidth: "30%",
                  borderRadius: "8px",
                  backgroundColor:
                    selectedStatus === OrderStatus.Delivered
                      ? "success.light"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "success.light",
                    color: "#fff",
                  },
                }}
                onClick={() => handleStatusClick(OrderStatus.Delivered)}
              >
                {deliveredTranslation}
              </Button>
            </Box>

            <OrderTable
              orders={filteredOrders}
              show={handleOrderClick}
              headerColor={headerColor}
            />
          </Box>

          <Box sx={{ width: "50%" }}>
            {activeCard === "Orders" && (
              <OrderWidget orderDetails={order} status={selectedStatus} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopOrder;
