import React from "react";
import { OrderFromDB } from "../../redux/slices/types/OrderAndSocketState";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { money, useWindowSize } from "../../helpers/generic";

export function OrderRow(props: {
  row: OrderFromDB;
  handleClick: (order: OrderFromDB) => void;
  isSelected: boolean;
}) {
  const { row, handleClick, isSelected } = props;
  const user = useSelector((state: RootState) => state.Auth.user);
  const isMobile = useWindowSize().width < 900;

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: "pointer",
          backgroundColor: isSelected ? "#14141414" : "inherit",
          "&:hover": {
            backgroundColor: isSelected ? "#1E1E1E1E" : "#0A0A0A0A",
          },
        }}
        onClick={() => handleClick(row)}
      >
        <TableCell component="th" scope="row" sx={{ whiteSpace: "wrap" }}>
          {isMobile
            ? format(new Date(row.createdAt), "MMM d, h:mma")
            : format(new Date(row.createdAt), "PPpp")}
        </TableCell>
        <TableCell align="center">{row.tableNumber}</TableCell>
        <TableCell align="right">
          {money(row.orderTotal, user.currency.prefix, false)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
