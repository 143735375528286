import React, { useEffect, useState } from "react";
import { Language } from "../../types/common.types";
import { useDispatch } from "react-redux";
import { languages as allLanguages } from "../../helpers/generic";
import { AddLanguage, updateDefaultLanguage } from "../../helpers/xhrHelper";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { SettingCard } from "./CustomCard";
import CustomButton from "../common/CustomButton";
import { useTranslation } from "../../hooks/useTranslation";

const SettingsWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
}));
interface Props {
  existingLanguages: Array<Language>;
  defaultLanguage: string;
}
const LanguageSetting = (props: Props) => {
  const { existingLanguages, defaultLanguage } = props;
  const [newLanguage, setNewLanguage] = useState("");
  const [availableLanguagesList, setAvailableLanguagesList] = useState<
    Array<Language>
  >([]);
  const dispatch = useDispatch();

  const newLanguageTranslation = useTranslation("new_language");
  const chooseLanguage = useTranslation("choose_language");
  const addLanguage = useTranslation("add_language");
  const defaultLanguageTranslation = useTranslation("default_language");
  const setAsYourDefaultSiteLanguageForYourBusiness = useTranslation(
    "set_as_your_default_site_language_for_your_business"
  );
  const setDefault = useTranslation("set_default");
  const deleteTranslation = useTranslation("delete");

  useEffect(() => {
    const existingLanguagesIso = existingLanguages.map((lang) => lang.iso);
    const availableLanguageOptions = allLanguages.filter(
      (availableLanguage) =>
        !existingLanguagesIso.includes(availableLanguage.iso)
    );
    setAvailableLanguagesList(availableLanguageOptions);
  }, [existingLanguages]);

  const languageAddHandler = () => {
    const selectedLanguage = allLanguages.find(
      (language) => language.name === newLanguage
    );
    if (!selectedLanguage) {
      alert("Select a language");
      return;
    }
    const newUserLanguages = existingLanguages.concat(selectedLanguage);
    AddLanguage(dispatch, newUserLanguages);
  };

  const languageDeleteHandler = (iso: string) => {
    const confirm = prompt("Type 'YES' to confirm language deletion");
    if (confirm !== "YES") return false;
    const newUserLanguages = existingLanguages.filter(
      (language) => language.iso !== iso
    );
    AddLanguage(dispatch, newUserLanguages);
  };

  return (
    <SettingsWrapper>
      <SettingCard
        header={
          <Box component="div" sx={{ display: "flex", gap: 1 }}>
            <SettingsSuggestIcon />
            <Typography variant="h6">{newLanguageTranslation}</Typography>
          </Box>
        }
        content={
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {chooseLanguage}
            </InputLabel>
            <Select
              value={newLanguage}
              onChange={(e) => setNewLanguage(e.target.value)}
              label={chooseLanguage}
              sx={{
                transition: "height 0.3s ease",
              }}
              size="medium"
            >
              {availableLanguagesList.length &&
                availableLanguagesList.map((language, index) => (
                  <MenuItem key={index} value={language.name}>
                    {language.name} ({language.iso})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        }
        footer={
          <Box sx={{ display: "flex", justifyContent: "end" }} width="100%">
            <CustomButton
              onClick={() => languageAddHandler()}
              sx={{ maxWidth: "129px" }}
            >
              {addLanguage}
            </CustomButton>
          </Box>
        }
      />

      {existingLanguages.map((language) => {
        const isDefaultLang = language.iso === defaultLanguage;
        return (
          <Box key={language.iso}>
            <SettingCard
              header={
                <Box component="div" sx={{ display: "flex", gap: 1 }}>
                  <InterpreterModeIcon />
                  <Typography variant="h6">{language.name}</Typography>
                </Box>
              }
              content={
                isDefaultLang ? (
                  <Typography variant="h6">
                    {defaultLanguageTranslation}
                  </Typography>
                ) : (
                  <Typography variant="body2" maxWidth={225}>
                    {setAsYourDefaultSiteLanguageForYourBusiness}
                  </Typography>
                )
              }
              footer={
                !isDefaultLang ? (
                  <Box sx={{ display: "flex", gap: 1 }} width="100%">
                    <CustomButton
                      onClick={() =>
                        updateDefaultLanguage(dispatch, language.iso)
                      }
                    >
                      {setDefault}
                    </CustomButton>
                    <CustomButton
                      background="#9e0505"
                      onClick={() => languageDeleteHandler(language.iso)}
                    >
                      {deleteTranslation}
                    </CustomButton>
                  </Box>
                ) : (
                  <></>
                )
              }
            />
          </Box>
        );
      })}
    </SettingsWrapper>
  );
};

export default LanguageSetting;
