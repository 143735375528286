import { TextField, Box, Typography, Popover, IconButton } from "@mui/material";
import React, { useState } from "react";
import { AddSegment } from "../../../helpers/xhrHelper";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../../../hooks/useTranslation";
import { RootState } from "../../../redux/store";
import { MembershipTier } from "../../../types/common.types";
import UpgradeModal from "../../upgrade/UpgradeModal";
import { get } from "lockr";
import PaymentCallback from "../../upgrade/PaymentCallback";
import SegmentIcon from "@mui/icons-material/Segment";

interface AddSegmentProps {
  id: "simple-popover" | undefined;
  open: boolean;
  anchorEl: HTMLElement | null;
  clientUsername: string;
  onClose: () => void;
}

export default function AddSegmentModal({
  id,
  open,
  anchorEl,
  clientUsername,
  onClose,
}: AddSegmentProps) {
  const [value, setValue] = useState<string>("");
  const [segmentName, setSegmentName] = useState<string>("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.Auth.user);

  const createSegment = useTranslation("create_segment_header_title");
  const segmentNameTranslation = useTranslation("create_segment_input_label");
  const submit = useTranslation("submit");
  const upgradeMembershipTier = useTranslation(
    "upgrade_your_membership_to_create_a_segment"
  );
  const upgradeTranslation = useTranslation("upgrade_btn_text");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, "");
    setValue(valueWithoutSpaces);

    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => setSegmentName(e.target.value), 2000);
    setTimer(newTimer);
  };

  const handleUpgradeClick = () => setShowUpgradeModal(true);

  const handleSubmit = async () => {
    if (segmentName) {
      await AddSegment(dispatch, clientUsername, segmentName);
      onClose();
    }
  };

  let mainText = createSegment;
  if (user.membershipDetails.tier === MembershipTier.FREE) {
    mainText = upgradeMembershipTier;
  }

  const Button = () => (
    <CustomButton
      sx={{
        width: "100%",
        maxWidth: "200px",
      }}
      disabled={user.hasSegments && !segmentName}
      onClick={
        user.membershipDetails.tier !== MembershipTier.FREE
          ? handleSubmit
          : handleUpgradeClick
      }
    >
      {user.membershipDetails.tier !== MembershipTier.FREE
        ? submit
        : upgradeTranslation}
    </CustomButton>
  );

  const paymentRef = get("pRef");
  if (paymentRef && user.membershipDetails.tier === MembershipTier.FREE) {
    return <PaymentCallback paymentRef={paymentRef} />;
  }

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            borderRadius: "12px",
            padding: "16px",
            maxWidth: "100%",
            width: "90%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="small"
            sx={{
              alignSelf: "flex-end",
              backgroundColor: "primary.main",
              color: "#fff",
              fontSize: "12px",
              "&:hover": { backgroundColor: "#666666" },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {mainText}
          </Typography>

          {user.membershipDetails.tier !== MembershipTier.FREE ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <TextField
                label={segmentNameTranslation}
                type="text"
                variant="outlined"
                placeholder="New Segment"
                InputProps={{
                  startAdornment: <SegmentIcon sx={{ mr: 1 }} />,
                }}
                value={value}
                onChange={handleChange}
                sx={{ width: "250px", mb: 1 }}
              />
              <code style={{ color: "black", fontWeight: "500" }}>
                {`menutender.com/${clientUsername}/${value.toLowerCase()}`}
              </code>
            </Box>
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              {mainText}
            </Typography>
          )}
          <Button />
        </Box>
      </Popover>

      <UpgradeModal
        currentTier={user.membershipDetails.tier}
        open={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </>
  );
}
