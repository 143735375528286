import { useRouteError } from "react-router-dom";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { logout } from "../auth/helper";
import { useDispatch } from "react-redux";
import { useTranslation } from "../../hooks/useTranslation";

export const ErrorBoundary = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let title = "";
  let text = "";
  const error = useRouteError();

  const sessionTimeoutTitle = useTranslation("session_timeout_title");
  const sessionTimeoutText = useTranslation("session_timeout_text");
  const unknownErrorTitle = useTranslation("unknown_error_title");
  const unknownErrorText = useTranslation("unknown_error_text");

  // @ts-ignore
  const errorCode: number = error.response.status;
  switch (errorCode) {
    case 401:
      title = sessionTimeoutTitle;
      text = sessionTimeoutText;
      break;
    default:
      title = unknownErrorTitle;
      text = unknownErrorText;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => logout(dispatch)}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};
