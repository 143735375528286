import * as Request from "./xhr";
import { getDrinks, XHR_STATE } from "./xhr";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setModalState,
  updateFeedback,
  updateMediaXhr,
  updateXHR,
} from "../redux/slices/AppState";
import { MODAL } from "../components/modal/helpers/constants";
import {
  setCategory,
  setMediaPhotos,
  setProductAddType,
} from "../redux/slices/ProductState";
import {
  BusinessInfo,
  Currency,
  FEEDBACK_ALERTS,
  Language,
  PasswordUpdateData,
  ProductInterface,
  Segment,
  User,
  XHR_Error,
} from "../types/common.types";
import { FEEDBACK_VARIANT } from "../components/extras/Alert";
import { NavigateFunction } from "react-router-dom";
import { ProductType } from "../components/product";
import { get, set } from "lockr";
import { setUserState, USER_STATE } from "../redux/slices/Auth";
import { AxiosResponse } from "axios";

interface ProductWrite {
  data: any; //ProductFormData;
  dispatch: Dispatch<any>;
}

export const getStaffPermission = () => {
  const staffPermissions = get("staff-permissions");
  return staffPermissions;
};

export const AddCategory = async (
  dispatch: Dispatch<any>,
  categoryName: string,
  productType: ProductType,
  usingProductState: boolean = false
) => {
  Request.addProductCategory(categoryName, productType)
    .then(() => {
      if (usingProductState) {
        dispatch(setCategory(""));
      }
    })
    .then(() => {
      if (productType === ProductType.Drink) {
        Request.getDrinkCategories(dispatch);
      } else {
        Request.getFoodCategories(dispatch);
      }
    });
};

export const UpdateProductCategory = async (
  dispatch: Dispatch<any>,
  categoryId: string,
  categoryName: string,
  productType: ProductType.Drink | ProductType.Food
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  const response = await Request.updateProductCategory(
    categoryId,
    categoryName,
    productType
  );
  if (response.data.success) {
    if (productType === ProductType.Drink) {
      Request.getDrinks("Loader", dispatch);
    } else {
      Request.getFoodProducts("Loader", dispatch);
    }
  }
  return response.data.success;
};

export const DeleteProductCategory = async (
  dispatch: Dispatch<any>,
  categoryId: string,
  productType: ProductType.Drink | ProductType.Food
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  const response = await Request.deleteProductCategory(categoryId, productType);
  if (response.data.success) {
    if (productType === ProductType.Drink) {
      Request.getDrinks("Loader", dispatch);
    } else {
      Request.getFoodProducts("Loader", dispatch);
    }
  }
  return response.data.success;
};

export const CreateDrink = (props: ProductWrite): Promise<boolean> => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  props.dispatch(updateXHR({ xhr: Request.XHR_STATE.REQUESTING }));
  return Request.InsertDrink(props.data)
    .then((response) => {
      const { data } = response;
      if (data.success) {
        props.dispatch(setProductAddType(null));
        props.dispatch(updateXHR({ xhr: Request.XHR_STATE.SUCCESS }));
        props.dispatch(setModalState(MODAL.VIEW));
      }
    })
    .then(async () => {
      await getDrinks("Helper", props.dispatch);
    })
    .then(() => true)
    .catch(() => false);
};
export const UpdateDrink = (props: ProductWrite, productId: string) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  props.dispatch(updateXHR({ xhr: Request.XHR_STATE.REQUESTING }));
  return Request.UpdateDrink(props.data, productId)
    .then(
      (
        response: AxiosResponse<{
          success: boolean;
          updatedProduct: ProductInterface;
        }>
      ) => {
        const { data } = response;
        if (data.success) {
          props.dispatch(
            updateFeedback({
              show: true,
              type: FEEDBACK_VARIANT.SUCCESS,
              message: FEEDBACK_ALERTS.PRODUCT_UPDATED,
            })
          );
          props.dispatch(setProductAddType(null));
          props.dispatch(updateXHR({ xhr: Request.XHR_STATE.SUCCESS }));
          props.dispatch(setModalState(MODAL.VIEW));
        }

        return data.success;
      }
    )
    .then((success) => success)
    .catch((error) => {
      const errorData = error.response?.data as XHR_Error;
      const message = errorData && errorData.error.message;
      props.dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message,
          timeout: 10000,
        })
      );
      return false;
    });
};
export const DeleteDrink = (
  drink: ProductInterface,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  Request.deleteDrink(drink).then((response) => {
    Request.getDrinks("Helper", dispatch);
    dispatch(
      updateFeedback({
        show: true,
        type: FEEDBACK_VARIANT.SUCCESS,
        message: response.data.message,
      })
    );
    setTimeout(() => navigate("/app/drinks"), 1500);
  });
};

//=========== Food ======

export const CreateFood = (props: ProductWrite) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  props.dispatch(updateXHR({ xhr: Request.XHR_STATE.REQUESTING }));
  return Request.InsertFood(props.data)
    .then((response) => {
      const { data } = response;
      if (data.success) {
        props.dispatch(
          updateFeedback({
            show: false,
            type: FEEDBACK_VARIANT.SUCCESS,
            message: FEEDBACK_ALERTS.PRODUCT_ADDED,
          })
        );
        props.dispatch(setProductAddType(null));
        props.dispatch(updateXHR({ xhr: Request.XHR_STATE.SUCCESS }));
        props.dispatch(setModalState(MODAL.VIEW));
      }

      return data.success;
    })
    .catch((error) => console.log(error));
};
export const UpdateFood = (props: ProductWrite, productId: string) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  props.dispatch(updateXHR({ xhr: Request.XHR_STATE.REQUESTING }));
  return Request.UpdateFood(props.data, productId)
    .then((response) => {
      const { data } = response;
      if (data.success) {
        props.dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.SUCCESS,
            message: FEEDBACK_ALERTS.PRODUCT_UPDATED,
          })
        );
        props.dispatch(setProductAddType(null));
        props.dispatch(updateXHR({ xhr: Request.XHR_STATE.SUCCESS }));
        props.dispatch(setModalState(MODAL.VIEW));
      }

      return data.success;
    })
    .catch((error) => {
      const errorData = error.response?.data as XHR_Error;
      const message = errorData && errorData.error.message;
      props.dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message,
          timeout: 10000,
        })
      );
    });
};

export const DeleteFood = (
  drink: ProductInterface,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("product_write")) {
    throw new Error("Access denied!");
  }
  Request.deleteFood(drink).then((response) => {
    Request.getFoodProducts("Helper", dispatch);
    dispatch(
      updateFeedback({
        show: true,
        type: FEEDBACK_VARIANT.SUCCESS,
        message: response.data.message,
      })
    );
    setTimeout(() => navigate("/app/food"), 1500);
  });
};

//=========== Settings ===========

export const updateBusinessInfo = async (
  dispatch: Dispatch<any>,
  updateData: BusinessInfo
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("settings_modification")) {
    throw new Error("Access denied!");
  }
  dispatch(updateXHR({ xhr: Request.XHR_STATE.REQUESTING }));
  const response = await Request.updateBusinessInfo(updateData);
  if (response.data.success) {
    const user: User = get("user");
    user.address = updateData.address ?? user.address;
    user.brief = updateData.brief ?? user.brief;
    user.contacts = updateData.contacts ?? user.contacts;
    user.activities = updateData.activities ?? user.activities;
    set("user", user);
    dispatch(setUserState({ state: USER_STATE.LOGGED_IN, user }));
  }
};

export const AddLanguage = (dispatch: Dispatch<any>, languages: Language[]) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("settings_modification")) {
    throw new Error("Access denied!");
  }
  Request.addLanguage(languages).then((response) => {
    if (response.data.success) {
      const user: User = get("user");
      user.languages = languages;
      set("user", user);
      dispatch(setUserState({ state: USER_STATE.LOGGED_IN, user }));
    }
  });
};

export const updateDefaultLanguage = (
  dispatch: Dispatch<any>,
  languageISO: string
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("settings_modification")) {
    throw new Error("Access denied!");
  }
  Request.updateDefaultLanguage(languageISO).then((response) => {
    if (response.data.success) {
      const user: User = get("user");
      user.defaultLanguage = languageISO;
      set("user", user);
      dispatch(setUserState({ state: USER_STATE.LOGGED_IN, user }));
    }
  });
};

export const changeCurrency = (dispatch: Dispatch<any>, currency: Currency) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("settings_modification")) {
    throw new Error("Access denied!");
  }
  Request.updateCurrency(currency).then((response) => {
    if (response.data.success) {
      const user: User = get("user");
      user.currency = currency;
      set("user", user);
      dispatch(setUserState({ state: USER_STATE.LOGGED_IN, user }));
    }
  });
};

export const changePassword = async (data: PasswordUpdateData) => {
  const response = await Request.changePassword(data);
  return response.data;
};

export const AddSegment = (
  dispatch: Dispatch<any>,
  clientUsername: string,
  segmentName: string
) => {
  const staffPermissions = getStaffPermission();
  if (staffPermissions && !staffPermissions.includes("can_generate_barcode")) {
    throw new Error("Access denied!");
  }
  Request.addSegment(clientUsername, segmentName).then((response) => {
    if (response.data.success) {
      const segment: Segment = { name: response.data.segment.name };
      const user: User = get("user");
      user.segments.push(segment);
      set("user", user);
      dispatch(setUserState({ state: USER_STATE.LOGGED_IN, user }));
    }
  });
};

export const GetMedia = (dispatch: Dispatch<any>, productType: ProductType) => {
  dispatch(updateMediaXhr({ xhr: XHR_STATE.REQUESTING, isFetchMedia: true }));
  const query = `?productType=${
    productType === ProductType.Drink ? "drinks" : "food"
  }`;
  Request.getMedia(query)
    .then((response) => {
      dispatch(updateMediaXhr({ xhr: XHR_STATE.NONE, isFetchMedia: true }));
      if (!response.data.success) throw new Error("Could not fetch media");
      dispatch(setMediaPhotos({ media: response.data.media }));
      setTimeout(() => {
        // Holding off media fetch completion for UX purposes.
        dispatch(updateMediaXhr({ xhr: XHR_STATE.NONE, isFetchMedia: false }));
      }, 2000);
      return true;
    })
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};
