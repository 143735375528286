import { useState, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Card } from "../../styled/Generic";
import { useTranslation } from "../../../hooks/useTranslation";
import { Box, Button } from "@mui/material";
import GenerateBarcodeModal from "./GenerateBarcodeModal";
import AddSegmentModal from "./AddSegmentModal";

export default function MobileBarcode() {
  const user = useSelector((state: RootState) => state.Auth.user);
  const [generateBarcodeMode, setGenerateBarcodeMode] =
    useState<boolean>(false);
  const [addSegmentMode, setAddSegmentMode] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [segment, setSegment] = useState<string | null>(null);

  const addSegment = useTranslation("add_segment");
  const generateBarcodeTitle = useTranslation("generate_barcode_title");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleAddSegmentClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setAddSegmentMode(true);
  };
  const handleSegmentClick = (
    event: MouseEvent<HTMLElement>,
    segmentName: string
  ) => {
    setAnchorEl(event.currentTarget);
    setGenerateBarcodeMode(true);
    setSegment(segmentName);
  };

  const handleGenerateBarcode = (event: MouseEvent<HTMLElement>) => {
    if (user.segments.length > 0) {
      setAnchorEl(event.currentTarget);
      setGenerateBarcodeMode(true);
      setSegment(user.segments[0].name);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setGenerateBarcodeMode(false);
    setAddSegmentMode(false);
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          flexWrap: "wrap",
        }}
      >
        <Card
          hoverEffect
          width="129px"
          borderRadius="6px"
          fontSize="14px"
          height="56px"
          centerAlignContent
          onClick={handleAddSegmentClick}
        >
          {addSegment}
        </Card>
        {!!user.segments.length &&
          user.segments.map((segment) => (
            <Card
              key={segment.name}
              hoverEffect
              width="129px"
              height="56px"
              fontSize="14px"
              borderRadius="6px"
              textTransform="capitalize"
              centerAlignContent
              onClick={(e: MouseEvent<HTMLElement>) =>
                handleSegmentClick(e, segment.name)
              }
            >
              {segment.name}
            </Card>
          ))}
      </div>

      <Box component="div" sx={{ my: 2 }}>
        <Button
          variant="text"
          type="button"
          size={"medium"}
          onClick={handleGenerateBarcode}
          sx={{ p: 0, textTransform: "none" }}
        >
          {generateBarcodeTitle}
        </Button>
      </Box>

      <div>
        {generateBarcodeMode && (
          <div>
            <GenerateBarcodeModal
              id={id}
              open={open}
              anchorEl={anchorEl}
              clientUsername={user.username}
              clientSegment={segment}
              onClose={handleClose}
            />
          </div>
        )}

        {addSegmentMode && (
          <div>
            <AddSegmentModal
              id={id}
              open={open}
              anchorEl={anchorEl}
              clientUsername={user.username}
              onClose={handleClose}
            />
          </div>
        )}
      </div>
    </Box>
  );
}
