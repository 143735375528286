import { useWindowSize } from "../helpers/generic";
import AddProductMobile from "../components/add-product/AddProductMobile";
import FeedbackAlert from "../components/extras/Alert";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import AddProductDesktop from "../components/add-product/AddProductDesktop";
import * as React from "react";
import { useState } from "react";
import {
  Category,
  MembershipTier,
  ProductAddSteps,
  ProductFormText,
} from "../types/common.types";
import {
  FeedBack,
  getSteps,
  ProductForm,
  StateGenerics,
  Step,
} from "../components/add-product/helper/addProductHelpers";
import { Box, Typography } from "@mui/material";
import { StyledNoDataWrapper } from "../components/styled/Generic";
import CustomButton from "../components/common/CustomButton";
import { useTranslation } from "../hooks/useTranslation";
import { ProductType } from "../components/product";
import PaymentCallback from "../components/upgrade/PaymentCallback";
import UpgradeModal from "../components/upgrade/UpgradeModal";
import { get } from "lockr";

export const NewProduct = () => {
  const user = useSelector((state: RootState) => state.Auth.user);
  const { drinkCategories, foodCategories } = useSelector(
    (state: RootState) => state.Product
  );
  const { feedback } = useSelector((state: RootState) => state.App);
  const isMobile = useWindowSize().width < 460;
  const [activeStep, setActiveStep] = React.useState(
    ProductAddSteps.SelectProduct
  );
  const [selectedProductType, setSelectedProductType] =
    useState<ProductType | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category>({
    createdAt: "",
    name: "",
    userId: "",
    id: "",
    updatedAt: "",
  });
  const [isProductCreateSuccessful, setIsProductCreateSuccessful] =
    useState(false);
  const [createdProductNames, setCreatedProductNames] =
    useState<ProductFormText>({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPaymentCallback, setShowPaymentCallback] = useState(false);

  const upgradeTranslation = useTranslation("upgrade");
  const upgradeMembershipTier = useTranslation(
    "upgrade_your_membership_to_add_more_products"
  );

  const paymentRef = get("pRef");

  const addProductProps: StateGenerics & ProductForm & FeedBack = {
    setSelectedCategory: setSelectedCategory,
    setCreatedProductNames: setCreatedProductNames,
    setProductSelect: setSelectedProductType,
    setActiveStep: setActiveStep,
    setFormSuccess: setIsProductCreateSuccessful,
    selectedProduct: selectedProductType,
    selectedCategory: selectedCategory,
    activeStep: activeStep,
    productNames: createdProductNames,
    isProductCreateSuccessful: isProductCreateSuccessful,
  };

  const [steps, setSteps] = useState<Step[]>(getSteps({ ...addProductProps }));

  const drinks = drinkCategories
    .map((category) => category.drinks || [])
    .flat();
  const foods = foodCategories.map((category) => category.food || []).flat();

  const numberOfProducts = drinks.length + foods.length;

  const hasBasicMembershipLimitReached =
    user.membershipDetails.tier === MembershipTier.BASIC &&
    numberOfProducts >= user.membershipDetails.limits.product;

  const hasFreeMembershipLimitReached =
    user.membershipDetails.tier === MembershipTier.FREE &&
    numberOfProducts >= user.membershipDetails.limits.product;

  const shouldShowUpgradeMessage =
    hasBasicMembershipLimitReached || hasFreeMembershipLimitReached;

  const handleUpgradeClick = () => {
    if (paymentRef) {
      setShowPaymentCallback(true);
      return;
    }
    setShowUpgradeModal(true);
  };

  return (
    <>
      {shouldShowUpgradeMessage ? (
        <StyledNoDataWrapper>
          <Typography variant="h5" gutterBottom>
            {upgradeMembershipTier}
          </Typography>
          <CustomButton
            sx={{
              maxWidth: "150px",
            }}
            onClick={handleUpgradeClick}
          >
            {upgradeTranslation}
          </CustomButton>
          {showPaymentCallback && <PaymentCallback paymentRef={paymentRef} />}
          <UpgradeModal
            currentTier={user.membershipDetails.tier}
            open={showUpgradeModal}
            onClose={() => setShowUpgradeModal(false)}
          />
        </StyledNoDataWrapper>
      ) : (
        <>
          <Box
            sx={{
              marginBottom: "5px",
            }}
          >
            <FeedbackAlert
              show={feedback.show}
              message={feedback.message}
              type={feedback.type}
              timeout={feedback.timeout}
            />
          </Box>
          {isMobile && (
            <AddProductMobile
              {...addProductProps}
              steps={steps}
              setSteps={setSteps}
            />
          )}
          {!isMobile && (
            <AddProductDesktop
              {...addProductProps}
              steps={steps}
              setSteps={setSteps}
            />
          )}
        </>
      )}
    </>
  );
};
