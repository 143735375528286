import React, { useState } from "react";
import { Currency } from "../../types/common.types";
import { currencies } from "../../helpers/generic";
import { changeCurrency } from "../../helpers/xhrHelper";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { SettingCard } from "./CustomCard";
import CustomButton from "../common/CustomButton";
import { useTranslation } from "../../hooks/useTranslation";

const CurrencySettingWrapper = styled(Box)(() => ({
  width: "max-content",
}));

const CurrencySetting: React.FC<{ currency: Currency }> = ({ currency }) => {
  const [changeMode, setChangeMode] = useState(false);
  const [newCurrency, setNewCurrency] = useState(currency.name);
  const dispatch = useDispatch();

  const insertCurrency = useTranslation("insert_currency");
  const selectCurrency = useTranslation("select_currency");
  const currencyTranslation = useTranslation("currency");
  const symbolTranslation = useTranslation("symbol");
  const youHaveNotAddedACurrency = useTranslation(
    "you_have_not_added_a_currency"
  );
  const cancelTranslation = useTranslation("cancel_translation");
  const changeTranslation = useTranslation("change_translation");
  const submitTranslation = useTranslation("submit");

  if (!currency.name) {
    return <>{youHaveNotAddedACurrency}</>;
  }
  const submitCurrencyChange = () => {
    const selectedCurrency = currencies.find(
      (someCurrency) => someCurrency.name === newCurrency
    );
    if (!!selectedCurrency) {
      changeCurrency(dispatch, selectedCurrency);
    }
  };
  const btnText = changeMode ? `${cancelTranslation}` : `${changeTranslation}`;
  const isSameCurrency = newCurrency === currency.name;

  return (
    <CurrencySettingWrapper>
      <SettingCard
        header={
          <Box component="div" sx={{ display: "flex", gap: 1 }}>
            <CurrencyExchangeIcon />
            <Typography variant="h6">{insertCurrency}</Typography>
          </Box>
        }
        content={
          changeMode ? (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {selectCurrency}
              </InputLabel>
              <Select
                name="currency"
                id="currencySelect"
                value={newCurrency}
                onChange={(e) => setNewCurrency(e.target.value)}
                label={selectCurrency}
                sx={{
                  transition: "height 0.3s ease",
                }}
                size="medium"
              >
                {currencies.map((currency, index) => (
                  <MenuItem key={index} value={currency.name}>
                    {currency.name} ({currency.prefix})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box component="div">
              <Typography variant="body1">
                {currencyTranslation}: <b>{currency.name}</b>
              </Typography>
              <Typography variant="body1">
                {symbolTranslation}: <b>{currency.prefix}</b>
              </Typography>
            </Box>
          )
        }
        footer={
          <Box
            sx={{
              display: "flex",
              cursor: "not-allowed",
              justifyContent: "end",
              gap: 1,
            }}
            width="100%"
          >
            <CustomButton
              onClick={() => {
                setChangeMode(!changeMode);
              }}
              sx={{ maxWidth: "105px" }}
            >
              {btnText}
            </CustomButton>

            {changeMode && (
              <CustomButton
                sx={{ cursor: "pointer" }}
                disabled={isSameCurrency}
                onClick={() => submitCurrencyChange()}
              >
                {submitTranslation}
              </CustomButton>
            )}
          </Box>
        }
      />
    </CurrencySettingWrapper>
  );
};

export default CurrencySetting;
