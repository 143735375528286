import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { TopBar } from "./app/TopBar";
import { SideDrawer } from "./app/SideDrawer";
import { palette } from "../assets/color";
import { useWindowSize } from "../helpers/generic";
import { useEffect, useState } from "react";

export const drawerWidth = 240;
const topBarHeight = 64;
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: topBarHeight, //`${topBarHeight - topBarPadding}px`,
  // padding: theme.spacing(0, 1),
  // ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
  const isMobile = useWindowSize().width < 957;
  const [open, setOpen] = useState(!isMobile);
  const handleDrawerOpen = () => {
    setOpen(!isMobile);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  return (
    <Box sx={{ display: "flex", width: "100%", minHeight: "100vh" }}>
      <CssBaseline />
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isMobile && { p: 3 }),
          backgroundColor: palette.bodyDark,
          p: "3%",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
