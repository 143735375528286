import React, { useState } from "react";
import { Staff } from "../../types/common.types";
import StaffCard from "./StaffCard";
import EditStaff from "./EditStaff";
import { useTranslation } from "../../hooks/useTranslation";

interface StaffListProps {
  staffs: Staff[];
}

export default function StaffList({ staffs }: StaffListProps) {
  const [editStaffMode, setEditStaffMode] = useState<boolean>(false);
  const [staff, setStaff] = useState<Staff | null>(null);

  const noStaffFound = useTranslation("no_staff_found");

  const handleOpen = (staff: Staff) => {
    setStaff(staff);
    setEditStaffMode(true);
  };
  const handleClose = () => setEditStaffMode(false);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        margin: "0, auto",
        padding: "20px 0px",
        boxSizing: "border-box",
      }}
    >
      {staffs.length > 0 &&
        staffs.map((staff, index) => (
          <button
            key={index}
            onClick={() => handleOpen(staff)}
            style={{
              background: "none",
              color: "inherit",
              border: "none",
              padding: 0,
              font: "inherit",
              cursor: "pointer",
              outline: "inherit",
            }}
          >
            {" "}
            <StaffCard staff={staff} />
          </button>
        ))}
      {!staffs.length && <p>{noStaffFound}</p>}

      <EditStaff open={editStaffMode} onClose={handleClose} staff={staff} />
    </div>
  );
}
