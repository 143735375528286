import { useEffect, useRef } from "react";

interface Props {
  value: any;
}
const UsePrevious = (props: Props) => {
  const previous = useRef({ value: null });

  useEffect(() => {
    previous.current.value = props.value;
  }, [props.value]);

  return previous.current.value;
};

export default UsePrevious;
