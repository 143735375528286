import { Alert, Box, TextField } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { changePassword } from "../../helpers/xhrHelper";
import CustomButton from "../common/CustomButton";
import { useTranslation } from "../../hooks/useTranslation";

const ChangePasswordWrapper = styled(Box)({
  width: "100%",
  maxWidth: "1020px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>("");

  const changePasswordTranslation = useTranslation("change_password");
  const oldPasswordTranslation = useTranslation("old_password");
  const newPasswordTranslation = useTranslation("new_password");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (oldPassword === newPassword) {
        setError(true);
        setAlertText("New password must be different from old password");
        setLoading(false);
        return;
      }

      const response = await changePassword({ oldPassword, newPassword });

      if (response.success) {
        setSuccess(true);
      } else {
        setError(true);
      }

      setAlertText(response.message || "Operation failed. Please try again.");
    } catch (error) {
      console.error("Error:", error);
      setAlertText("An unknown error occurred. Please try again.");
      setError(true);
    } finally {
      setLoading(false);

      setTimeout(() => {
        setSuccess(false);
        setError(false);
        setOldPassword("");
        setNewPassword("");
      }, 2000);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <ChangePasswordWrapper>
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {alertText}
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {alertText}
          </Alert>
        )}

        <TextField
          type="password"
          label={oldPasswordTranslation}
          value={oldPassword}
          required
          size="small"
          disabled={loading}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <TextField
          type="password"
          label={newPasswordTranslation}
          value={newPassword}
          required
          size="small"
          disabled={loading}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {/* <Button sx={{ textTransform: "none" }} disabled={loading} type="submit">
          Submit
        </Button> */}

        <CustomButton
          sx={{ height: "45px" }}
          size="large"
          disabled={loading}
          type="submit"
        >
          {changePasswordTranslation}
        </CustomButton>
      </ChangePasswordWrapper>
    </Box>
  );
}
