import React from "react";
import { ProductInterface } from "../../types/common.types";
import { Record } from "../styled/Generic";
interface SalesInterface {
  drink: ProductInterface;
}
/**
 *  The motive is for this component to render a simple and comprehensive
 *  information about sales made on actively browsed drink.
 * */
const SalesTab = (props: SalesInterface): JSX.Element => {
  return (
    <Record>
      28 items sold in the last <b> 7 days-------</b>
    </Record>
  );
};

export default SalesTab;
